import {
  SET_TAXFILING_LOADER,
  SET_TAXFILING_ERROR,
  SET_TAXFILING_CURRENT_ANSWER,
  SET_TAXFILING_CURRENT_QUESTION,
  RESET_TAXFILING_CURRENT_QUESTION,
  UPDATE_TAXFILING_CURRENT_ANSWER,
  UPDATE_TAXFILING_CURRENT_ANSWER_BULK,
  SET_TAXFILING_UPDATING,
  UPDATE_TAXFILING_CACHE,
  REPLACE_TAXFILING_INFO_ITEMS,
  MERGE_TAXFILING_CURRENT_QUESTION,
  SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN,
  SET_TAXFILING_IS_PAYMENT_MODAL_OPEN,
  SET_TAXFILING_CONTEXT_LOADING,
  SET_TAXFILING_NAVIGATION_ITEMS,
  SET_TAXFILING_INDUSTRIES,
  SET_TAXFILING_BUSINESS_CODES,
  SET_TAXFILING_RATING,
  SET_TAXFILING_HOVER_RATING,
  SET_TAXFILING_CURRENT_UPLOADS,
  SET_TAXFILING_INFO_MODAL_SHOW,
  SET_TAXFILING_INFO_MODAL_ITEM,
  SET_TAXFILING_DROPDOWN_WARNINGS,
  SET_TAXFILING_BUSINESS_CODE,
  SET_TAXFILING_IS_QUESTIONNAIRE_FLOW,
  SET_TAXFILING_QUESTION_WRAPPER,
  SET_TAXFILING_DROPZONE_FILES,
  SET_TAXFILING_DROPZONE_FILES_SAME_FORM,
  SET_TAXFILING_DROPZONE_LOADING,
  SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD,
  SET_TAXFILING_FORM_UPLOAD_STATUS,
  SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG,
  SET_DESKTOP_SELL_MODAL_SHOW,
  SET_TAXFILING_FORM_UPLOAD_KEYED_FILES,
  SET_TAXFILING_BULK_UPLOAD_ITEMS,
  SET_TAXFILING_ADD_MORE_ITEMS,
  SET_FEEDBACK_MODAL_SHOW,
  SET_TAXFILING_IS_WEBVIEW_FOCUSED,
  SET_SUBSCRIPTION_MODAL_SHOW,
  SET_TAXFILING_CHANGED_ENDPOINT_ATTRIBUTES,
  SET_TAXFILING_CURRENTLY_FOCUSED_ENDPOINT_ATTRIBUTE
} from '@app/src/actions/types';

export const setInfoModalShow = (payload) => {
  return {
    type: SET_TAXFILING_INFO_MODAL_SHOW,
    payload
  };
};

export const setInfoModalItem = (payload) => {
  return {
    type: SET_TAXFILING_INFO_MODAL_ITEM,
    payload
  };
};

/**
 * @desc Set Loader
 */
export const setTaxFlowLoader = (payload) => {
  return {
    type: SET_TAXFILING_LOADER,
    payload
  };
};

/**
 * @desc Set Errors
 */
export const setTaxFlowError = (payload) => {
  return {
    type: SET_TAXFILING_ERROR,
    payload
  };
};

export const setIsWebviewFocused = (payload) => {
  return {
    type: SET_TAXFILING_IS_WEBVIEW_FOCUSED,
    payload
  };
};

export const setCurrentAnswer = (payload) => ({
  type: SET_TAXFILING_CURRENT_ANSWER,
  payload
});

export const setCurrentQuestion = (payload) => ({
  type: SET_TAXFILING_CURRENT_QUESTION,
  payload
});

export const resetCurrentQuestion = () => ({
  type: RESET_TAXFILING_CURRENT_QUESTION
});

export const setContextLoading = (payload) => ({
  type: SET_TAXFILING_CONTEXT_LOADING,
  payload
});

export const updateCurrentAnswer = (payload) => ({
  type: UPDATE_TAXFILING_CURRENT_ANSWER,
  payload
});

export const updateCurrentAnswerBulk = (payload) => ({
  type: UPDATE_TAXFILING_CURRENT_ANSWER_BULK,
  payload
});

export const setUpdating = (payload) => ({
  type: SET_TAXFILING_UPDATING,
  payload
});

export const updateCache = (payload) => ({
  type: UPDATE_TAXFILING_CACHE,
  payload
});

export const replaceInfoItems = (payload) => ({
  type: REPLACE_TAXFILING_INFO_ITEMS,
  payload
});

export const mergeCurrentQuestion = (payload) => ({
  type: MERGE_TAXFILING_CURRENT_QUESTION,
  payload
});

export const setIsConfirmationModalOpen = (payload) => ({
  type: SET_TAXFILING_IS_CONFIRMATION_MODAL_OPEN,
  payload
});

export const setIsPaymentModalOpen = (payload) => ({
  type: SET_TAXFILING_IS_PAYMENT_MODAL_OPEN,
  payload
});

export const setNavigationItems = (payload) => ({
  type: SET_TAXFILING_NAVIGATION_ITEMS,
  payload
});

export const setDropdownWarnings = (payload) => ({
  type: SET_TAXFILING_DROPDOWN_WARNINGS,
  payload
});

export const setIndustries = (payload) => ({
  type: SET_TAXFILING_INDUSTRIES,
  payload
});

export const setBusinessCodes = (payload) => ({
  type: SET_TAXFILING_BUSINESS_CODES,
  payload
});

export const setBulkUploadItems = (manualEntryItems) => ({
  type: SET_TAXFILING_BULK_UPLOAD_ITEMS,
  payload: manualEntryItems
});

export const setTaxFlowRating = (payload) => ({
  type: SET_TAXFILING_RATING,
  payload
});

export const setTaxFlowHoverRating = (payload) => ({
  type: SET_TAXFILING_HOVER_RATING,
  payload
});

export const setCurrentUploads = (payload) => ({
  type: SET_TAXFILING_CURRENT_UPLOADS,
  payload
});

export const setFormUploadStatus = (status) => ({
  type: SET_TAXFILING_FORM_UPLOAD_STATUS,
  payload: status
});

export const setDropzoneLoading = (isLoading) => ({
  type: SET_TAXFILING_DROPZONE_LOADING,
  payload: isLoading
});

export const setDropzoneFiles = (files) => ({
  type: SET_TAXFILING_DROPZONE_FILES,
  payload: files
});

export const setFormUploadKeyedFiles = (keyedFiles) => ({
  type: SET_TAXFILING_FORM_UPLOAD_KEYED_FILES,
  payload: keyedFiles
});

export const setDropzoneFilesReadyForUpload = (isReady) => ({
  type: SET_TAXFILING_DROPZONE_FILES_READY_FOR_UPLOAD,
  payload: isReady
});

export const setDropzoneFilesSameForm = (sameForm) => ({
  type: SET_TAXFILING_DROPZONE_FILES_SAME_FORM,
  payload: sameForm
});

export const setBusinessCode = (payload) => ({
  type: SET_TAXFILING_BUSINESS_CODE,
  payload
});

export const setAddMoreItems = (pills) => ({
  type: SET_TAXFILING_ADD_MORE_ITEMS,
  payload: pills
});

export const setIsQuestionnaireFlow = (payload) => ({
  type: SET_TAXFILING_IS_QUESTIONNAIRE_FLOW,
  payload
});

export const setQuestionWrapper = (payload) => ({
  type: SET_TAXFILING_QUESTION_WRAPPER,
  payload
});

export const setCurrentGetQuestionAnswerLoadingSlug = (payload) => ({
  type: SET_TAXFILING_CURRENT_GET_QUESTION_ANSWER_LOADING_SLUG,
  payload
});

export const setDesktopSellModalShow = (payload) => {
  return {
    type: SET_DESKTOP_SELL_MODAL_SHOW,
    payload
  };
};

export const setFeedbackModalShow = (payload) => {
  return {
    type: SET_FEEDBACK_MODAL_SHOW,
    payload
  };
};

export const setSubscriptionModalShow = (payload) => {
  return {
    type: SET_SUBSCRIPTION_MODAL_SHOW,
    payload
  };
};

export const setChangedEndpointAttributes = (payload) => ({
  type: SET_TAXFILING_CHANGED_ENDPOINT_ATTRIBUTES,
  payload
});

export const setCurrentlyFocusedEndpointAttribute = (payload) => ({
  type: SET_TAXFILING_CURRENTLY_FOCUSED_ENDPOINT_ATTRIBUTE,
  payload
});
