import _ from 'lodash';
import { baseApi, TAGS } from '@app/src//api/baseApi';
import { setExpensesYear } from '@app/src/actions/expenseReviewActions';
import { setHolisticOnboardingWebviewBankLinked } from '@app/src/actions/holisticOnboardingActions';
import { setIsWebviewFocused } from '@app/src/actions/taxFlowActions';
import { setBuild } from '@app/src/actions/workActions';
import plaidApi from '@app/src/api/plaidApi';
import { deleteTaxData, updateTaxData } from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { runOnSuccess } from '@app/src/services/bankLinkService';
import { getSubscriptionInfo } from '@app/src/services/pricingService';
import store from '@app/src/store/store';
import { uiStageSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { PATH_COMPONENT__SUBMIT_EMAIL_INFO } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { getTaxFilingPaid } from '@app/src/taxflow/sections/submit/services/submitService';
import { setAppOs } from '@app/src/taxflow/shared/actions/sharedActions';
import { TAXFLOW_BASE_URL, UI_STAGE__CONFIRM_AMOUNTS } from '@app/src/taxflow/shared/constants/sharedConstants';
import { currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const sentMsgToWeb = (type, data) => async (dispatch, getState) => {
  switch (type) {
    case 'set_build':
      dispatch(setBuild(data));
      break;
    case 'refetch_paid_status': {
      await dispatch(plaidApi.util.invalidateTags([TAGS.PAID, TAGS.ACCOUNT_DETAILS]));
      await dispatch(getSubscriptionInfo());
      const paid = await dispatch(getTaxFilingPaid());
      const question = currentQuestionSelector(getState());
      const uiStage = uiStageSelector(getState());
      // If user paid and in the submit flow, send to the submit email info screen.
      // Else, the user paid outside of the submit flow (via a modal or prompt), persist them where they were.
      if (paid && /submit/.test(question.slug) && uiStage === UI_STAGE__CONFIRM_AMOUNTS) {
        history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__SUBMIT_EMAIL_INFO}`);
      }
      break;
    }
    case 'app_os':
      dispatch(setAppOs(data));
      break;
    case 'back_button_press':
      history.goBack();
      break;
    // Reset web rtk cache upon webview (taxes tab) focus
    // Ex. a user could have updated deductions data in the native 'deductions' tab - we want to ensure tax filing is synced with such
    case 'webview_focus':
      dispatch(baseApi.util.invalidateTags(_.values(TAGS)));
      dispatch(setIsWebviewFocused(true));
      break;
    case 'webview_unfocus':
      dispatch(setIsWebviewFocused(false));
      break;
    case 'set_year':
      dispatch(setExpensesYear(data));
      break;
    case 'navigate_tax_home':
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
      break;
    case 'invalidate_tags':
      dispatch(baseApi.util.invalidateTags(_.values(TAGS)));
      break;
    case 'navigate_path':
      history.push(data);
      break;
    case 'plaid_link_success':
      dispatch(setHolisticOnboardingWebviewBankLinked(true));
      dispatch(runOnSuccess({ origin: 'webview' }));
      break;
    case 'write_tax_data':
      dispatch(updateTaxData(data));
      break;
    case 'delete_tax_data':
      dispatch(deleteTaxData(data));
      break;
    default:
      break;
  }
};

window.sentMsgToWeb = async (type, data) => {
  await store.dispatch(sentMsgToWeb(type, data));
};
