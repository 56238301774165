import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { selfUnlockModalOpenSelector } from '@app/src/selectors/navigationListSelectors';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';

const TaxFlowPremiumSelfUnlockModal = ({ title, body }) => {
  const dispatch = useDispatch();
  const selfUnlockModalOpen = useSelector(selfUnlockModalOpenSelector);

  return (
    <Dialog
      open={selfUnlockModalOpen}
      onClose={() => {
        dispatch(setSelfUnlockModalOpen(false));
      }}
    >
      <DialogTitle sx={{ marginTop: 1.5, marginRight: 1.5, marginLeft: 1.5 }}>
        <div>{title}</div>
        <IconButton
          aria-label='close'
          onClick={() => {
            dispatch(setSelfUnlockModalOpen(false));
          }}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: 1 }}>
        <DialogContentText>{body}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default TaxFlowPremiumSelfUnlockModal;
