import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxFlowFormAddressItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormAddressItem';
import TaxFlowFormBox14Item from '@app/src/Components/TaxFlow/Form/TaxFlowFormBox14Item';
import TaxFlowFormCalendarItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormCalendarItem';
import TaxFlowFormDateItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormDateItem';
import TaxFlowFormDropdownItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormDropdownItem';
import TaxFlowFormEINItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormEINItem';
import TaxFlowFormExpensesLinkItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormExpensesLinkItem';
import TaxFlowFormInfoItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormInfoItem';
import TaxFlowFormInitialItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormInitialItem';
import TaxFlowFormIntItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormIntItem';
import TaxFlowFormMilesItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMilesItem';
import TaxFlowFormMoneyItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMoneyItem';
import TaxFlowFormMultiOptionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormMultiOptionItem';
import TaxFlowFormNumberItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormNumberItem';
import TaxFlowFormOptionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormOptionItem';
import TaxFlowFormPercentItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormPercentItem';
import TaxFlowFormPhoneItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormPhoneItem';
import TaxFlowFormSSNItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSSNItem';
import TaxFlowFormSSNOrEINItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSSNOrEINItem';
import TaxFlowFormSliderItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSliderItem';
import TaxFlowFormSquareFootageItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSquareFootageItem';
import TaxFlowFormTextItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormTextItem';
import TaxFlowFormTwelveAItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormTwelveAItem';
import TaxFlowFormYearsItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormYearsItem';
import TaxFlowFormZipItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormZipItem';
import TaxFlowWorkMilesEstimated from '@app/src/Components/TaxFlow/Question/TaxFlowWorkMilesEstimated';
import {
  CATEGORY_TYPE_TAXFLOW_FORM_TEXT,
  CATEGORY_TYPE_TAXFLOW_FORM_EMAIL,
  CATEGORY_TYPE_TAXFLOW_FORM_PHONE,
  CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD,
  CATEGORY_TYPE_TAXFLOW_FORM_DATE,
  CATEGORY_TYPE_TAXFLOW_FORM_SSN,
  CATEGORY_TYPE_TAXFLOW_FORM_NUMBER,
  CATEGORY_TYPE_TAXFLOW_FORM_SLIDER,
  CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_INFO,
  CATEGORY_TYPE_MONEY,
  CATEGORY_TYPE_TWELVE_A,
  CATEGORY_TYPE_BOX_14,
  CATEGORY_TYPE_STATE,
  CATEGORY_TYPE_INITIAL,
  CATEGORY_TYPE_MILES,
  CATEGORY_TYPE_YEARS,
  CATEGORY_TYPE_PERCENT,
  CATEGORY_TYPE_SQUARE_FOOTAGE,
  CATEGORY_TYPE_EIN,
  CATEGORY_TYPE_SSN_OR_EIN,
  CATEGORY_TYPE_ZIP,
  CATEGORY_TYPE_INT,
  CATEGORY_TYPE_TAXFLOW_ADDRESS,
  CATEGORY_TYPE_EXPENSES_LINK,
  CATEGORY_TYPE_TAXFLOW_FORM_MULTI,
  CATEGORY_TYPE_WHO
} from '@app/src/constants/constants';
import { isW2OnlySelector } from '@app/src/selectors/dashboardSelectors';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { formMultiQuestionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { CAR_SLUGS } from '@app/src/taxflow/sections/car/carConstants';
import { statusSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormSubQuestionItem.scss';

const TaxFlowFormMultiItem = (props) => {
  const {
    currentQuestion,
    currentAnswer,
    resultLoading,
    status,
    onChange,
    onFocus = _.noop,
    onBlur = _.noop,
    formMultiQuestions
  } = props;

  if (resultLoading) return null;

  const handleFocus = (result) => {
    onFocus({
      endpoint_attr: result.endpoint_attr,
      collectionType: currentQuestion.collectionType
    });
  };

  const handleBlur = ({ question, answer }) => {
    onBlur({ question, answer });
  };

  const handleChange = (answer) => {
    onChange({ slug: answer.slug, value: answer.value, endpoint_attr: answer.endpoint_attr });
  };

  const renderQuestionInputType = (question) => {
    const answer = currentAnswer?.value ? currentAnswer.value[question.slug] : null;

    if (
      !isQuestionPresent({
        currentQuestion: currentQuestion,
        question,
        answer: currentAnswer,
        status: status
      })
    ) {
      return null;
    }

    return (
      <TaxFlowFormSubQuestionItem
        {...props}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        question={question}
        answer={answer}
        fullAnswer={currentAnswer}
      />
    );
  };

  return (
    <>
      {formMultiQuestions &&
        formMultiQuestions.map((question, index) => (
          <div key={index} className='taxflow-form-multi-item-container'>
            <label className='taxflow-form-multi-item-label'>{question.title}</label>
            {question.sub_question.map((subquestion) => (
              <div key={subquestion.slug}>{renderQuestionInputType(subquestion)}</div>
            ))}
          </div>
        ))}
    </>
  );
};

const TaxFlowFormSubQuestionItem = (props) => {
  const {
    question,
    answer,
    onChange,
    onFocus = _.noop,
    onBlur = _.noop,
    overrideCollectionId,
    currentAnswer,
    status,
    resultLoading,
    formMultiQuestions,
    replaceStrings
  } = props;

  if (question && ((question.question_meta && question.question_meta.type) || question.question_type)) {
    switch (question.question_meta.type || question.question_type) {
      case CATEGORY_TYPE_TAXFLOW_FORM_TEXT:
        return (
          <TaxFlowFormTextItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_EMAIL:
        return (
          <TaxFlowFormTextItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_PHONE:
        return (
          <TaxFlowFormPhoneItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_NUMBER:
        return (
          <TaxFlowFormNumberItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_PASSWORD:
        return (
          <TaxFlowFormTextItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_MONEY:
        return (
          <TaxFlowFormMoneyItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
            overrideCollectionId={overrideCollectionId}
          />
        );
      case CATEGORY_TYPE_TWELVE_A:
        return (
          <TaxFlowFormTwelveAItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_BOX_14:
        return (
          <TaxFlowFormBox14Item
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_DATE:
        return (
          <TaxFlowFormDateItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_SSN:
        return (
          <TaxFlowFormSSNItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_INITIAL:
        return (
          <TaxFlowFormInitialItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_MILES:
        return (
          <TaxFlowFormMilesItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_YEARS:
        return (
          <TaxFlowFormYearsItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_PERCENT:
        return (
          <TaxFlowFormPercentItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_SQUARE_FOOTAGE:
        return (
          <TaxFlowFormSquareFootageItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_EIN:
        return (
          <TaxFlowFormEINItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_SSN_OR_EIN:
        return (
          <TaxFlowFormSSNOrEINItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_ZIP:
        return (
          <TaxFlowFormZipItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_INT:
        return (
          <TaxFlowFormIntItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_SLIDER:
        return (
          <TaxFlowFormSliderItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_DROPDOWN:
        return (
          <TaxFlowFormDropdownItem
            {...props}
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_STATE:
        return (
          <TaxFlowFormDropdownItem
            {...props}
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_WHO:
        return (
          <TaxFlowFormDropdownItem
            {...props}
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_OPTIONS:
        return (
          <TaxFlowFormOptionItem
            {...props}
            question={{
              ...question,
              info: props.currentQuestion.info
            }}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
            setCurrentAnswer={props.setCurrentAnswer}
            fullAnswer={props.fullAnswer}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_MULTI_OPTION:
        return (
          <TaxFlowFormMultiOptionItem
            {...props}
            question={{
              ...question,
              info: props.currentQuestion.info
            }}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_INFO:
        if (question.slug === CAR_SLUGS.MILEAGE_ESTIMATION_WORK_MILES_ESTIMATED) {
          return (
            <TaxFlowWorkMilesEstimated
              question={question}
              answer={answer}
              onChange={onChange}
              parentAnswer={currentAnswer}
              {...props}
            />
          );
        }
        return <TaxFlowFormInfoItem question={question} replaceStrings={replaceStrings} />;
      case CATEGORY_TYPE_TAXFLOW_CALENDAR:
        return (
          <TaxFlowFormCalendarItem
            {...props}
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
            setCurrentAnswer={props.setCurrentAnswer}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_ADDRESS:
        return (
          <TaxFlowFormAddressItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_EXPENSES_LINK:
        return (
          <TaxFlowFormExpensesLinkItem
            question={question}
            answer={answer}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
          />
        );
      case CATEGORY_TYPE_TAXFLOW_FORM_MULTI:
        return (
          <TaxFlowFormMultiItem
            {...props}
            currentQuestion={question}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            replaceStrings={replaceStrings}
            status={status}
            currentAnswer={currentAnswer}
            resultLoading={resultLoading}
            formMultiQuestions={formMultiQuestions}
          />
        );
      default:
        return null;
    }
  }
};

const mapStateToProps = (state, props) => ({
  isW2Only: isW2OnlySelector(state),
  status: statusSelector(state),
  currentAnswer: state.taxFlow.currentAnswer,
  resultLoading: state.taxFlow.resultLoading,
  formMultiQuestions: formMultiQuestionsSelector(state, props.question)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowFormSubQuestionItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormSubQuestionItem);

export default ConnectedTaxFlowFormSubQuestionItem;
