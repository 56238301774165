import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import { setSettingsLoading } from '@app/src/actions/settingsActions';
import { bankLinkPendingSelector, bankListPageLoadingSelector } from '@app/src/selectors/bankSelectors';
import { trackActivity } from '@app/src/services/analyticsService';

const SettingsLinkedAccounts = () => {
  const dispatch = useDispatch();
  const isBankLinking = useSelector(bankLinkPendingSelector);
  const isParentLoading = useSelector(bankListPageLoadingSelector);

  useEffect(() => {
    trackActivity('navigation: connected accounts');
  }, []);

  return (
    <>
      <div className='question-title'>Linked Accounts</div>
      <Divider />
      <BankList
        origin='settings'
        type='settings'
        isParentLoading={isParentLoading}
        setIsParentLoading={(loading) => dispatch(setSettingsLoading(loading))}
        isBankLinking={isBankLinking}
      />
    </>
  );
};

export default SettingsLinkedAccounts;
