import React, { useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { ArrowDown2 } from 'iconsax-react';
import { connect } from 'react-redux';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { isMobileBrowser } from '@app/src/global/Helpers';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { currentAnswerSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowDropdownSearchItem = ({
  currentAnswer,
  currentQuestion,
  setCurrentAnswer,
  onBlur = _.noop,
  onFocus = _.noop
}) => {
  const { data = [], options: dropdownSearchOptions } = currentQuestion.question_meta;
  const currentValue = useMemo(
    () => data.find((option) => option.value === currentAnswer.value),
    [currentAnswer, data]
  );

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: currentQuestion.endpoint_attr });
    if (autocompleteRef && autocompleteRef.current) {
      setOpen(true);
      const scrollOptions = isMobileBrowser() ? undefined : { behavior: 'smooth' };

      setTimeout(() => {
        autocompleteRef.current.scrollIntoView(scrollOptions);
      }, 250);
    }
  };

  const handleBlur = () => {
    if (autocompleteRef && autocompleteRef.current) {
      setOpen(false);
    }
  };

  const onOptionTouch = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.blur();
      setOpen(true);
    }
  };

  const onInputChange = (_event, value, reason) => {
    switch (reason) {
      case 'input':
        setInputValue(value);
        onBlur({ currentQuestion, currentAnswer });
        break;
      case 'reset':
        if (!open) {
          setInputValue(value);
          onBlur({ currentQuestion, currentAnswer });
        }
        break;
      case 'clear':
        setInputValue('');
        onBlur({ currentQuestion, currentAnswer });
        break;
      default:
        setInputValue(value);
    }
  };

  const onChange = (_event, option) => {
    const value = option ? option.value : null;

    setCurrentAnswer({ value });
  };

  return (
    <div className='steps-body dropdown-search-body'>
      <Autocomplete
        options={data}
        clearOnBlur={false}
        value={currentValue || null}
        groupBy={(option) => option[dropdownSearchOptions.groupBy]}
        renderInput={(params) => (
          <TextField {...params} placeholder={dropdownSearchOptions.placeholder} inputRef={inputRef} />
        )}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputValue={inputValue}
        onInputChange={onInputChange}
        disablePortal
        autoHighlight
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        PaperComponent={(params) => <Paper {...params} onTouchStart={onOptionTouch} />}
        popupIcon={<ArrowDown2 />}
        PopperComponent={(params) => (
          <Popper
            {...params}
            modifiers={[
              {
                name: 'flip',
                enabled: false
              }
            ]}
          />
        )}
        ref={autocompleteRef}
      />
      <TaxValidationWarnings question={currentQuestion} answer={currentAnswer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentAnswer: currentAnswerSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowDropdownSearchItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowDropdownSearchItem);

export default ConnectedTaxFlowDropdownSearchItem;
