import _ from 'lodash';
import { createSelector } from 'reselect';
import taxDataApi from '@app/src/api/taxDataApi';
import { CATEGORY_TYPE_TAXFLOW_FORM, CATEGORY_TYPE_TAXFLOW_FORM_MULTI } from '@app/src/constants/constants';
import { Url_SIGNUP_PHONE_ONLY_PHONE } from '@app/src/constants/onboardingConstants';
import {
  changedEndpointAttributesSelector,
  currentlyFocusedEndpointAttributeSelector
} from '@app/src/selectors/taxFlowSelectors';
import {
  bulkUploadItemsSelector,
  formUploadAttemptsSelector,
  isCurrentCollectionUploadedSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { taxDataSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__STATE_EXPENSES,
  COLLECTION_TYPE__STATE_INCOME,
  COLLECTION_TYPE__STATE_RESIDENCY,
  COLLECTION_TYPE__STATE_RETURN,
  ENDPOINT_ATTRIBUTE__STATE_RETURN,
  SLUG__STATE_EXPENSES,
  SLUG__STATE_INCOME,
  STATE_SPLIT_SLUGS
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  currentQuestionSelector,
  currentCollectionIdSelector,
  currentAnswerSelector,
  queryResultsSelector,
  statusSelector,
  currentCollectionTypeSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';

const questionSelector = (state, props) => props.question;
const overrideCollectionIdSelector = (state, props) => props.overridecollectionid;

/** This selector gets errors that were fetched from the backend (existing errors) */
const apiErrorsSelector = (state) => {
  const year = yearSelector(state);
  const { data } = taxDataApi.endpoints.getTaxInfo.select({ year })(state);
  return _.get(data, 'errors', []);
};

/** This selector gets errors that were generated by fields the user has touched on this page viewing */
const currentErrorsSelector = (state) => _.get(state, ['taxValidation', 'errors'], []);

/** Get slugs that are associated with the current question */
const slugsOnCurrentPageSelector = createSelector(
  [currentQuestionSelector, taxDataSelector, currentAnswerSelector],
  (currentQuestion, taxData, currentAnswer) => {
    if (_.isEmpty(currentQuestion) || _.isEmpty(currentAnswer)) {
      return [];
    }
    // Handle processing for non-form questions
    if (_.isEmpty(currentQuestion.sub_question)) {
      // If this is a state split question, we need to handle it differently. The pills automatically get generated
      // with an error. We can't use taxData to derive the slugs like we can for other questions because the user won't have
      // prior data entered for the splits. As a result, we have to derive it from the currentAnswer object, which has
      // all the relevant endpoint attributes as keys.
      if (STATE_SPLIT_SLUGS.includes(currentQuestion.slug)) {
        return currentAnswer?.value ? Object.keys(currentAnswer.value) : [];
      }
      // Otherwise, return just the question slug
      return [currentQuestion.endpoint_attr];
    }
    return currentQuestion.sub_question.flatMap((subQuestion) => {
      if (subQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
        // For multi-form questions, get all slugs that start with each sub-question's endpoint_attr
        return subQuestion.sub_question.flatMap((formMultiQuestion) =>
          taxData.filter((td) => td?.slug?.startsWith(formMultiQuestion.endpoint_attr)).map((td) => td.slug)
        );
      }
      return subQuestion.endpoint_attr;
    });
  }
);

/**
 * Determines if this page already contained tax data.
 * This could be because the user has already visited the page before, or because it was prefilled by bulk upload.
 * */
export const currentPageAlreadyHasTaxDataSelector = createSelector(
  [taxDataSelector, slugsOnCurrentPageSelector, currentCollectionTypeSelector, currentCollectionIdSelector],
  (taxData, currentSlugs, currentCollectionType, currentCollectionId) => {
    return _.some(
      taxData,
      (item) =>
        currentSlugs.includes(item.slug) &&
        item.coll_type === currentCollectionType &&
        item.coll_id === currentCollectionId
    );
  }
);

/** Gets errors to display to the user after they've edited a field */
export const errorsSelector = createSelector(
  [
    currentCollectionTypeSelector,
    currentCollectionIdSelector,
    apiErrorsSelector,
    changedEndpointAttributesSelector,
    slugsOnCurrentPageSelector,
    currentPageAlreadyHasTaxDataSelector,
    currentlyFocusedEndpointAttributeSelector,
    currentErrorsSelector
  ],
  (
    collectionType,
    collectionId,
    apiErrors,
    changedEndpointAttributes,
    currentSlugs,
    currentPageAlreadyHasTaxData,
    currentlyFocusedEndpointAttribute,
    currentErrors
  ) => {
    const changedEndpointAttributesKeys = Object.keys(changedEndpointAttributes);
    const currentApiErrors = apiErrors.filter(
      (error) =>
        error.coll_type === collectionType &&
        error.coll_id === collectionId &&
        currentSlugs.includes(error.slug) &&
        // We temporarily hide errors on the currently focused field
        error.slug !== currentlyFocusedEndpointAttribute
    );
    const filteredCurrentErrors = currentErrors.filter(
      // We temporarily hide errors on the currently focused field
      (error) => error.slug !== currentlyFocusedEndpointAttribute
    );

    // If the user's current error list has only one error on the state residency question,
    // it means that it was newly generated. For this particular question, the answers for both states
    // are linked, so we need to add an error for the other state as well.
    if (filteredCurrentErrors.filter((error) => error.coll_type === COLLECTION_TYPE__STATE_RESIDENCY).length === 1) {
      const residencyError = filteredCurrentErrors.find(
        (error) => error.coll_type === COLLECTION_TYPE__STATE_RESIDENCY
      );
      const stateNumber = residencyError.slug.slice(-1);
      const otherStateNumber = stateNumber === '1' ? '2' : '1';
      filteredCurrentErrors.push({
        slug: residencyError.slug.slice(0, -1) + otherStateNumber,
        coll_id: residencyError.coll_id,
        coll_type: residencyError.coll_type,
        code: residencyError.code
      });
    }

    // If the user's current error list is empty, but they had errors when they loaded the page AND they have changed a field,
    // it means that they resolved the errors, so return an empty array.
    if (
      _.isEmpty(filteredCurrentErrors) &&
      currentApiErrors.some((error) => error.coll_type === COLLECTION_TYPE__STATE_RESIDENCY) &&
      changedEndpointAttributesKeys.length > 0
    ) {
      return [];
    }

    // If the current page already has tax data, return the current errors
    if (!currentPageAlreadyHasTaxData) {
      return filteredCurrentErrors;
    }

    // Otherwise, merge the errors from fields the user touched with the backend errors that haven't been touched yet.
    // This ensures that the initial load state has all relevant errors present on the page.
    // Also note: the "slug" in the backend errors object is actually the endpoint_attr in the Contentful item.
    const errorsInUntouchedFields = currentApiErrors.filter(
      (error) => !changedEndpointAttributesKeys.includes(error.slug)
    );

    return _.uniqWith(
      [...errorsInUntouchedFields, ...filteredCurrentErrors],
      (a, b) =>
        a.slug === b.slug &&
        a.coll_id === b.coll_id &&
        a.coll_type === b.coll_type &&
        a.warningMessage === b.warningMessage
    );
  }
);

/**
 * Get a list of errors to surface to the user
 *
 * Exclude errors on tax data the user has not answered yet (as users shouldn't see errors till they submit tax data)
 */
export const filteredErrorsSelector = (state) => {
  const taxData = taxDataSelector(state);
  const isCurrentCollectionUploaded = isCurrentCollectionUploadedSelector(state);
  const bulkUploadItems = bulkUploadItemsSelector(state);
  const currentQuestion = currentQuestionSelector(state);
  const currentPageAlreadyHasTaxData = currentPageAlreadyHasTaxDataSelector(state);
  const changedEndpointAttributes = Object.keys(changedEndpointAttributesSelector(state));
  const errors = errorsSelector(state);
  if (currentPageAlreadyHasTaxData || (!currentPageAlreadyHasTaxData && changedEndpointAttributes.length > 0)) {
    return errors;
  }

  return _.some(bulkUploadItems, { formInputQuestionSlug: currentQuestion.slug }) && isCurrentCollectionUploaded
    ? errors
    : errors.filter(({ slug, coll_id }) => _.some(taxData, { coll_id, slug }));
};

export const formErrorsSelector = createSelector(
  [filteredErrorsSelector, currentQuestionSelector, currentCollectionIdSelector, currentAnswerSelector, statusSelector],
  (errors, question, collectionId, answer, status) => {
    const collectionIds =
      question.slug === INCOME_SLUGS.INVEST_INFO ? [collectionId, `${Number(collectionId) + 1}`] : [collectionId];

    return _.chain(errors)
      .filter(({ slug, coll_id }) => {
        if (_.get(question, 'question_type') === CATEGORY_TYPE_TAXFLOW_FORM) {
          const questionEndpointAttrs = _.chain(question)
            .get('sub_question', [])
            .filter((subQuestion) =>
              isQuestionPresent({
                currentQuestion: question,
                question: subQuestion,
                answer,
                status
              })
            )
            .flatMap((item) => {
              if (item.question_type !== CATEGORY_TYPE_TAXFLOW_FORM_MULTI) {
                return item.endpoint_attr;
              }
              return _.map(item.sub_question, 'endpoint_attr');
            })
            .uniq()
            .value();
          if (question.sub_question.some((sq) => sq.question_type === CATEGORY_TYPE_TAXFLOW_FORM_MULTI)) {
            return questionEndpointAttrs.some((attr) => slug.includes(attr)) && collectionIds.includes(coll_id);
          }
          return questionEndpointAttrs.includes(slug) && collectionIds.includes(coll_id);
        }
        if ([SLUG__STATE_INCOME, SLUG__STATE_EXPENSES].includes(question.slug)) {
          if (_.isEmpty(answer)) {
            return false;
          }
          return _.has(answer.value, slug);
        }
        return slug === question.endpoint_attr && collectionIds.includes(coll_id);
      })
      .keyBy('slug')
      .value();
  }
);

const isPrefillSelector = createSelector(
  [formUploadAttemptsSelector, questionSelector, currentCollectionIdSelector],
  (formUploadAttempts, question, currentCollectionId) => {
    const collectionType = _.get(question, 'collectionType');
    const collectionId = currentCollectionId;
    if (_.isNil(collectionType) || _.isNil(collectionId)) {
      return false;
    }

    const collectionAttempt = _.get(formUploadAttempts, [collectionType, collectionId], {});
    return _.get(collectionAttempt, 'status') === 'prefilled';
  }
);

export const hasFieldErrorSelector = createSelector(
  [
    questionSelector,
    currentAnswerSelector,
    currentCollectionIdSelector,
    filteredErrorsSelector,
    queryResultsSelector,
    isPrefillSelector,
    overrideCollectionIdSelector
  ],
  (question, answer, collectionId, errors, queryResults, isPrefill, overrideCollectionId) => {
    if (
      question.slug === Url_SIGNUP_PHONE_ONLY_PHONE &&
      _.get(answer, ['value', Url_SIGNUP_PHONE_ONLY_PHONE, 'value'], '').startsWith('11')
    ) {
      return true;
    } else if (question.collectionType === COLLECTION_TYPE__STATE_RETURN) {
      const firstStateReturn = queryResults.find((result) => {
        return result.coll_type === COLLECTION_TYPE__STATE_RETURN && result.slug === ENDPOINT_ATTRIBUTE__STATE_RETURN;
      });
      return firstStateReturn && _.get(answer, ['value']) === _.get(firstStateReturn, ['answer', 'value']);
    }

    let collectionErrors;
    if (question.collectionType === INCOME_COLLECTION_TYPES.INVEST) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === INCOME_SLUGS.INVEST_UNIFICATION && result.coll_id === collectionId
      );
      const unifiedCollIds = _.get(unificationRecord, ['answer', 'value']) || [Number(collectionId)];
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          unifiedCollIds.includes(Number(error.coll_id)) &&
          error.slug === question.endpoint_attr &&
          (_.isNil(overrideCollectionId) || overrideCollectionId === Number(error.coll_id))
      );
    } else if (
      question.collectionType === COLLECTION_TYPE__STATE_INCOME ||
      question.collectionType === COLLECTION_TYPE__STATE_EXPENSES
    ) {
      if (_.isArray(question.slug)) {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && question.slug.includes(error.slug)
        );
      } else {
        collectionErrors = errors.filter(
          (error) => error.coll_type === question.collectionType && error.slug === question.slug
        );
      }
    } else {
      collectionErrors = errors.filter(
        (error) =>
          error.coll_type === question.collectionType &&
          error.coll_id === collectionId &&
          error.slug === question.endpoint_attr &&
          error.blocking
      );
    }

    const ocrErrors = collectionErrors.filter((error) => error.code === 'ocr_error');
    return (isPrefill && !!ocrErrors.length) || collectionErrors.length > ocrErrors.length;
  }
);

export const currentPageHasNewErrorSelector = (state) => {
  return _.get(state, ['taxValidation', 'currentPageHasNewError'], false);
};
