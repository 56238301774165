import {
  SET_HOLISTIC_ONBOARDING_LOADING,
  SET_HOLISTIC_ONBOARDING_ERROR,
  SET_HOLISTIC_ONBOARDING_UPDATING,
  SET_HOLISTIC_ONBOARDING_INITIAL_LOAD,
  SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES,
  SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES,
  SET_HOLISTIC_ONBOARDING_TAX_ESTIMATE,
  SET_HOLISTIC_ONBOARDING_GOALS,
  SET_HOLISTIC_ONBOARDING_FIRST_NAME,
  SET_HOLISTIC_ONBOARDING_LAST_NAME,
  SET_HOLISTIC_ONBOARDING_EMAIL,
  SET_HOLISTIC_ONBOARDING_PHONE,
  SET_HOLISTIC_ONBOARDING_STATE,
  SET_HOLISTIC_ONBOARDING_MARRIED,
  SET_HOLISTIC_ONBOARDING_DEPENDENTS,
  SET_HOLISTIC_ONBOARDING_RENT,
  SET_HOLISTIC_ONBOARDING_HOME_OWNER,
  SET_HOLISTIC_ONBOARDING_STUDENT_LOANS,
  SET_HOLISTIC_ONBOARDING_INCOME_TYPE,
  SET_HOLISTIC_ONBOARDING_W2_INCOME,
  SET_HOLISTIC_ONBOARDING_1099_INCOME,
  SET_HOLISTIC_ONBOARDING_PARTNER_W2_INCOME,
  SET_HOLISTIC_ONBOARDING_PARTNER_1099_INCOME,
  SET_HOLISTIC_ONBOARDING_JOB,
  SET_HOLISTIC_ONBOARDING_START_DATE,
  SET_HOLISTIC_ONBOARDING_DRIVE,
  SET_HOLISTIC_ONBOARDING_HOME,
  SET_HOLISTIC_ONBOARDING_MEAL,
  SET_HOLISTIC_ONBOARDING_TRAVEL,
  SET_HOLISTIC_ONBOARDING_PROGRESS,
  SET_HOLISTIC_ONBOARDING_DRAWER_OPEN,
  SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED,
  SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS
} from '@app/src/actions/types';

const initialState = {
  loading: false,
  error: null,
  updating: false,
  initialLoad: true,
  drawerOpen: false,
  progress: 0,
  content: {},
  jobCategories: [],
  taxEstimate: null,
  goals: [],
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  state: '',
  married: 'single',
  dependents: 0,
  rent: null,
  homeOwner: null,
  studentLoans: null,
  incomeType: [],
  incomeW2: null,
  income1099: null,
  partnerIncomeW2: null,
  partnerIncome1099: null,
  jobs: [],
  jobDuration: '',
  home: null,
  drive: null,
  meal: null,
  travel: null,
  webviewBankLinked: false,
  onboardingExperiments: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_HOLISTIC_ONBOARDING_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_UPDATING:
      return {
        ...state,
        updating: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_INITIAL_LOAD:
      return {
        ...state,
        initialLoad: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES:
      return {
        ...state,
        content: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES:
      return {
        ...state,
        jobCategories: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_PROGRESS:
      return {
        ...state,
        progress: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_DRAWER_OPEN:
      return {
        ...state,
        drawerOpen: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_TAX_ESTIMATE:
      return {
        ...state,
        taxSavings: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_GOALS:
      return {
        ...state,
        goals: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_FIRST_NAME:
      return {
        ...state,
        firstname: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_LAST_NAME:
      return {
        ...state,
        lastname: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_EMAIL:
      return {
        ...state,
        email: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_PHONE:
      return {
        ...state,
        phone: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_STATE:
      return {
        ...state,
        state: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_MARRIED:
      return {
        ...state,
        married: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_DEPENDENTS:
      return {
        ...state,
        dependents: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_RENT:
      return {
        ...state,
        rent: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_HOME_OWNER:
      return {
        ...state,
        homeOwner: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_STUDENT_LOANS:
      return {
        ...state,
        studentLoans: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_INCOME_TYPE:
      return {
        ...state,
        incomeType: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_W2_INCOME:
      return {
        ...state,
        incomeW2: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_1099_INCOME:
      return {
        ...state,
        income1099: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_PARTNER_W2_INCOME:
      return {
        ...state,
        partnerIncomeW2: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_PARTNER_1099_INCOME:
      return {
        ...state,
        partnerIncome1099: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_JOB:
      return {
        ...state,
        jobs: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_START_DATE:
      return {
        ...state,
        jobDuration: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_DRIVE:
      return {
        ...state,
        drive: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_HOME:
      return {
        ...state,
        home: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_MEAL:
      return {
        ...state,
        meal: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_TRAVEL:
      return {
        ...state,
        travel: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED:
      return {
        ...state,
        webviewBankLinked: action.payload
      };

    case SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS:
      return {
        ...state,
        onboardingExperiments: action.payload
      };

    default:
      return state;
  }
}
