import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BankList from '@app/src/Components/Bank/BankList/BankList';
import PlaidSecurityNotice from '@app/src/Components/HolisticOnboarding/components/PlaidSecurityNotice/PlaidSecurityNotice';
import { setHolisticOnboardingLoading } from '@app/src/actions/holisticOnboardingActions';
import { bankListSelector, bankLinkPendingSelector } from '@app/src/selectors/bankSelectors';
import { webviewBankLinkedSelector, loadingSelector } from '@app/src/selectors/holisticOnboardingSelectors';
import { getWorkInfo } from '@app/src/services/workService';

const OnboardingBankLink = () => {
  const dispatch = useDispatch();
  const webviewBankLinked = useSelector(webviewBankLinkedSelector);
  const isBankLinking = useSelector(bankLinkPendingSelector);
  const isParentLoading = useSelector(loadingSelector);
  const banklist = useSelector(bankListSelector);

  useEffect(() => {
    dispatch(getWorkInfo());
  }, [dispatch]);

  return (
    <>
      <BankList
        origin='onboarding'
        type='onboarding'
        isParentLoading={isParentLoading}
        setIsParentLoading={(loading) => dispatch(setHolisticOnboardingLoading(loading))}
        isBankLinking={isBankLinking}
        isMobileWebview={webviewBankLinked}
      />
      {banklist.length === 0 && <PlaidSecurityNotice />}
    </>
  );
};

export default OnboardingBankLink;
