import React, { forwardRef, useState } from 'react';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import KeeperSelect from '@app/src/Components/Common/KeeperSelect/KeeperSelect';
import { colorPrimary, colorPrimaryAlt, colorSurface } from '@app/src/theme';

const ExpenseReviewEditPanelDropdown = forwardRef(
  ({ displayName, options, labelId, disabled, value, onChange, ...props }, ref) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
      setIsOpen(!isOpen);
    };

    return (
      <KeeperSelect
        ref={ref}
        labelId={labelId}
        open={isOpen}
        value={!_.isNil(value) ? value : ''}
        onChange={(e) => onChange(e.target.value)}
        onClick={onClick}
        disabled={disabled}
        renderValue={() => _.upperFirst(displayName)}
        inputProps={{ 'aria-label': 'Without label' }}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            key={_.get(option, 'value')}
            value={_.get(option, 'value')}
            sx={{
              '&.Mui-selected': { backgroundColor: colorPrimaryAlt, color: colorSurface },
              '&.Mui-selected:hover': { backgroundColor: colorPrimary, color: colorSurface }
            }}
          >
            {_.get(option, 'displayName')}
          </MenuItem>
        ))}
      </KeeperSelect>
    );
  }
);

ExpenseReviewEditPanelDropdown.displayName = 'ExpenseReviewEditPanelDropdown';

export default ExpenseReviewEditPanelDropdown;
