import React, { useMemo, useState, forwardRef } from 'react';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Fuse from 'fuse.js';
import { FUSE_CATEGORY_OPTIONS, OTHER_CATEGORY_ID } from '@app/src/constants/constants';

const ExpenseReviewCategoryDropdown = forwardRef(({ labelId, value, options, disabled, onChange, name }, ref) => {
  const [inputValue, setInputValue] = useState('');

  const fuse = useMemo(() => new Fuse(options, FUSE_CATEGORY_OPTIONS), [options]);

  return (
    <Autocomplete
      ref={ref}
      id={labelId}
      options={options}
      popupIcon={null}
      disableClearable
      getOptionLabel={(option) => option.displayName}
      value={options.find((option) => option.value === value) || null}
      blurOnSelect
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      clearOnBlur
      openOnFocus
      filterOptions={(options, { inputValue }) => {
        if (inputValue?.trim() === '') {
          return options;
        }

        const searchResult = fuse.search(inputValue);

        const otherExpense = _.find(options, (item) => item.value === OTHER_CATEGORY_ID);

        let filteredCategories;

        if (otherExpense) {
          filteredCategories = _.filter(searchResult, (result) => {
            return result.item.value !== OTHER_CATEGORY_ID;
          });
          filteredCategories.push({ item: otherExpense });
        } else {
          filteredCategories = searchResult;
        }

        return _.map(filteredCategories, 'item');
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& .MuiAutocomplete-input': {
                padding: '0 0 0 8px',
                cursor: 'pointer'
              }
            }
          }}
          variant='outlined'
          placeholder='Select category'
          disabled={disabled}
        />
      )}
      onChange={(event, { value }) => onChange({ value }, inputValue)}
    />
  );
});

ExpenseReviewCategoryDropdown.displayName = 'ExpenseReviewCategoryDropdown';

export default ExpenseReviewCategoryDropdown;
