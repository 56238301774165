import React from 'react';
import ArrowDown2 from '@app/src/assets/arrow-down.svg?react';

export const ArrowIconForDropdown = (props) => (
  <ArrowDown2
    style={{
      marginRight: '4px',
      width: '24px',
      height: '24px'
    }}
    // This allows the onClick handling to be passed in, which
    // allows MUI to handle flipping the arrow on click automatically
    {...props}
  />
);
