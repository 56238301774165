import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import CalendarIcon from '@app/src/assets/calendar.svg?react';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxValidationDatePicker = ({ question, hasError, value, onChange, onFocus = _.noop, onBlur = _.noop }) => {
  const handleChange = (value) => {
    onChange({ value: value ? value.format('DD/MMM/YYYY') : null });
  };

  return (
    <div>
      <TaxValidationInputLabel question={question} hasError={hasError} htmlFor={question.slug} />
      <div className='tax-flow-input-wrap'>
        <DesktopDatePicker
          value={value ? moment(value, 'DD/MMM/YYYY') : null}
          onOpen={onFocus}
          onChange={(newValue) => {
            if (onFocus) {
              onFocus();
            }
            handleChange(newValue);
          }}
          components={{ OpenPickerIcon: CalendarIcon }}
          renderInput={(params) => (
            <TextField
              name={question.slug}
              id={question.slug}
              fullWidth
              {...params}
              error={hasError}
              onFocus={onFocus}
              onBlur={onBlur}
              inputProps={{
                ...params.inputProps,
                placeholder: 'MM/DD/YYYY',
                'data-testid': question.slug
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationDatePicker = connect(mapStateToProps)(TaxValidationDatePicker);

export default ConnectedTaxValidationDatePicker;
