import React from 'react';
import Button from '@mui/material/Button';
import AppStoreLogo from '@app/src/assets/apple-store-logo.svg?react';
import DownloadFeature from '@app/src/assets/download-featured.svg?react';
import DownloadRating from '@app/src/assets/download-rating.svg?react';
import GoogleStoreLogo from '@app/src/assets/google-store-logo.svg?react';
import IphoneImage from '@app/src/assets/iphone.svg?react';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { trackActivity } from '@app/src/services/analyticsService';
import getImageUrl from '@app/src/utils/getImageUrl';

const OnboardingAppDownload = () => {
  const isMobile = useIsMobile();

  const onDownloadClick = () => {
    trackActivity('app store clicked', { store: 'download' });
  };

  const onAppStoreClick = (store) => {
    trackActivity('app store clicked', { store });
    if (store === 'android') {
      window.open('https://play.google.com/store/apps/details?id=com.keepertax&hl=en&gl=US', '_blank');
    } else {
      window.open('https://itunes.apple.com/app/id1471090941', '_blank');
    }
  };

  return (
    <div className='h-onboarding-content-question-download'>
      {!isMobile && (
        <div className='h-onboarding-content-question-download-reviews'>
          <DownloadFeature alt='Featured' />
          <DownloadRating alt='Rating' />
        </div>
      )}
      <div className='h-onboarding-content-question-download-header'>
        <IphoneImage alt='iPhone interface' />
      </div>
      <div>
        <div style={{ margin: '16px 0' }}>
          {isMobile ? (
            <div>
              <Button
                variant='contained'
                color='secondary'
                fullWidth
                type='button'
                href='https://keeper.tax/get-the-app'
                onClick={onDownloadClick}
              >
                Continue
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <AppStoreLogo alt='Apple App Store' onClick={() => onAppStoreClick('apple')} role='button' />
                  <GoogleStoreLogo alt='Google Play Store' onClick={() => onAppStoreClick('android')} role='button' />
                </div>
                <div className='flex column' style={{ alignItems: 'center', width: '50%' }}>
                  <img src={getImageUrl('get-the-app-qr.svg')} alt='QR code' height={130} width={130} />
                  <div className='text-base' style={{ textAlign: 'center' }}>
                    To download, scan QR code with your phone's camera
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingAppDownload;
