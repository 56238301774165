import React, { useEffect, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import Divider from '@mui/material/Divider';
import ProgressBar from '@ramonak/react-progress-bar';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { trackActivity } from '@app/src/services/analyticsService';
import { TAXFLOW__FORM_UPLOAD_FAILED_STATES } from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  getPrefillNextPathComponentMap,
  getPrefillPrevPathComponentMap
} from '@app/src/taxflow/collection/utils/collectionUtils';
import { formUploadAttemptsSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { getOriginParam } from '@app/src/taxflow/navigation/utils/navigationUtils';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentCollectionIdSelector,
  currentQuestionSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowPrefillLoadingScreen.scss';

const TaxFlowPrefillLoadingScreen = ({
  history,
  currentQuestion,
  currentCollectionId,
  formUploadAttempts,
  ...props
}) => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const location = useLocation();
  const collectionType = _.get(currentQuestion, 'collectionType');
  const collectionUpload = _.get(formUploadAttempts, [collectionType, currentCollectionId]);
  const uploadStatus = _.get(collectionUpload, 'status');
  const uploadFileName = ['prefilled', 'ocr_initiated', 'in_cloud_storage'].includes(uploadStatus)
    ? _.get(collectionUpload, ['files', 0, 'file_name'])
    : null;
  const hasError = uploadStatus && TAXFLOW__FORM_UPLOAD_FAILED_STATES.includes(uploadStatus);

  useEffect(() => {
    if (hasError) {
      trackActivity('form upload: ocr error', {
        collection_type: currentQuestion.collectionType,
        collection_id: currentCollectionId
      });
      const prevPathComponentMap = { ...getPrefillPrevPathComponentMap() };
      history.push(
        `/${TAXFLOW_BASE_URL}/${prevPathComponentMap[currentQuestion.slug]}?collectionId=${currentCollectionId}`
      );
    }
  }, [hasError, currentCollectionId, currentQuestion, history]);

  useEffect(() => {
    if (uploadFileName) {
      const loadingPercentageInterval = setInterval(() => {
        if (loadingPercentage === 0) {
          trackActivity('form upload: start ocr waiting', {
            collection_type: currentQuestion.collectionType,
            collection_id: currentCollectionId
          });
        }
        if (loadingPercentage < 80) {
          setLoadingPercentage(loadingPercentage + 5);
        }
      }, 1000);
      return () => {
        clearInterval(loadingPercentageInterval);
      };
    }
  }, [uploadFileName, loadingPercentage, currentQuestion, currentCollectionId]);

  useEffect(() => {
    if (uploadStatus === 'prefilled') {
      setLoadingPercentage(100);
      trackActivity('form upload: load ocr results', {
        collection_type: currentQuestion.collectionType,
        collection_id: currentCollectionId
      });
      const originParam = getOriginParam({ location });
      setTimeout(async () => {
        const nextComponentPathMap = { ...getPrefillNextPathComponentMap() };
        const nextComponentPath = nextComponentPathMap[currentQuestion.slug];
        history.replace(
          url.format({
            pathname: `/${TAXFLOW_BASE_URL}/${nextComponentPath}`,
            query: {
              collectionId: currentCollectionId,
              origin: originParam
            }
          })
        );
      }, 5000);
    }
  }, [uploadStatus, currentQuestion, history, currentCollectionId, location]);

  return (
    <div>
      <div className='steps-body'>
        <Divider light />
        {uploadFileName && (
          <div className='tax-flow-prefill-loading-screen-bar-container'>
            <ProgressBar
              completed={loadingPercentage}
              isLabelVisible={false}
              height='10px'
              borderRadius='6px'
              baseBgColor='#eff0f0'
              bgColor='#4abc78'
              transitionTimingFunction='linear'
            />
            <div className='tax-flow-prefill-loading-screen-file-label'>Reading information from {uploadFileName}</div>
          </div>
        )}
        {_.get(currentQuestion, ['sub_question', '0', 'sub_question'], [])
          .filter((subQuestion) => subQuestion.required)
          .map((subQuestion, index) => (
            <div className='form-group' key={subQuestion.id}>
              <label className='normal' style={{ color: '#424a60', fontSize: '16px', fontWeight: 'normal' }}>
                {props.replaceStrings(subQuestion.title)}
              </label>
              <div
                className={classNames(
                  'tax-flow-prefill-loading-screen-placeholder-box',
                  `tax-flow-prefill-loading-screen-placeholder-box-${(5 * index) % 7}`
                )}
              ></div>
            </div>
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  history: props.history,
  currentQuestion: currentQuestionSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  formUploadAttempts: formUploadAttemptsSelector(state)
});

const ConnectedTaxFlowPrefillLoadingScreen = connect(mapStateToProps)(TaxFlowPrefillLoadingScreen);

export default ConnectedTaxFlowPrefillLoadingScreen;
