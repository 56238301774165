import React from 'react';
import _ from 'lodash';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ArrowRight2 } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import ExpenseReviewCategoryIcon from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryIcon';
import { setSidebarComponent, setStatusFilterSelections } from '@app/src/actions/expenseReviewActions';
import { currencyWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  allCategoriesSelector,
  categoryStatisticsSelector,
  expensesYearSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { updateCategoryFilter } from '@app/src/services/expenseReviewService';
import '@app/src/Components/ExpenseReview/ExpenseReviewCategoryList.scss';

const ExpenseReviewCategoryList = () => {
  const dispatch = useDispatch();
  const allCategories = useSelector(allCategoriesSelector);
  const categoryStatistics = useSelector(categoryStatisticsSelector);
  const year = useSelector(expensesYearSelector);

  const handleClick = (catId) => {
    dispatch(setStatusFilterSelections(['yes']));
    dispatch(updateCategoryFilter([Number(catId)]));
    dispatch(setSidebarComponent(null));
  };

  if (_.isEmpty(categoryStatistics)) {
    return (
      <p className='expense-review-empty-category-list'>
        If you earned freelance / 1099 income in {year}, marking business expenses as deductions reduces your taxable
        income.
      </p>
    );
  }

  return (
    <List
      variant='category-list'
      disablePadding
      sx={{
        minWidth: '451px'
      }}
    >
      {categoryStatistics.map(({ categoryId, deductionAmount, count }) => {
        if (count === 0) return null;
        const listCategory = allCategories.find((category) => Number(category.id) === Number(categoryId));
        const keeperCategory = _.get(listCategory, 'keeper_category', '');

        return (
          <ListItem
            key={categoryId}
            variant='category-item'
            onClick={() => handleClick(categoryId)}
            secondaryAction={
              <div className='expense-review-category-item-info'>
                <div>{currencyWith0DecimalPlaces(deductionAmount)}</div>
                <div>({count} transactions)</div>
                <ArrowRight2 />
              </div>
            }
          >
            <div className='expense-review-category-item-content'>
              <ExpenseReviewCategoryIcon id={categoryId} size='20px' />
              <div className='expense-review-category-item-name'>{_.upperFirst(keeperCategory)}</div>
            </div>
          </ListItem>
        );
      })}
    </List>
  );
};
export default ExpenseReviewCategoryList;
