import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import TaxFlowPercentItem from '@app/src/Components/TaxFlow/Question/TaxFlowPercentItem';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxFlowFormPercentItem = (props) => {
  const { question, answer, onChange, onFocus = _.noop, onBlur = _.noop } = props;

  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  return (
    <div className='form-group'>
      <TaxFlowPercentItem
        {...props}
        id={question.slug}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxFlowFormPercentItem = connect(mapStateToProps)(TaxFlowFormPercentItem);

export default ConnectedTaxFlowFormPercentItem;
