import React from 'react';
import _ from 'lodash';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import NumberFormatCustom from '@app/src/Components/Common/NumberFormatCustom/NumberFormatCustom';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationInputLabel from '@app/src/Components/TaxValidation/TaxValidationInputLabel';
import { isHeaderHiddenSelector } from '@app/src/selectors/taxFlowSelectors';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxValidationNumberFormat = ({
  question,
  answer,
  hasError,
  onValueChange,
  onFocus = _.noop,
  onBlur = _.noop,
  isHeaderHidden,
  ...props
}) => {
  const handleChange = ({ value }) => {
    onValueChange({
      value
    });
  };

  return (
    <div className='form-group'>
      <TaxValidationInputLabel question={question} htmlFor={question.slug} />
      <div className='tax-flow-input-wrap'>
        <TextField
          fullWidth
          name={question.slug}
          id={question.slug}
          value={_.get(answer, 'value')}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          error={hasError}
          autoComplete={question.slug}
          placeholder={question.question_meta.default}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              ...props,
              'data-testid': question.slug,
              'aria-label': isHeaderHidden ? question.title : undefined
            }
          }}
        />
        <TaxFlowInfoButton currentQuestion={question} />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector,
  isHeaderHidden: isHeaderHiddenSelector
});

const ConnectedTaxValidationNumberFormat = connect(mapStateToProps)(TaxValidationNumberFormat);

export default ConnectedTaxValidationNumberFormat;
