import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpenseUploadLinkPrompt from '@app/src/Components/Common/ExpenseUpload/modals/ExpenseUploadLinkPrompt';
import ChurnExplanation from '@app/src/Components/Settings/Modals/Churn/ChurnExplanation';
import ChurnInitial from '@app/src/Components/Settings/Modals/Churn/ChurnInitial';
import DeleteAccountModal from '@app/src/Components/Settings/Modals/DeleteAccountModal';
import SubscriptionFlow from '@app/src/Components/Subscription/SubscriptionFlow';
import { setOverrides } from '@app/src/actions/pricingActions';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { SETTINGS_MODAL_TYPES } from '@app/src/constants/settingsConstants';
import { subscriptionStatusSelector } from '@app/src/selectors/pricingSelectors';
import { settingsModalTypeSelector } from '@app/src/selectors/settingsSelectors';

const SettingsModalContent = () => {
  const dispatch = useDispatch();

  const modalType = useSelector(settingsModalTypeSelector);
  const trialingUser = useSelector(subscriptionStatusSelector) === 'free trial';

  switch (modalType) {
    case PRICING_ORIGINS.SUBSCRIPTION_FLOW:
      return <SubscriptionFlow initialScreen='subscriptionType' />;
    case PRICING_ORIGINS.EDIT_PLAN:
      dispatch(
        setOverrides({
          subscriptionType: {
            title: 'Update your plan',
            CTA: {
              primary: {
                text: 'Update'
              }
            }
          },
          pricingMethod: {
            title: trialingUser ? 'Continue your free trial' : 'Choose payment method',
            CTA: {
              primary: {
                text: 'Continue'
              }
            }
          }
        })
      );

      return <SubscriptionFlow initialScreen='subscriptionType' />;
    case PRICING_ORIGINS.EDIT_PAYMENT:
      dispatch(
        setOverrides({
          pricingMethod: {
            title: 'Update payment method',
            CTA: {
              primary: {
                text: 'Update'
              }
            }
          }
        })
      );

      return <SubscriptionFlow initialScreen='pricingMethod' />;
    case SETTINGS_MODAL_TYPES.CHURN_INITIAL:
      return <ChurnInitial />;
    case SETTINGS_MODAL_TYPES.CHURN_EXPLANATION:
      return <ChurnExplanation />;
    case SETTINGS_MODAL_TYPES.DELETE_ACCOUNT:
      return <DeleteAccountModal />;
    case SETTINGS_MODAL_TYPES.EXPENSE_UPLOAD_LINK_PROMPT:
      return <ExpenseUploadLinkPrompt />;
    default:
      return null;
  }
};

export default SettingsModalContent;
