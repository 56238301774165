import React, { useEffect } from 'react';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { ArrowRight, LampOn } from 'iconsax-react';
import { connect } from 'react-redux';
import { setExpenseReviewCurrentRule, setExpenseReviewModalType } from '@app/src/actions/expenseReviewActions';
import IconCheckGreen from '@app/src/assets/icon-check-green.svg?react';
import IconClose from '@app/src/assets/icon-close.svg?react';
import {
  currentRuleSelector,
  loadingSelector,
  merchantSearchResultsSelector,
  validRuleSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { createExpenseRule, deleteExpenseRule, updateExpenseRule } from '@app/src/services/expenseReviewService';
import { colorWarning } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewModalAutoRule.scss';

const ExpenseReviewModalAutoRule = ({
  rule,
  setExpenseReviewCurrentRule,
  setExpenseReviewModalType,
  merchants,
  createExpenseRule,
  loading
}) => {
  const ruleId = _.get(rule, 'rule_id', null);
  const ruleName = _.get(rule, 'name', '');
  const rulePrediction = _.get(rule, 'prediction', '');

  const merchant = merchants.find((merchant) => merchant.clean_name === ruleName);

  useEffect(() => {
    trackActivity(`expense rules: auto rule overlay viewed`, { platform: 'web' });

    return () => {
      setExpenseReviewCurrentRule({});
    };
  }, [ruleId, setExpenseReviewCurrentRule]);

  const handleSave = async () => {
    await createExpenseRule({ origin: 'auto modal' });
  };

  const handleSkip = () => {
    setExpenseReviewModalType(null);
  };

  const toggleApplyToPreviousTransactions = () => {
    setExpenseReviewCurrentRule({ ...rule, update_expenses: !rule.update_expenses });
  };

  return (
    <div className='expense-review-modal-auto-rule'>
      <div className='text-xl bold'>Create a rule</div>
      <Divider />
      <Stack spacing={2} className='rules'>
        <div>
          By create a rule, Keeper will automatically update all present and future transactions from the same merchant.
        </div>
        <div className='highlight-box'>
          <div className='text-base'>"{ruleName}"</div>
          <ArrowRight />
          <div className='flex row'>
            {rulePrediction === 'yes' && (
              <>
                <IconCheckGreen />
                <div className='text-base ml-8'>Deduction</div>
              </>
            )}
            {rulePrediction === 'no' && (
              <>
                <IconClose />
                <div className='text-base ml-8'>Not deductible</div>
              </>
            )}
            {rulePrediction === 'maybe' && (
              <>
                <LampOn color={colorWarning} />
                <div className='text-base ml-8'>Review</div>
              </>
            )}
          </div>
        </div>
        <div className='flex row space-between'>
          <div className='text-base'>Apply rule to past transactions ({merchant?.count})</div>
          <Switch onChange={toggleApplyToPreviousTransactions} defaultChecked={true} />
        </div>
        <div className='flex row center'>
          <Button onClick={handleSkip} variant='text' color='secondary'>
            See all rules
          </Button>
        </div>
        <Stack spacing={2} direction='row'>
          <LoadingButton variant='contained' color='secondary' fullWidth onClick={handleSave} loading={loading}>
            Save
          </LoadingButton>
          <Button variant='outlined' fullWidth onClick={handleSkip}>
            Skip
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

const mapStateToProps = (state) => ({
  rule: currentRuleSelector(state),
  merchants: merchantSearchResultsSelector(state),
  validRule: validRuleSelector(state),
  loading: loadingSelector(state)
});

const mapDispatchToProps = {
  setExpenseReviewCurrentRule,
  deleteExpenseRule,
  setExpenseReviewModalType,
  createExpenseRule,
  updateExpenseRule
};

const ConnectedExpenseReviewModalAutoRule = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewModalAutoRule);

export default ConnectedExpenseReviewModalAutoRule;
