import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import ArrowRightIcon from '@app/src/assets/arrow-right.svg?react';
import { BANNER_VARIANT } from '@app/src/constants/constants';
import '@app/src/Components/Common/Banner/Banner.scss';

export const Banner = ({ text, icon, variant = BANNER_VARIANT.DEFAULT, onClick = _.noop }) => (
  <div
    className={classNames('banner', { 'banner-click-through': variant === BANNER_VARIANT.CLICK_THROUGH })}
    role={variant === BANNER_VARIANT.CLICK_THROUGH ? 'button' : undefined}
    onClick={onClick}
  >
    <div className='banner-lhs'>
      <div className='banner-icon'>{icon}</div>
      <span>{text}</span>
    </div>
    {variant === BANNER_VARIANT.CLICK_THROUGH && <ArrowRightIcon />}
  </div>
);
