import _ from 'lodash';
import { useSelector } from 'react-redux';
import { CATEGORY_TYPE_BUSINESS_CODE, CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD } from '@app/src/constants/constants';
import { useIsExtensionFlow } from '@app/src/hooks/useIsExtensionFlow';
import { currentUploadsSelector, isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import isValidInput from '@app/src/services/taxFlow/isValidInput';
import { taxDataSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { CAR_SLUGS } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
  SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
  SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import { HOME_SLUGS } from '@app/src/taxflow/sections/home/homeConstants';
import { INCOME_ENDPOINT_ATTRIBUTES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE,
  SLUG__DEPENDENT_DETAIL,
  SLUG__DEPENDENT_NAV_START,
  SLUG__DEPENDENT_RELATIONSHIP_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL,
  SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON,
  SLUG__DEPENDENT_RELATIONSHIP_TYPE,
  SLUG__HOME_ADDRESS_DETAIL,
  SLUG__HOME_ADDRESS_STATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import {
  SLUG__CREDIT_ADD_MORE,
  SLUG__INCOME_ADD_MORE,
  SLUG__SPECIAL_EARLY_EXIT
} from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__DC_RENT_CREDIT_OPTIONS,
  SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_DEBIT,
  SLUG__SUBMIT_PIN,
  SLUG__SUBMIT_HAVE_PIN,
  SLUG__SUBMIT_SELF_PIN_NUMBER,
  SLUG__SUBMIT_SPOUSE_PIN_NUMBER,
  SLUG__SUBMIT_DEBIT_MANUAL,
  SLUG__SUBMIT_SIGNATURE,
  SLUG__SUBMIT_EMAIL_INFO,
  SLUG__SUBMIT_DEBIT_MANUAL_OPTION,
  SLUG__SUBMIT_BANK_NUMBERS,
  SLUG__SUBMIT_CONFIRM_ID
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { SLUG__CONTACT_SUPPORT, SLUG__PAST_RETURNS } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentQuestionSelector,
  currentAnswerSelector,
  queryResultsSelector,
  statusSelector,
  isAlabamaOrMinnesotaSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { hasQuestionError, isQuestionPresent } from '@app/src/utils/taxValidationUtils';

export const useIsTaxFilingNextEnabled = () => {
  const currentQuestion = useSelector(currentQuestionSelector);
  const currentAnswer = useSelector(currentAnswerSelector);
  const queryResults = useSelector(queryResultsSelector);
  const status = useSelector(statusSelector);
  const currentUploads = useSelector(currentUploadsSelector);
  const isAlabamaOrMinnesota = useSelector(isAlabamaOrMinnesotaSelector);
  const isQuestionnaireFlow = useSelector(isQuestionnaireFlowSelector);
  const year = useSelector(yearSelector);
  const taxData = useSelector(taxDataSelector);

  const isExtensionFlow = useIsExtensionFlow();

  if (_.includes([SLUG__INCOME_ADD_MORE, SLUG__CREDIT_ADD_MORE], currentQuestion.slug)) {
    return false;
  }

  if (
    currentQuestion.slug === SLUG__SUBMIT_DEBIT_MANUAL &&
    _.get(currentAnswer, ['value', SLUG__SUBMIT_DEBIT_MANUAL_OPTION, 'value']) !== 'manual'
  ) {
    return false;
  }

  if (currentQuestion.slug === SLUG__CREDIT_STUDENT_TUITION_FORM_UPLOAD) {
    return false;
  }

  if (currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_FORM_UPLOAD) {
    const hasPendingUpload = currentUploads.some((upload) => upload.status === 'pending');

    // form upload is required for AL and MN residents
    return !hasPendingUpload && !isAlabamaOrMinnesota;
  }

  // Skipping of questions not allowed in the manual extensions filing flow
  if (isExtensionFlow) {
    return !hasQuestionError({ question: currentQuestion, answer: currentAnswer, status, queryResults });
  }

  // required questions without default are non skippable
  if (
    [
      SLUG__SUBMIT_DEBIT,
      SLUG__SUBMIT_DEBIT_MANUAL,
      SLUG__SUBMIT_BANK_NUMBERS,
      INCOME_SLUGS.FREELANCE_1099_TYPE,
      CAR_SLUGS.NAV_START,
      CAR_SLUGS.WORK_MILES_TRACKED,
      CAR_SLUGS.OTHER_PERSONAL,
      HOME_SLUGS.ADDRESS,
      HOME_SLUGS.NAV_START,
      HOME_SLUGS.OPTIONS,
      HOME_SLUGS.TYPE,
      HOME_SLUGS.PRESELECT_ADDRESS,
      SLUG__DC_RENT_CREDIT_PROPERTY_TYPE,
      SLUG__DC_RENT_CREDIT_OPTIONS,
      SLUG__SUBMIT_SIGNATURE,
      SLUG__SUBMIT_EMAIL_INFO,
      SLUG__SUBMIT_CONFIRM_ID,
      SLUG__CREDIT_BUSINESS_LOANS_PERCENT,
      SLUG__CREDIT_BUSINESS_LOANS_AMOUNT,
      SLUG__DEPENDENT_NAV_START
    ].includes(currentQuestion.slug)
  ) {
    return !hasQuestionError({ question: currentQuestion, answer: currentAnswer, status, queryResults });
  }

  if (currentQuestion.slug === SLUG__SUBMIT_PIN) {
    if (_.get(currentAnswer, ['value', SLUG__SUBMIT_HAVE_PIN, 'value']) !== '1') {
      return true;
    } else {
      const pinQuestion = currentQuestion.sub_question.find((x) => x.slug === SLUG__SUBMIT_SELF_PIN_NUMBER);
      const pinMinLength = _.get(pinQuestion, ['question_meta', 'minLength'], 6);
      const selfPin = _.get(currentAnswer, ['value', SLUG__SUBMIT_SELF_PIN_NUMBER, 'value']);
      const spousePin = _.get(currentAnswer, ['value', SLUG__SUBMIT_SPOUSE_PIN_NUMBER, 'value']);
      const selfPinValid = !_.isNil(selfPin) ? _.isEmpty(selfPin) || selfPin.length >= pinMinLength : false;
      const isSpousePinPresent = isQuestionPresent({
        question: currentQuestion.sub_question.find((x) => x.slug === SLUG__SUBMIT_SPOUSE_PIN_NUMBER),
        answer: currentAnswer,
        status
      });
      const spousePinValid = !_.isNil(spousePin) ? _.isEmpty(spousePin) || spousePin.length >= pinMinLength : false;

      if (isSpousePinPresent) {
        return selfPinValid && spousePinValid && (!_.isEmpty(selfPin) || !_.isEmpty(spousePin));
      }

      return selfPinValid && !_.isEmpty(selfPin);
    }
  }

  if (currentQuestion.slug === SLUG__HOME_ADDRESS_DETAIL) {
    const addressSubquestions = currentQuestion.sub_question || [];
    const multistateOnly = !addressSubquestions.some((q) => q.slug === SLUG__HOME_ADDRESS_STATE);

    if (isQuestionnaireFlow && multistateOnly) {
      const multistateOption = _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_MULTISTATE, 'value']);
      if (_.isNil(multistateOption)) {
        return false;
      }

      const secondState = _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE_MULTISTATE, 'value']);
      return multistateOption === '0' || !_.isEmpty(secondState);
    } else {
      const state = _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE, 'value']);
      return !_.isNil(state) && !_.isEmpty(state);
    }
  }

  // Require answer to be present for questions that determine the form sub type (without it, we don't know which form inputs to nav to!)
  if (
    currentQuestion.slug === INCOME_SLUGS.FREELANCE_1099_TYPE ||
    currentQuestion.slug === INCOME_SLUGS.RETIREMENT_TYPE
  ) {
    return !_.isEmpty(currentAnswer?.value);
  }

  if (currentQuestion.slug === SLUG__DEPENDENT_DETAIL) {
    const dependentFirstName = _.get(currentAnswer, ['value', ENDPOINT_ATTRIBUTE__DEPENDENT_FIRST_NAME, 'value']);
    return !_.isNil(dependentFirstName) && !_.isEmpty(dependentFirstName);
  }

  if (currentQuestion.question_type === CATEGORY_TYPE_BUSINESS_CODE) {
    const businessCode = _.get(currentAnswer, ['value', INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_BUSINESS_CODE, 'value']);
    const industry = _.get(currentAnswer, ['value', INCOME_ENDPOINT_ATTRIBUTES.FREELANCE_INDUSTRY, 'value']);
    return !_.isEmpty(businessCode) && !_.isEmpty(industry);
  }

  if (currentQuestion.slug === SLUG__STATE_RETURN) {
    return isValidInput({
      question: currentQuestion,
      answer: currentAnswer,
      status,
      queryResults,
      taxYear: year,
      taxData
    });
  }

  if (
    currentQuestion.slug === SLUG__SPECIAL_EARLY_EXIT ||
    currentQuestion.slug === SLUG__CONTACT_SUPPORT ||
    currentQuestion.slug === SLUG__PAST_RETURNS
  ) {
    return false;
  }

  if (
    currentQuestion.slug === SLUG__DEPENDENT_RELATIONSHIP_DETAIL &&
    !_.get(currentAnswer, ['value', SLUG__DEPENDENT_RELATIONSHIP_TYPE, 'value'])
  ) {
    return false;
  }

  if (
    currentQuestion.slug === SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL &&
    !_.get(currentAnswer, ['value', SLUG__DEPENDENT_RELATIONSHIP_RELATIVE_DETAIL_REASON, 'value'])
  ) {
    return false;
  }

  return true;
};
