import React, { useEffect } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import {
  useDismissSubmitWarningMutation,
  useGetSubmitIssuesQuery,
  useGetSubmitWarningsQuery,
  useGetUIStageQuery,
  useUpdateSubmitIssuesMutation
} from '@app/src/api/taxDataApi';
import LampIcon from '@app/src/assets/lamp-on.svg?react';
import WarningIcon from '@app/src/assets/warning-2.svg?react';
import { useDeepNavigation } from '@app/src/hooks/useDeepNavigation';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { UI_STAGE__CONFIRM_AMOUNTS, UI_STAGE_METADATA } from '@app/src/taxflow/shared/constants/sharedConstants';
import { colorNeutralMetallica, colorPrimary, colorPrimaryAlt3 } from '@app/src/theme';
import '@app/src/Components/TaxFlow/Question/TaxFlowGlobalWarnings.scss';

const SUBMIT_WARNING_VARIANT = {
  BLOCKING: 'blocking',
  NON_BLOCKING: 'non-blocking'
};

/**
 * Component rendering submit warnings and escalation errors
 * Submit warnings only surfaced pre-submit
 */
const TaxFlowGlobalWarnings = ({ setLoading = _.noop }) => {
  const year = useSelector(yearSelector);
  const navigateToPath = useDeepNavigation();

  const { data: submitWarnings, isLoading: submitWarningsLoading } = useGetSubmitWarningsQuery({ year });
  const { data: submitIssues, isLoading: submitIssuesLoading } = useGetSubmitIssuesQuery({ year });
  const { data: uiStage, isLoading: isUIStageLoading } = useGetUIStageQuery({ year });
  const [dismissSubmitWarning] = useDismissSubmitWarningMutation();
  const [updateSubmitIssues] = useUpdateSubmitIssuesMutation();

  const isLoading = submitWarningsLoading || submitIssuesLoading || isUIStageLoading;

  useEffect(() => {
    setLoading(isLoading);
  }, [setLoading, isLoading]);

  if (isLoading) {
    return null;
  }

  const isPreSubmit = _.isEmpty(uiStage) || _.some(UI_STAGE_METADATA, { stage: uiStage, preSubmit: true });
  const hideButtons = uiStage === UI_STAGE__CONFIRM_AMOUNTS;
  const navigateMakeChanges = ({ link }) => {
    navigateToPath({ path: link });
  };

  const resolveSubmitWarning = ({ slug, header }) => {
    trackActivity('dismiss submit warning', { slug, header });
    dismissSubmitWarning({ slug, year });
  };

  const resolveSubmitIssue = ({ slug, header, body }) => {
    trackActivity('dismiss submit warning', { slug, header });
    const updatedIssues = _.map(submitIssues, (submitIssue) =>
      _.isMatch(submitIssue, { header: header, body }) ? { ...submitIssue, isChecked: true } : submitIssue
    );
    updateSubmitIssues({ updatedIssues, year });
  };

  if (_.isEmpty(submitWarnings) && _.isEmpty(submitIssues)) {
    return null;
  }

  return (
    <div className='taxflow-submit-warnings'>
      {_.chain(submitIssues)
        .filter({ isChecked: false })
        .map((submitIssue) => (
          <TaxFlowGlobalWarning
            key={submitIssue.header}
            header={submitIssue.header}
            body={submitIssue.body}
            variant={
              hideButtons || submitIssue.isBlocking
                ? SUBMIT_WARNING_VARIANT.BLOCKING
                : SUBMIT_WARNING_VARIANT.NON_BLOCKING
            }
            link={!hideButtons && submitIssue.link}
            dismissText={!hideButtons && (submitIssue.isBlocking ? 'I FIXED IT' : 'SKIP')}
            onLinkClick={() => navigateMakeChanges({ link: submitIssue.link })}
            onDismiss={() =>
              resolveSubmitIssue({ slug: submitIssue.slug, header: submitIssue.header, body: submitIssue.body })
            }
          />
        ))
        .value()}
      {isPreSubmit &&
        _.map(submitWarnings, (submitWarning) => (
          <TaxFlowGlobalWarning
            key={submitWarning.slug}
            header={submitWarning.header}
            body={submitWarning.body}
            variant={
              hideButtons || submitWarning.isBlocking
                ? SUBMIT_WARNING_VARIANT.BLOCKING
                : SUBMIT_WARNING_VARIANT.NON_BLOCKING
            }
            link={!hideButtons && submitWarning.link}
            dismissText={!submitWarning.isBlocking && 'SKIP'}
            onLinkClick={() => navigateMakeChanges({ link: submitWarning.link })}
            onDismiss={() => resolveSubmitWarning({ slug: submitWarning.slug, header: submitWarning.header })}
          />
        ))}
    </div>
  );
};

export const TaxFlowGlobalWarning = ({ header, body, variant, link, dismissText, onLinkClick, onDismiss }) => {
  const isMobile = useIsMobile();
  return (
    <div
      className='taxflow-submit-warning'
      style={
        variant === SUBMIT_WARNING_VARIANT.BLOCKING
          ? { background: 'rgba(166, 15, 15, 0.10)' /** color rufous 10% opacity */ }
          : { backgroundColor: colorPrimaryAlt3 }
      }
    >
      <div className='taxflow-submit-warning-header'>
        <div className='taxflow-submit-warning-header-lhs'>
          {variant === SUBMIT_WARNING_VARIANT.BLOCKING ? (
            <WarningIcon className='taxflow-submit-warning-header-lhs-icon' width={20} height={20} />
          ) : (
            <LampIcon className='taxflow-submit-warning-header-lhs-icon' width={20} height={20} />
          )}
          <div>{header}</div>
        </div>
        {!isMobile && (
          <div className='taxflow-submit-warning-header-rhs'>
            <TaxFlowGlobalWarningButtons
              link={link}
              dismissText={dismissText}
              onLinkClick={onLinkClick}
              onDismiss={onDismiss}
            />
          </div>
        )}
      </div>
      {!_.isNil(body) && <div className='taxflow-submit-warning-body'>{body}</div>}
      {isMobile && (
        <TaxFlowGlobalWarningButtons
          link={link}
          dismissText={dismissText}
          onLinkClick={onLinkClick}
          onDismiss={onDismiss}
        />
      )}
    </div>
  );
};

const TaxFlowGlobalWarningButtons = ({ link, dismissText, onLinkClick, onDismiss }) => {
  const shouldShowEditButton = !_.isEmpty(link);
  const shouldShowDismissButton = !_.isEmpty(dismissText);

  if (!shouldShowEditButton && !shouldShowDismissButton) return null;

  return (
    <div style={{ display: 'flex', gap: 8 }}>
      {shouldShowEditButton && (
        <Button
          fullWidth
          variant='pill'
          onClick={onLinkClick}
          style={{ backgroundColor: colorPrimary, color: 'white', borderColor: 'black' }}
        >
          EDIT
        </Button>
      )}
      {shouldShowDismissButton && (
        <Button
          fullWidth
          variant='pill'
          onClick={onDismiss}
          style={{ backgroundColor: 'white', borderColor: colorNeutralMetallica }}
        >
          {dismissText}
        </Button>
      )}
    </div>
  );
};

export default TaxFlowGlobalWarnings;
