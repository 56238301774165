import React, { useEffect } from 'react';
import BaseTaxProfileForm from '@app/src/Components/TaxProfile/BaseTaxProfileForm';
import TaxEstimateDetails from '@app/src/Components/TaxProfile/TaxEstimateDetails';
import useTaxProfile from '@app/src/Components/TaxProfile/hooks/useTaxProfile';
import { trackActivity } from '@app/src/services/analyticsService';

const TaxProfile = (props) => {
  const { formProps, detailsProps, isLoading, year } = useTaxProfile({ year: props.year });

  useEffect(() => {
    trackActivity('navigation: tax profile', {
      year
    });
  }, [year]);

  return (
    <BaseTaxProfileForm
      renderBeforeSections={() => {
        if (isLoading) {
          return <TaxEstimateDetails isLoading />;
        }

        return <TaxEstimateDetails {...detailsProps} />;
      }}
      {...formProps}
      {...props}
    />
  );
};

export default TaxProfile;
