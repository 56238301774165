import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import TaxFlowListItem from '@app/src/Components/TaxFlow/Question/TaxFlowListItem';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import { currentAnswerSelector, resultLoadingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { isCalendarMonths } from '@app/src/taxflow/shared/utils/sharedUtils';

class TaxFlowCalendarItem extends Component {
  constructor(props) {
    super(props);
    let months = [];
    for (let i = 1; i <= 12; i++) {
      //Set all month selected by default
      months.push({ value: i, text: moment(i, 'MM').format('MMMM'), isSelected: true });
    }

    this.state = {
      months: months
    };
  }

  /**
   * @desc save user input in question answer and set answer for analytics and signup
   */
  handleChange = (result) => {
    const { currentQuestion, currentAnswer } = this.props;

    const newAnswer = {
      ...(!isCalendarMonths(currentQuestion) ? { value: result.value } : {}),
      months: `${result.value}` === '1' ? [] : currentAnswer.months
    };
    this.props.setCurrentAnswer(newAnswer);

    // if "all of 2019" is selected then auto move to the next Quest
    if (`${result.value}` === '0') this.props.onNextQuest();
  };

  /**
   * @desc handles month selection changes
   */
  handleMonthSelection = (index) => {
    const { currentQuestion, currentAnswer } = this.props;
    const currentMonths = currentAnswer.months ? currentAnswer.months : [];
    let newMonths = [];
    if (this.isChecked(index)) {
      newMonths = currentMonths.filter((x) => x !== index);
    } else {
      newMonths = [...currentMonths, index];
    }
    newMonths = _.sortBy(_.uniq(newMonths));

    const newAnswer = {
      ...(!isCalendarMonths(currentQuestion) ? { value: '1' } : {}),
      months: newMonths
    };
    this.props.setCurrentAnswer(newAnswer);
  };

  isChecked = (month) => {
    const { currentAnswer } = this.props;
    const currentMonths = currentAnswer.months ? currentAnswer.months : [];
    return currentMonths.indexOf(month) !== -1;
  };

  isMonthPast = (item) => {
    const monthDiff = moment().diff(moment({ year: 2019, month: item.value - 1, date: 1 }), 'months');
    return monthDiff >= 0;
  };

  renderMonths() {
    const { currentQuestion } = this.props;

    return (
      <div className='calendar-item-wrap'>
        {this.state.months
          .filter((item) => this.isMonthPast(item))
          .map((item, index) => (
            <div className='checkbox-item-calendar' key={currentQuestion.id + '_' + item.value}>
              <TaxFlowListItem
                item={item}
                handleChange={() => this.handleMonthSelection(index)}
                checked={this.isChecked(index)}
                key={item.value}
              >
                <span>{this.props.replaceStrings(item.text)}</span>
              </TaxFlowListItem>
            </div>
          ))}
      </div>
    );
  }

  render() {
    const { currentQuestion, currentAnswer, resultLoading } = this.props;

    if (resultLoading) return null;

    if (_.isEmpty(currentQuestion.question_meta)) {
      return <div className='steps-body'>{this.renderMonths()}</div>;
    }

    return (
      <>
        <div className='steps-body'>
          <div className='checkbox-list'>
            {currentQuestion.question_meta &&
              currentQuestion.question_meta.length > 0 &&
              currentQuestion.question_meta.map((item, index) => (
                <div className='checkbox-item' key={index}>
                  <TaxFlowListItem
                    item={item}
                    handleChange={() => this.handleChange(item)}
                    checked={
                      currentAnswer && !_.isNil(currentAnswer.value)
                        ? `${item.value}` === `${currentAnswer.value}`
                        : item.default === 1
                    }
                    key={item.value}
                  >
                    <span>{this.props.replaceStrings(item.text)}</span>
                  </TaxFlowListItem>
                </div>
              ))}
          </div>
          {`${currentAnswer.value}` === '1' && (
            <div className='steps-head'>
              <h3>Which months?</h3>
              {this.renderMonths()}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentAnswer: currentAnswerSelector(state),
  resultLoading: resultLoadingSelector(state)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowCalendarItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowCalendarItem);

export default ConnectedTaxFlowCalendarItem;
