import React, { forwardRef } from 'react';
import _ from 'lodash';
import CarExpenseSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/CarExpenseSlider';
import DeductibleMealsEnabledInput from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/DeductibleMealsEnabledInput';
import DeductibleTravelEnabledInput from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/DeductibleTravelEnabledInput';
import HomeOfficeSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/HomeOfficeSlider';
import JobsInput from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/JobsInput';
import PhoneSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/PhoneSlider';
import PublicTransportSlider from '@app/src/Components/TaxProfile/FormInputs/CategoryUsage/PublicTransportSlider';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';
import { useTaxProfileFormContext } from '@app/src/Components/TaxProfile/TaxProfileFormContext';

const CategoryUsageSection = forwardRef((_props, ref) => {
  const { jobType } = useTaxProfileFormContext();

  return (
    <TaxProfileFormSection header={`${_.capitalize(jobType)} details`} id='category-usage-section' ref={ref}>
      <JobsInput />
      <DeductibleMealsEnabledInput />
      <DeductibleTravelEnabledInput />
      <CarExpenseSlider />
      <HomeOfficeSlider />
      <PhoneSlider />
      <PublicTransportSlider />
    </TaxProfileFormSection>
  );
});

export default CategoryUsageSection;
