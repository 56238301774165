import React, { Component } from 'react';
import _ from 'lodash';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import TaxFlowFormSubQuestionItem from '@app/src/Components/TaxFlow/Form/TaxFlowFormSubQuestionItem';
import { updateCurrentAnswer } from '@app/src/actions/taxFlowActions';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { dismissOcrError, dismissUncommonOcrErrors } from '@app/src/services/taxFlowService';
import { deleteCollection } from '@app/src/taxflow/collection/services/collectionService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getNextCollectionId } from '@app/src/taxflow/main/services/taxFlowDataService';
import { deserializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import {
  INCOME_COLLECTION_TYPES,
  INCOME_INVEST_INTRO_SLUGS,
  INCOME_INVEST_LONG_TERM_SLUGS,
  INCOME_INVEST_SHORT_TERM_SLUGS,
  INCOME_INVEST_UNKNOWN_TERM_SLUGS,
  INCOME_SLUGS
} from '@app/src/taxflow/sections/income/incomeConstants';
import {
  currentCollectionIdSelector,
  currentQuestionSelector,
  queryResultsSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQuestionOcrError } from '@app/src/taxflow/shared/utils/sharedUtils';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';

class TaxFlow1099BItem extends Component {
  handleChange = (result, collId) => {
    if (result.slug === INCOME_SLUGS.INVEST_UNKNOWN_TERM && result.value === '0') {
      const newAnswer = {
        value: {
          ..._.get(this.props.currentAnswer, 'value')
        }
      };
      const unknownTermCollIds = Object.keys(_.get(this.props.currentAnswer, ['value']))
        .map((key) => Number(key))
        .filter((collId) => collId > Number(this.props.currentCollectionId) + 1);
      unknownTermCollIds.forEach((collId) => {
        this.props.deleteCollection({
          currentQuestion: this.props.currentQuestion,
          collectionType: INCOME_COLLECTION_TYPES.INVEST,
          collectionId: `${collId}`
        });
        delete newAnswer.value[collId];
      });
      newAnswer.value[this.props.currentCollectionId] = {
        ...newAnswer.value[this.props.currentCollectionId],
        [INCOME_SLUGS.INVEST_UNIFICATION]: {
          value: JSON.stringify([Number(this.props.currentCollectionId), Number(this.props.currentCollectionId) + 1])
        },
        [INCOME_SLUGS.INVEST_UNKNOWN_TERM]: { value: '0' }
      };
      this.props.updateCurrentAnswer({
        answer: newAnswer,
        replaceAll: true
      });
    } else {
      this.props.updateCurrentAnswer({
        slug: result.slug,
        answer: result,
        collId
      });
    }
  };

  handleFocus = (result, collId) => {
    const ocrError = getQuestionOcrError({
      errors: this.props.errors,
      question: { endpoint_attr: result.endpoint_attr, collectionType: this.props.currentQuestion.collectionType },
      collectionId: `${collId}`
    });
    if (ocrError) {
      const newErrors = this.props.errors.filter(
        (error) =>
          !(
            error.slug === result.endpoint_attr &&
            error.coll_type === this.props.currentQuestion.collectionType &&
            error.coll_id === `${collId}` &&
            error.code === 'ocr_error'
          )
      );
      this.props.setErrors(newErrors);
      this.props.dismissOcrError({ error: ocrError });
    }

    this.props.onFocus &&
      this.props.onFocus(
        {
          endpoint_attr: result.endpoint_attr,
          collectionType: this.props.currentQuestion.collectionType
        },
        `${collId}`
      );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onNext();
  };

  addUnknownTermCollection = async () => {
    const nextCollStartedId = await this.props.getNextCollectionId({ collectionType: INCOME_COLLECTION_TYPES.INVEST });
    const nextCurrentAnswerId =
      Math.max(...Object.keys(_.get(this.props.currentAnswer, 'value')).map((val) => Number(val))) + 1;
    const nextId = Math.max(nextCollStartedId, nextCurrentAnswerId);

    const newEntryValues = this.props.currentQuestion.sub_question.reduce((result, subQuestion) => {
      const queryResult = this.props.queryResults.filter(
        (qr) =>
          qr.coll_type === subQuestion.collectionType && nextId === qr.coll_id && qr.slug === subQuestion.endpoint_attr
      );

      const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year: this.props.year });
      result = {
        ...result,
        [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
      };
      return result;
    }, {});

    const newAnswer = {
      value: {
        ..._.get(this.props.currentAnswer, 'value'),
        [nextId]: {
          ...newEntryValues
        }
      }
    };

    this.props.updateCurrentAnswer({
      answer: newAnswer,
      replaceAll: true
    });
  };

  renderQuestionInputType = (question, collId) => {
    const { currentAnswer } = this.props;

    const answer = _.get(currentAnswer, ['value', collId, question.slug], null);
    if (
      question.slug === INCOME_SLUGS.INVEST_EARNER &&
      !isQuestionPresent({
        question,
        answer,
        status: this.props.status
      })
    ) {
      return null;
    }

    if (!_.get(this.props.conditionalFieldMap, question.slug, true)) {
      return null;
    }

    return (
      <TaxFlowFormSubQuestionItem
        {...this.props}
        onChange={(result) => this.handleChange(result, collId)}
        onFocus={(result) => this.handleFocus(result, collId)}
        onBlur={this.props.onBlur}
        question={question}
        answer={answer}
        overrideCollectionId={collId}
      />
    );
  };

  renderSubQuestion = (subQuestions, slug, collectionId) => {
    const question = subQuestions.find((q) => q.slug === slug);
    if (!question) {
      return null;
    }
    return <div key={`${question.slug}${collectionId}`}>{this.renderQuestionInputType(question, collectionId)}</div>;
  };

  render() {
    const { currentQuestion, resultLoading, currentAnswer, currentCollectionId } = this.props;

    const collectionId = Number(currentCollectionId);

    const hasUnknownTerm =
      _.get(currentAnswer, ['value', collectionId, INCOME_SLUGS.INVEST_UNKNOWN_TERM, 'value']) === '1';

    if (resultLoading || !currentQuestion.sub_question) return null;

    const subQuestions = currentQuestion.sub_question;

    const unknownTermCollIds = Object.keys(_.get(currentAnswer, 'value', {}))
      .map((key) => Number(key))
      .filter((collId) => collId > collectionId + 1);

    const sections = [
      { collectionId, slugs: INCOME_INVEST_INTRO_SLUGS },
      { collectionId, slugs: INCOME_INVEST_SHORT_TERM_SLUGS },
      { collectionId: collectionId + 1, slugs: INCOME_INVEST_LONG_TERM_SLUGS },
      { collectionId, slugs: [INCOME_SLUGS.INVEST_UNKNOWN_TERM] }
    ];

    return (
      <div>
        <form className='steps-signup-form' onSubmit={this.handleSubmit}>
          <div className={'steps-body'}>
            {sections.map((section) =>
              section.slugs.map((slug) => this.renderSubQuestion(subQuestions, slug, section.collectionId))
            )}
            {hasUnknownTerm ? (
              <>
                {unknownTermCollIds.map((collId) =>
                  INCOME_INVEST_UNKNOWN_TERM_SLUGS.map((slug) => this.renderSubQuestion(subQuestions, slug, collId))
                )}
                <Button
                  fullWidth
                  variant='label'
                  onClick={this.addUnknownTermCollection}
                  className='tax-flow-add-stock-btn'
                >
                  Add another stock
                </Button>
              </>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  queryResults: queryResultsSelector(state),
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: state.taxFlow.currentAnswer,
  currentCollectionId: currentCollectionIdSelector(state),
  errors: errorsSelector(state),
  status: statusSelector(state),
  year: yearSelector(state)
});

const mapDispatchToProps = {
  updateCurrentAnswer,
  deleteCollection,
  setErrors,
  dismissOcrError,
  dismissUncommonOcrErrors,
  getNextCollectionId
};

const ConnectedTaxFlow1099BItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlow1099BItem);

export default ConnectedTaxFlow1099BItem;
