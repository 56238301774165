import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TaxFlowQuestionItem from '@app/src/Components/TaxFlow/Question/TaxFlowQuestionItem';
import { setChangedEndpointAttributes, setCurrentlyFocusedEndpointAttribute } from '@app/src/actions/taxFlowActions';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { useGoBackToPreviousQuestionnaireQuestionMutation } from '@app/src/api/taxDataApi';
import { useLazyGetValidationErrorsQuery } from '@app/src/api/taxValidationApi';
import { useRedirectOnInvalidCollectionId } from '@app/src/hooks/useRedirectOnInvalidCollectionId';
import {
  allTaxDataUpdatesSelector,
  changedEndpointAttributesSelector,
  isQuestionnaireFlowSelector
} from '@app/src/selectors/taxFlowSelectors';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import store from '@app/src/store/store';
import { replaceStrings } from '@app/src/taxflow/common';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { showNextBtn } from '@app/src/taxflow/main/utils/mainUtils';
import { SLUG__BULK_UPLOAD_QUESTIONS } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  currentAnswerSelector,
  currentCollectionIdSelector,
  currentQuestionSelector,
  queryResultsSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { scrollToTop } from '@app/src/utils';

const TaxFlowQuestion = (props) => {
  const {
    currentQuestion,
    currentAnswer,
    errors,
    history,
    isQuestionnaireFlow,
    currentCollectionId,
    onPrev,
    onNext,
    replaceStrings,
    setErrors
  } = props;
  const year = useSelector(yearSelector);
  const [getValidationErrors] = useLazyGetValidationErrorsQuery();
  const dispatch = useDispatch();
  const changedEndpointAttributes = useSelector(changedEndpointAttributesSelector);

  useRedirectOnInvalidCollectionId();

  const [goBackToPreviousQuestionnaireQuestion] = useGoBackToPreviousQuestionnaireQuestionMutation();
  const handlePrev = async () => {
    trackActivity('question: back', {
      flow: currentQuestion.flow,
      title: currentQuestion.title,
      question: isQuestionnaireFlow ? SLUG__BULK_UPLOAD_QUESTIONS : currentQuestion.slug,
      ...(isQuestionnaireFlow && {
        clarifyingQuestionSlug: currentQuestion.slug
      })
    });
    if (onPrev) {
      onPrev();
      return;
    }

    // If "back" is clicked while in the questionnaire flow, navigate to the previous questionnaire question.
    // If the user exits the flow (occurs if they are on the first question), navigate back to bulk upload
    if (isQuestionnaireFlow) {
      const {
        data: { questionnaireExited }
      } = await goBackToPreviousQuestionnaireQuestion({ year });
      if (!questionnaireExited) {
        return;
      }
    }

    history.goBack();
  };

  const handleNext = () => {
    if (onNext) {
      onNext();
      return;
    }
  };

  const handleFocus = (question = currentQuestion, collectionId = currentCollectionId) => {
    dispatch(setCurrentlyFocusedEndpointAttribute(question.endpoint_attr));
    const collectionType = _.get(question, 'collectionType', _.get(currentQuestion, 'collectionType'));
    const endpoint_attr = _.get(question, 'endpoint_attr', _.get(currentQuestion, 'endpoint_attr'));

    const error = _.find(errors, {
      coll_type: collectionType,
      slug: endpoint_attr,
      coll_id: collectionId
    });

    if (error) {
      _.chain(errors)
        .filter(
          (err) => !(err.slug === error.slug && err.coll_type === error.coll_type && err.coll_id === error.coll_id)
        )
        .tap(setErrors)
        .value();
    }
  };

  const handleBlur = ({ question = currentQuestion, answer = currentAnswer } = {}) => {
    dispatch(setCurrentlyFocusedEndpointAttribute(null));
    if (_.isEmpty(question) || _.isEmpty(answer)) {
      return;
    }
    const updatedTaxData = allTaxDataUpdatesSelector(store.getState());
    const newChangedEndpointAttributes = { ...changedEndpointAttributes, [question.endpoint_attr]: true };
    dispatch(setChangedEndpointAttributes(newChangedEndpointAttributes));
    getValidationErrors({ year, taxData: updatedTaxData }).then(({ data: errors }) => {
      const changedSlugs = new Set(Object.keys(newChangedEndpointAttributes));
      const errorsToSurface = errors.filter((error) => changedSlugs.has(error.slug));
      if (!_.isEmpty(errorsToSurface)) {
        for (const error of errorsToSurface) {
          trackActivity('question: live validation error', {
            question: error.slug,
            blocking: error.blocking,
            collectionId: error.coll_id,
            collectionType: error.coll_type
          });
        }
      }
      setErrors(errorsToSurface);
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <TaxFlowQuestionItem
      isPrev
      {...props}
      urlObj={{}}
      isNext={showNextBtn({ slug: currentQuestion.slug })}
      onNextQuest={handleNext}
      onPrev={handlePrev}
      onNext={handleNext}
      onFocus={handleFocus}
      onBlur={handleBlur}
      replaceStrings={replaceStrings}
    />
  );
};

const mapStateToProps = (state) => ({
  bank: state.bank,
  currentQuestion: currentQuestionSelector(state),
  currentAnswer: currentAnswerSelector(state),
  queryResults: queryResultsSelector(state),
  currentCollectionId: currentCollectionIdSelector(state),
  status: statusSelector(state),
  errors: errorsSelector(state),
  isQuestionnaireFlow: isQuestionnaireFlowSelector(state)
});

const mapDispatchToProps = {
  replaceStrings,
  setErrors
};

const ConnectedTaxFlowQuestion = connect(mapStateToProps, mapDispatchToProps)(withRouter(TaxFlowQuestion));

export default ConnectedTaxFlowQuestion;
