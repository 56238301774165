import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationDatePicker from '@app/src/Components/TaxValidation/TaxValidationDatePicker';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import {
  currentAnswerSelector,
  currentQuestionSelector,
  resultLoadingSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';

const TaxFlowDateItem = ({ onFocus = _.noop, onBlur = _.noop }) => {
  const currentQuestion = useSelector(currentQuestionSelector);
  const currentAnswer = useSelector(currentAnswerSelector);
  const resultLoading = useSelector(resultLoadingSelector);

  const dispatch = useDispatch();

  const handleChange = ({ value }) => {
    dispatch(setCurrentAnswer({ value }));
  };

  if (resultLoading || !currentQuestion || !currentAnswer) return null;

  return (
    <div className='tax-flow-input-wrap steps-body'>
      <TaxValidationDatePicker
        question={currentQuestion}
        answer={currentAnswer}
        placeholder={_.get(currentQuestion, ['question_meta', 'default'], '')}
        required={currentQuestion.required}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={_.get(currentAnswer, 'value')}
      />
      <TaxFlowInfoButton currentQuestion={currentQuestion} />
      <TaxValidationWarnings question={currentQuestion} answer={currentAnswer} />
    </div>
  );
};

export default TaxFlowDateItem;
