import React from 'react';
import DependentsInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/DependentsInput';
import FilingStatusInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/FilingStatusInput';
import StateInput from '@app/src/Components/TaxProfile/FormInputs/HouseholdDetails/StateInput';
import TaxProfileFormSection from '@app/src/Components/TaxProfile/FormSections/TaxProfileFormSection';

/**
 * @typedef HouseholdDetailsSectionProps
 * @property {import('react-hook-form').UseFormReturn['control']} control
 * @property {import('react-hook-form').UseFormReturn['handleSubmit']} handleSubmit
 * @property {string} filingStatus
 */

/** @type {React.FC<HouseholdDetailsSectionProps>} */
const HouseholdDetailsSection = () => {
  return (
    <TaxProfileFormSection header='Household details'>
      <StateInput />
      <FilingStatusInput />
      <DependentsInput />
    </TaxProfileFormSection>
  );
};

export default HouseholdDetailsSection;
