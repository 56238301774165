import _ from 'lodash';
import { createSelector } from 'reselect';
import { submitIssueItemsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { SUBMIT_AUDIT_ISSUE, SUBMIT_AUDIT_TYPE } from '@app/src/taxflow/sections/submit/constants/submitConstants';

export const submitIssueTypeSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  const submitIssueType = submitIssueItems.find((s) => s.slug === SUBMIT_AUDIT_TYPE);
  return _.get(submitIssueType, 'value');
});

export const uncheckedSubmitIssueMessagesSelector = createSelector([submitIssueItemsSelector], (submitIssueItems) => {
  return submitIssueItems.filter((e) => e.slug === SUBMIT_AUDIT_ISSUE && !e.isChecked);
});
