import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';
import {
  EXPENSE_REVIEW_STATUS_OPTIONS_LIST,
  maybeStatusesArr,
  noStatusesArr,
  yesStatusesArr
} from '@app/src/constants/constants';
import { currencyWith2DecimalPlaces } from '@app/src/global/Helpers';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const expensesErrorSelector = (state) => _.get(state, ['expenseReview', 'expensesError']);
export const expensesSelector = (state) => _.get(state, ['expenseReview', 'expenses']);
export const orderedTransactionIdsSelector = (state) =>
  _.get(state, ['expenseReview', 'expenses', 'orderedTransactionIds']);
export const expensesByIdSelector = (state) => _.get(state, ['expenseReview', 'expenses', 'expensesById']);
export const loadingSelector = (state) => _.get(state, ['expenseReview', 'loading']);
export const expensesYearSelector = (state) => _.get(state, ['expenseReview', 'expensesYear']);
export const isInitialExpensesLoadSelector = (state) => _.get(state, ['expenseReview', 'isInitialExpensesLoad']);
export const hasFetchedMaxExpensesSelector = (state) => _.get(state, ['expenseReview', 'hasFetchedMaxExpenses']);
export const savingsAmountSelector = (state) => _.get(state, ['expenseReview', 'savingsAmount']);
const accountsSelector = (state) => _.get(state, ['expenseReview', 'accounts']);
export const categoryFilterSelectionsSelector = (state) => _.get(state, ['expenseReview', 'categoryFilterSelections']);
export const accountFilterSelectionsSelector = (state) => _.get(state, ['expenseReview', 'accountFilterSelections']);
export const statusFilterSelectionsSelector = (state) => _.get(state, ['expenseReview', 'statusFilterSelections']);
export const amountFilterSelectionsSelector = (state) => _.get(state, ['expenseReview', 'amountFilterSelections']);
export const merchantNameSearchSelector = (state) => _.get(state, ['expenseReview', 'merchantNameSearch']);
export const merchantSearchResultsSelector = (state) => _.get(state, ['expenseReview', 'merchantSearchResults']);
export const merchantSearchLoadingSelector = (state) => _.get(state, ['expenseReview', 'merchantSearchLoading']);
export const rulesSelector = (state) => _.get(state, ['expenseReview', 'rules']);
export const currentRuleSelector = (state) => _.get(state, ['expenseReview', 'currentRule']);
export const autoRuleSelector = (state) => _.get(state, ['expenseReview', 'autoRule']);
export const merchantNameFilterQuerySelector = (state) => _.get(state, ['expenseReview', 'merchantNameFilterQuery']);
export const selectedExpenseSelector = (state) => _.get(state, ['expenseReview', 'selectedExpense']);
export const allCategoriesSelector = (state) => _.get(state, ['expenseReview', 'allCategories']);
export const isModalSubmittingSelector = (state) => _.get(state, ['expenseReview', 'isModalSubmitting']);
/**
 * Gets the current type of expense review modal being displayed
 * @param {Object} state - Redux state
 * @returns {string|null} Modal type from EXPENSE_REVIEW_MODAL_TYPES
 * @see {EXPENSE_REVIEW_MODAL_TYPES} in @app/src/constants/dashboardConstants.js for possible values
 */
export const expenseReviewModalTypeSelector = (state) => _.get(state, ['expenseReview', 'expenseReviewModalType']);
export const manualExpenseDataSelector = (state) => _.get(state, ['expenseReview', 'manualExpenseData']);
export const manualExpenseAmountSelector = (state) => _.get(state, ['expenseReview', 'manualExpenseAmount']);
export const expenseReviewModalShowSelector = (state) => _.get(state, ['expenseReview', 'expenseReviewModalShow']);
export const sidebarComponentSelector = (state) => _.get(state, ['expenseReview', 'sidebarComponent']);
export const sidebarOptionsSelector = (state) => _.get(state, ['expenseReview', 'sidebarOptions']);
export const retroIntervalSelector = (state) => _.get(state, ['expenseReview', 'retroInterval']);
export const isExportingSelector = (state) => _.get(state, ['expenseReview', 'isExporting']);
export const bulkEditExpensesSelector = (state) => _.get(state, ['expenseReview', 'bulkEditExpenses']);
export const bulkEditLoadingSelector = (state) => _.get(state, ['expenseReview', 'bulkEditLoading']);

const allExpensesSelector = createSelector([expensesByIdSelector], (expensesById) => {
  return Object.values(expensesById);
});

export const bulkEditToolbarVisibleSelector = createSelector(
  [bulkEditExpensesSelector, bulkEditLoadingSelector],
  (bulkEditExpenses, bulkEditLoading) => {
    return bulkEditExpenses.length > 0 || bulkEditLoading;
  }
);

export const expensesYearStartSelector = createSelector(expensesYearSelector, (expensesYear) =>
  expensesYear ? moment(expensesYear, 'YYYY').startOf('year').format('YYYY-MM-DD') : null
);

export const expensesCountSelector = createSelector([orderedTransactionIdsSelector], (orderedTransactionIds) => {
  return _.size(orderedTransactionIds);
});

export const expensesYearEndSelector = createSelector(expensesYearSelector, (expensesYear) =>
  expensesYear ? moment(expensesYear, 'YYYY').endOf('year').format('YYYY-MM-DD') : null
);

export const categoryFilterOptionsListSelector = createSelector([allCategoriesSelector], (allCategories) => {
  return allCategories.map((category) => {
    return {
      value: category.id,
      displayName: _.upperFirst(category.display_name_without_emoji),
      relatedTerms: category.related_terms
    };
  });
});

export const accountFilterOptionsListSelector = createSelector([accountsSelector], (accounts) => {
  return Object.keys(accounts).map((key) => {
    return {
      accountIds: accounts[key],
      displayName: key
    };
  });
});

export const orderedExpensesSelector = createSelector(
  [orderedTransactionIdsSelector, expensesByIdSelector],
  (orderedTransactionIds, expensesById) => {
    return orderedTransactionIds.map((id) => {
      const expense = expensesById[id];

      return {
        ...expense,
        isCompleted: yesStatusesArr.includes(expense.status) || noStatusesArr.includes(expense.status),
        isWriteOff: yesStatusesArr.includes(expense.status),
        isPersonal: noStatusesArr.includes(expense.status)
      };
    });
  }
);

const deductibleExpensesSelector = createSelector([allExpensesSelector, yearSelector], (expenses, year) => {
  return _.filter(expenses, (expense) => {
    const isSelectedYear = moment(expense.date, 'YYYY-MM-DD').year() === Number(year);

    return expense.isDeductible && isSelectedYear;
  });
});

const deductionsByCategoryIdSelector = createSelector([deductibleExpensesSelector], (expenses) => {
  return _.groupBy(expenses, 'keeper_category_id');
});

export const categoryStatisticsSelector = createSelector([deductionsByCategoryIdSelector], (deductionsByCategoryId) => {
  return _.chain(deductionsByCategoryId)
    .entries()
    .map(([categoryId, expenses]) => ({
      categoryId: Number(categoryId),
      deductionAmount: _.sumBy(expenses, 'deductionAmount'),
      count: _.size(expenses)
    }))
    .orderBy(['deductionAmount'], ['desc'])
    .value();
});

export const deductionsSelector = createSelector([deductibleExpensesSelector], (expenses) => {
  return _.sumBy(expenses, 'deductionAmount');
});

export const isCategoryFilterSelectedSelector = createSelector(
  [categoryFilterSelectionsSelector],
  (categoryFilterSelections) => {
    return categoryFilterSelections?.length > 0;
  }
);

export const isAccountFilterSelectedSelector = createSelector(
  [accountFilterSelectionsSelector],
  (accountFilterSelections) => {
    return accountFilterSelections?.length > 0;
  }
);

export const isStatusFilterSelectedSelector = createSelector(
  [statusFilterSelectionsSelector],
  (statusFilterSelections) => {
    return statusFilterSelections?.length > 0;
  }
);

export const isAmountFilterSelectedSelector = createSelector(
  [amountFilterSelectionsSelector],
  (amountFilterSelections) => {
    return amountFilterSelections?.length > 0;
  }
);

export const hasFiltersSelectedSelector = createSelector(
  [
    isCategoryFilterSelectedSelector,
    isAccountFilterSelectedSelector,
    isStatusFilterSelectedSelector,
    isAmountFilterSelectedSelector,
    merchantNameFilterQuerySelector
  ],
  (
    isCategoryFilterSelected,
    isAccountFilterSelected,
    isStatusFilterSelected,
    isAmountFilterSelected,
    merchantNameFilterQuery
  ) => {
    return (
      isCategoryFilterSelected ||
      isAccountFilterSelected ||
      isStatusFilterSelected ||
      isAmountFilterSelected ||
      merchantNameFilterQuery
    );
  }
);

export const searchStatusSelector = createSelector([statusFilterSelectionsSelector], (statusFilterSelections) => {
  const combinedStatuses = statusFilterSelections.reduce((acc, status) => {
    if (yesStatusesArr.includes(status)) {
      acc.push(...yesStatusesArr);
    }
    if (maybeStatusesArr.includes(status)) {
      acc.push(...maybeStatusesArr);
    }
    if (noStatusesArr.includes(status)) {
      acc.push(...noStatusesArr);
    }
    return acc;
  }, []);

  return combinedStatuses;
});

export const recategorizeOptionsListSelector = createSelector([allCategoriesSelector], (allCategories) => {
  return allCategories
    .filter((c) => c.transaction_type !== 'income')
    .map((category) => {
      return {
        value: category.id,
        displayName: _.upperFirst(category.display_name_without_emoji),
        relatedTerms: category.related_terms
      };
    });
});

export const manualExpenseCategoryOptionsListSelector = createSelector([allCategoriesSelector], (allCategories) => {
  return allCategories
    .filter((c) => c.transaction_type === 'personal_deduction' || c.transaction_type === 'possible_business_deduction')
    .map((category) => {
      return {
        value: category.id,
        displayName: _.upperFirst(category.display_name_without_emoji),
        relatedTerms: category.related_terms
      };
    });
});

export const categoryIdsToDisplayNamesSelector = createSelector([allCategoriesSelector], (allCategories) => {
  return allCategories.reduce((acc, category) => {
    acc[category.id] = _.upperFirst(category.display_name_without_emoji);
    return acc;
  }, {});
});

export const selectedExpenseAllDataSelector = createSelector(
  [selectedExpenseSelector, allCategoriesSelector],
  (selectedExpense, allCategories) => {
    const matchedValue = _.find(EXPENSE_REVIEW_STATUS_OPTIONS_LIST, {
      value: _.get(selectedExpense, ['data', 'status'])
    });
    const isPersonal = noStatusesArr.includes(_.get(selectedExpense, ['data', 'status']));
    const businessUsePercentage = _.get(selectedExpense, ['data', 'businessUsePercentage'], 100);
    const statusDisplayName = _.get(matchedValue, 'displayName', 'Select status');
    const status = matchedValue ? _.get(selectedExpense, ['data', 'status'], '') : '';
    const amountForWork = currencyWith2DecimalPlaces(
      _.get(selectedExpense, ['data', 'amount']) * (businessUsePercentage / 100)
    );

    const transactionCategory = allCategories.find(
      (category) => category.id === _.get(selectedExpense, ['data', 'keeper_category_id'])
    );

    return {
      ...selectedExpense?.data,
      statusDisplayName,
      status,
      amountForWork,
      isPersonal,
      businessUsePercentage,
      transactionType: transactionCategory?.transaction_type,
      drakeSection: transactionCategory?.drake_section
    };
  }
);

const hasNewBusinessUsePercentageSelector = createSelector(
  [selectedExpenseAllDataSelector, expensesByIdSelector],
  (selectedExpenseAllData, expensesById) => {
    const originalBusinessUsePercentage = _.get(
      expensesById,
      [selectedExpenseAllData.transaction_id, 'businessUsePercentage'],
      100
    );
    const newBusinessUsePercentage = _.get(selectedExpenseAllData, 'businessUsePercentage', 100);

    return originalBusinessUsePercentage !== newBusinessUsePercentage;
  }
);

export const hasEditedSelectedExpenseSelector = createSelector(
  [selectedExpenseSelector, expensesByIdSelector, hasNewBusinessUsePercentageSelector],
  (selectedExpense, expensesById, hasNewBusinessUsePercentage) => {
    const selectedExpenseData = _.get(selectedExpense, 'data', {});
    const originalSelectedExpenseData = _.get(expensesById, [selectedExpense?.transactionId], {});

    return (
      originalSelectedExpenseData.transaction_id &&
      (selectedExpenseData.status !== originalSelectedExpenseData.status ||
        selectedExpenseData.keeper_category_id !== originalSelectedExpenseData.keeper_category_id ||
        hasNewBusinessUsePercentage ||
        selectedExpenseData.note !== originalSelectedExpenseData.note)
    );
  }
);

export const hasValidManualExpenseDataSelector = createSelector(
  [manualExpenseDataSelector, manualExpenseAmountSelector],
  (manualExpenseData, manualExpenseAmount) => {
    const category = _.get(manualExpenseData, 'keeper_category_id');
    const date = _.get(manualExpenseData, 'date');
    const isValidDate = moment(date, 'YYYY-MM-DD').isSameOrBefore(moment());
    const merchant = _.get(manualExpenseData, 'description');

    return manualExpenseAmount && !_.isNil(category) && merchant && isValidDate;
  }
);

export const hideStatusEditModalSelector = createSelector(
  [selectedExpenseSelector, allCategoriesSelector],
  (selectedExpense, categories) => {
    const selectedCategory = _.get(selectedExpense, ['data', 'keeper_category_id']);

    return categories.some(
      (category) => category.id === selectedCategory && category.transaction_type === 'not_deductible'
    );
  }
);

export const displayedDescriptionSelector = createSelector(
  [selectedExpenseAllDataSelector, allCategoriesSelector],
  (selectedExpenseAllData, categories) => {
    const selectedCategory = _.get(selectedExpenseAllData, 'keeper_category_id');
    const selectedCategoryData = _.find(categories, { id: selectedCategory });

    const isDescriptionCategory =
      _.get(selectedCategoryData, 'transaction_type') === 'not_deductible' ||
      _.get(selectedCategoryData, 'transaction_type') === 'personal_deduction';

    return isDescriptionCategory && selectedCategoryData.description ? selectedCategoryData.description : null;
  }
);

export const nonBusinessDeductionIdsSelector = createSelector([allCategoriesSelector], (categories) => {
  return categories
    .filter((category) => category.transaction_type !== 'possible_business_deduction')
    .map((category) => category.id);
});

export const notDeductibleIdsSelector = createSelector([allCategoriesSelector], (categories) => {
  return categories.filter((category) => category.transaction_type === 'not_deductible').map((category) => category.id);
});

export const validRuleSelector = createSelector(currentRuleSelector, (currentRule) => {
  const category = _.get(currentRule, 'category_update');
  const status = _.get(currentRule, 'prediction');
  const name = _.get(currentRule, 'name');

  return category && status && name;
});
