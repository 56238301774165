import {
  SET_ACCOUNT_FILTER_SELECTIONS,
  SET_ALL_CATEGORIES,
  SET_AMOUNT_FILTER_SELECTIONS,
  SET_AUTO_RULE,
  SET_BULK_EDIT_EXPENSES,
  SET_BULK_EDIT_LOADING,
  SET_CATEGORY_AMOUNTS,
  SET_CATEGORY_FILTER_SELECTIONS,
  SET_EXPENSE_REVIEW_ACCOUNTS,
  SET_EXPENSE_REVIEW_CURRENT_RULE,
  SET_EXPENSE_REVIEW_MODAL_TYPE,
  SET_EXPENSE_REVIEW_RULES,
  SET_EXPENSES,
  SET_EXPENSES_ERROR,
  SET_EXPENSES_YEAR,
  SET_HAS_FETCHED_MAX_EXPENSES,
  SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING,
  SET_IS_EXPORTING,
  SET_IS_INITIAL_EXPENSES_LOAD,
  SET_IS_LOADING_EXPENSES,
  SET_MANUAL_EXPENSE_AMOUNT,
  SET_MANUAL_EXPENSE_DATA,
  SET_MERCHANT_NAME_FILTER_QUERY,
  SET_MERCHANT_NAME_SEARCH,
  SET_MERCHANT_SEARCH_LOADING,
  SET_MERCHANT_SEARCH_RESULTS,
  SET_RETRO_INTERVAL,
  SET_SAVINGS_AMOUNT,
  SET_SELECTED_EXPENSE,
  SET_SIDEBAR_COMPONENT,
  SET_STATUS_FILTER_SELECTIONS
} from '@app/src/actions/types';

export const setExpenses = (payload) => ({
  type: SET_EXPENSES,
  payload
});

export const setIsLoadingExpenses = (payload) => ({
  type: SET_IS_LOADING_EXPENSES,
  payload
});

export const setIsInitialExpensesLoad = (payload) => ({
  type: SET_IS_INITIAL_EXPENSES_LOAD,
  payload
});

// eslint-disable-next-line import/no-unused-modules
export const setExpensesError = (payload) => {
  return {
    type: SET_EXPENSES_ERROR,
    payload
  };
};

export const setExpensesYear = (payload) => {
  return {
    type: SET_EXPENSES_YEAR,
    payload
  };
};

export const setHasFetchedMaxExpenses = (payload) => {
  return {
    type: SET_HAS_FETCHED_MAX_EXPENSES,
    payload
  };
};

export const setSavingsAmount = (payload) => {
  return {
    type: SET_SAVINGS_AMOUNT,
    payload
  };
};

export const setCategoryAmounts = (payload) => {
  return {
    type: SET_CATEGORY_AMOUNTS,
    payload
  };
};

export const setAccounts = (payload) => {
  return {
    type: SET_EXPENSE_REVIEW_ACCOUNTS,
    payload
  };
};

export const setCategoryFilterSelections = (payload) => {
  return {
    type: SET_CATEGORY_FILTER_SELECTIONS,
    payload
  };
};

export const setAccountFilterSelections = (payload) => {
  return {
    type: SET_ACCOUNT_FILTER_SELECTIONS,
    payload
  };
};

export const setStatusFilterSelections = (payload) => {
  return {
    type: SET_STATUS_FILTER_SELECTIONS,
    payload
  };
};

export const setAmountFilterSelections = (payload) => {
  return {
    type: SET_AMOUNT_FILTER_SELECTIONS,
    payload
  };
};

export const setMerchantNameSearch = (payload) => {
  return {
    type: SET_MERCHANT_NAME_SEARCH,
    payload
  };
};

export const setMerchantNameSearchResults = (payload) => {
  return {
    type: SET_MERCHANT_SEARCH_RESULTS,
    payload
  };
};

export const setMerchantSearchLoading = (payload) => {
  return {
    type: SET_MERCHANT_SEARCH_LOADING,
    payload
  };
};

export const setMerchantNameFilterQuery = (payload) => {
  return {
    type: SET_MERCHANT_NAME_FILTER_QUERY,
    payload
  };
};

export const setSelectedExpense = (payload) => {
  return {
    type: SET_SELECTED_EXPENSE,
    payload
  };
};

export const setExpenseReviewRules = (payload) => {
  return {
    type: SET_EXPENSE_REVIEW_RULES,
    payload
  };
};

export const setExpenseReviewCurrentRule = (payload) => {
  return {
    type: SET_EXPENSE_REVIEW_CURRENT_RULE,
    payload
  };
};

export const setAutoRule = (payload) => {
  return {
    type: SET_AUTO_RULE,
    payload
  };
};

export const setSidebarComponent = (component, options) => {
  return {
    type: SET_SIDEBAR_COMPONENT,
    payload: {
      component,
      options
    }
  };
};

export const setAllCategories = (payload) => {
  return {
    type: SET_ALL_CATEGORIES,
    payload
  };
};

export const setIsModalSubmitting = (payload) => {
  return {
    type: SET_IS_EXPENSE_REVIEW_MODAL_SUBMITTING,
    payload
  };
};

export const setExpenseReviewModalType = (payload) => {
  return {
    type: SET_EXPENSE_REVIEW_MODAL_TYPE,
    payload
  };
};

export const setManualExpenseData = (payload) => {
  return {
    type: SET_MANUAL_EXPENSE_DATA,
    payload
  };
};

export const setManualExpenseAmount = (payload) => {
  return {
    type: SET_MANUAL_EXPENSE_AMOUNT,
    payload
  };
};

export const setRetroInterval = (payload) => {
  return {
    type: SET_RETRO_INTERVAL,
    payload
  };
};

export const setIsExporting = (payload) => {
  return {
    type: SET_IS_EXPORTING,
    payload
  };
};

export const setBulkEditExpenses = (payload) => {
  return {
    type: SET_BULK_EDIT_EXPENSES,
    payload
  };
};

export const setBulkEditLoading = (payload) => {
  return {
    type: SET_BULK_EDIT_LOADING,
    payload
  };
};
