import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import PlacesAutocomplete from 'react-places-autocomplete';
import { connect, useDispatch, useSelector } from 'react-redux';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { setChangedEndpointAttributes, updateCurrentAnswerBulk } from '@app/src/actions/taxFlowActions';
import { setErrors } from '@app/src/actions/taxValidationActions';
import { changedEndpointAttributesSelector } from '@app/src/selectors/taxFlowSelectors';
import { errorsSelector } from '@app/src/selectors/taxValidationSelectors';
import { dismissOcrError } from '@app/src/services/taxFlowService';
import { currentCollectionIdSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import {
  formatAutoFillAddress,
  getMappedAddressSlugs,
  getQuestionOcrAddressErrors
} from '@app/src/taxflow/shared/utils/sharedUtils';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormAddressItem.scss';

const TaxFlowFormAddressItem = ({
  question,
  answer,
  onChange,
  onFocus = _.noop,
  onBlur = _.noop,
  replaceStrings,
  updateCurrentAnswerBulk,
  errors,
  collectionId,
  dismissOcrError,
  setErrors
}) => {
  const addressValue = answer && !_.isNil(answer.value) ? answer.value : '';
  const searchOptions = {
    types: ['address'],
    componentRestrictions: { country: 'us' }
  };
  const changedEndpointAttributes = useSelector(changedEndpointAttributesSelector);
  const dispatch = useDispatch();

  const handleInputChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  const slugs = getMappedAddressSlugs({
    slug: question.slug
  });

  const handleSelect = async (address) => {
    const formattedAddress = await formatAutoFillAddress(address);

    updateCurrentAnswerBulk(
      Object.keys(slugs).reduce((acc, fieldType) => {
        const slug = slugs[fieldType];
        if (slug) {
          acc[slug] = {
            slug,
            value: _.get(formattedAddress, fieldType)
          };
        }
        return acc;
      }, {})
    );

    const ocrErrors = getQuestionOcrAddressErrors({
      errors,
      question,
      collectionId
    });
    Promise.all(ocrErrors.map((error) => dismissOcrError({ error })));

    // Clear all address-related errors while the street field is focused after selecting a suggestion.
    // Once it's blurred, any relevant errors will appear again.
    const isNotMatchingError = (error) =>
      !(
        error.coll_type === question.collectionType &&
        error.coll_id === collectionId &&
        Object.values(slugs).includes(error.slug)
      );
    setErrors(errors.filter(isNotMatchingError));

    const newChangedEndpointAttributes = {
      ...changedEndpointAttributes,
      ...Object.values(slugs).reduce((acc, slug) => ({ ...acc, [slug]: true }), {})
    };
    dispatch(setChangedEndpointAttributes(newChangedEndpointAttributes));
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
  };

  return (
    <div className='form-group'>
      <TaxValidationLabel
        question={question}
        answer={answer}
        title={replaceStrings(question.title)}
        htmlFor={question.slug}
      />
      <PlacesAutocomplete
        value={addressValue}
        onChange={handleInputChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        onBlur={(_, clearSuggestions) => clearSuggestions()}
        onError={onError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className='tax-flow-input-wrap'>
            <TaxValidationInput
              question={question}
              answer={answer}
              type={question.question_meta.type}
              name={question.slug}
              id={question.slug}
              placeholder={question.question_meta.default}
              required={question.required}
              onChange={(e) => handleInputChange(e.target.value)}
              onFocus={handleFocus}
              value={addressValue}
              {...getInputProps({
                placeholder: question.question_meta.default,
                onBlur: handleBlur
              })}
            />
            {suggestions && suggestions.length ? (
              <div className='taxflow-form-address-dropdown-container'>
                <div className='taxflow-form-address-suggestion-title'>Suggestions</div>
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      className={classNames('taxflow-form-address-suggestion-item', {
                        'taxflow-form-address-suggestion-item-active': suggestion.active
                      })}
                      {...getSuggestionItemProps(suggestion)}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </PlacesAutocomplete>
      <TaxValidationWarnings question={question} answer={answer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  errors: errorsSelector(state),
  collectionId: currentCollectionIdSelector(state)
});

const mapDispatchToProps = {
  updateCurrentAnswerBulk,
  dismissOcrError,
  setErrors
};

const ConnectedTaxFlowFormAddressItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowFormAddressItem);

export default ConnectedTaxFlowFormAddressItem;
