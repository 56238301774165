import { useEffect } from 'react';
import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import useEvent from 'react-use-event-hook';
import { baseApi } from '@app/src/api/baseApi';
import { useIsFocused } from '@app/src/hooks/useIsFocused';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

/** How often to check for stalled queries */
const CHECK_INTERVAL_MS = 10_000;

/** How long for a query to be pending prior to being considered stalled */
const STALL_THRESHOLD_MS = 20_000;

/**
 * Reload page upon stalled queries and report to sentry. We're seeing users end up with stale api data, which might be caused by perpetually pending queries
 * This will help
 *  - profile the issue
 *  - unblock users with stalled requests (hard refresh will force api calls to be made from scratch)
 */
export const useReloadOnStuckQuery = () => {
  const apiState = useSelector((state) => _.get(state, baseApi.reducerPath));
  const isFocused = useIsFocused();

  const reloadIfQueryStuck = useEvent(async ({ trigger }) => {
    const stalledQuery = _.find(
      apiState?.queries,
      (query) => query.status === 'pending' && query.startedTimeStamp < Date.now() - STALL_THRESHOLD_MS
    );
    if (!_.isNil(stalledQuery)) {
      const trackingData = { ..._.omit(stalledQuery, 'data'), trigger };
      defaultCaptureMessage('query stalled', trackingData, 'error');
      trackActivity('query stalled', trackingData);
      // Ensure warning is sent to sentry prior to reloading
      await Sentry.flush(5000);
      window.location.reload();
    }
  });

  // Run check whenever app comes back into focus
  useEffect(() => {
    if (isFocused) {
      reloadIfQueryStuck({ trigger: 'focus' });
    }
  }, [isFocused, reloadIfQueryStuck]);

  useInterval(async () => {
    // Don't reload when taxes tab / page in background
    if (!isFocused) {
      return;
    }
    reloadIfQueryStuck({ trigger: 'periodic check' });
  }, CHECK_INTERVAL_MS);
};
