import _ from 'lodash';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { baseApi } from '@app/src/api/baseApi';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import rootReducer from '@app/src/store/reducers';
import { streamMiddleware } from '@app/src/utils/messagingMiddleware';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { createSocketMiddleware } from '@app/src/utils/socket';

const initialState = {};

/**
 * Report rejected actions to sentry
 */
const reportRejectedActionsMiddleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const extra = (() => {
      if (action.meta?.arg?.type === 'query') {
        const arg = action.meta?.arg;
        const request = action.meta?.baseQueryMeta?.request;

        return {
          endpointName: arg?.endpointName,
          request: { url: request?.url, method: request?.method },
          payload: action.payload
        };
      }

      return action;
    })();

    // This error is handled -- can safely ignore
    if (_.get(extra, 'payload.data.msg') === 'Tax Profile not found') {
      return next(action);
    }

    const error = new Error(action.error.message);
    error.name = action.error.name;
    error.stack = action.error.stack;
    defaultCaptureException(error, {
      extra
    });
  }
  return next(action);
};

const middleware = [
  thunk,
  createSocketMiddleware(),
  streamMiddleware,
  baseApi.middleware,
  reportRejectedActionsMiddleware
];

// eslint-disable-next-line import/no-unused-modules
export const initializeStore = ({ preloadedState = initialState, enhancers = [] } = {}) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false
      }),
      ...middleware,
      ...enhancers
    ],
    devTools: { trace: REACT_APP_ENV === 'development' }
  });
};

const store = initializeStore({
  preloadedState: initialState
});

export default store;
