import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import classNames from 'classnames';
import { UserTag } from 'iconsax-react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTimeoutFn, useUnmount } from 'react-use';
import { toggleAssistantOpen } from '@app/src/actions/assistantActions';
import { useGetCurrentQuestionnaireQuestionQuery } from '@app/src/api/taxDataApi';
import ArrowLeft from '@app/src/assets/arrow-left.svg?react';
import MenuIcon from '@app/src/assets/menu.svg?react';
import { getIsTaxFilingSandwiched, isReactNative } from '@app/src/global/Helpers';
import { useIsMobile } from '@app/src/hooks/useIsMobile';
import { useIsTaxFilingNextEnabled } from '@app/src/hooks/useIsTaxFilingNextEnabled';
import { useIsTaxFilingSkipEnabled } from '@app/src/hooks/useIsTaxFilingSkipEnabled';
import {
  hideAssistantBadgeSelector,
  assistantMessagesSelector,
  assistantOpenSelector,
  assistantSessionIdSelector,
  assistantUnreadMessagesSelector
} from '@app/src/selectors/assistantSelectors';
import { messagingPlatformSelector } from '@app/src/selectors/authSelectors';
import { isNavigationPanelEnabledSelector } from '@app/src/selectors/navigationListSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { updateHideAssistantBadgeCampaign } from '@app/src/services/taxFlowService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setIsNavigationPanelOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { setIsNextOrPrevButtonLoading } from '@app/src/taxflow/shared/actions/sharedActions';
import {
  currentQuestionSelector,
  isNextOrPrevButtonLoadingSelector,
  resultLoadingSelector,
  showFooterSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import '@app/src/Components/TaxFlow/Question/TaxFlowQuestionFooter.scss';

const TaxFlowQuestionFooter = ({
  currentQuestion,
  onPrev = _.noop,
  onNext = _.noop,
  isPrev,
  isNext,
  isUpdating,
  showFooter,
  isNavigationPanelEnabled,
  sessionId,
  messages,
  assistantOpen,
  unreadMessages,
  messagingPlatform,
  contextLoading,
  NextButtonLabel = 'Next',
  fullWidth,
  isNextOrPrevButtonLoading,
  hideAssistantBadge,
  renderFooterLeft,
  setIsNavigationPanelOpen,
  toggleAssistantOpen,
  updateHideAssistantBadgeCampaign,
  setIsNextOrPrevButtonLoading
}) => {
  const location = useLocation();
  const year = useSelector(yearSelector);
  const isMobile = useIsMobile();

  const isTaxFilingNextEnabled = useIsTaxFilingNextEnabled();
  const isSkipEnabled = useIsTaxFilingSkipEnabled();

  const isDisabled = !isTaxFilingNextEnabled || isUpdating || contextLoading || isNextOrPrevButtonLoading;
  const isPrevDisabled =
    (isReactNative() && !location.key) || isUpdating || contextLoading || isNextOrPrevButtonLoading;
  // Native users should use the native ask functionality
  const shouldShowAssistantBadge = !isReactNative() && messagingPlatform;

  const { data: currentQuestionnaireQuestion } = useGetCurrentQuestionnaireQuestionQuery({ year });

  // Cancel next button loading if loading does not stop after 10 secs
  const [, cancelNextOrPrevButtonLoadingTimeoutCheck, queueNextOrPrevButtonLoadingTimeoutCheck] = useTimeoutFn(() => {
    setIsNextOrPrevButtonLoading(false);
    defaultCaptureMessage('next button load timeout', { slug: currentQuestion?.slug, currentQuestionnaireQuestion });
    trackActivity('next button load timeout', { slug: currentQuestion?.slug });
  }, 10000);

  let buttonLabel;
  if (isSkipEnabled) {
    buttonLabel = 'Skip';
  } else {
    buttonLabel = NextButtonLabel;
  }

  const isFilingSandwiched = getIsTaxFilingSandwiched();

  // Stop loading of "Next" / "Prev" button removal of footer
  useUnmount(() => {
    setIsNextOrPrevButtonLoading(false);
  });

  // Stop loading of "Next" / "Prev" button upon nav to the next question
  useEffect(() => {
    setIsNextOrPrevButtonLoading(false);
  }, [currentQuestion?.slug, setIsNextOrPrevButtonLoading]);

  useEffect(() => {
    if (!isNextOrPrevButtonLoading) {
      cancelNextOrPrevButtonLoadingTimeoutCheck();
    }
  }, [isNextOrPrevButtonLoading, cancelNextOrPrevButtonLoadingTimeoutCheck]);

  const handleNextClick = useCallback(() => {
    onNext();
    setIsNextOrPrevButtonLoading(true);
    queueNextOrPrevButtonLoadingTimeoutCheck();
  }, [onNext, setIsNextOrPrevButtonLoading, queueNextOrPrevButtonLoadingTimeoutCheck]);

  const handlePreviousClick = () => {
    onPrev();
    setIsNextOrPrevButtonLoading(true);
    queueNextOrPrevButtonLoadingTimeoutCheck();
  };

  /**
   * Progress to next question upon click of enter/return (web only)
   */
  useEffect(() => {
    if (isMobile) {
      return;
    }
    const handleEnter = (event) => {
      if (event.key === 'Enter') {
        const isAssistantInput = event.target.closest('.assistant-footer-input-container');
        if (isAssistantInput || isDisabled) {
          return;
        }
        handleNextClick();
      }
    };

    document.addEventListener('keydown', handleEnter);

    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [isMobile, handleNextClick, isDisabled]);

  if (isFilingSandwiched && contextLoading) {
    return null;
  }

  const onNavigationPanelButtonClicked = () => {
    setIsNavigationPanelOpen(true);
  };

  const handleAssistantToggle = async () => {
    await updateHideAssistantBadgeCampaign();
    const opening = !assistantOpen;
    const analyticsProps = {
      currentQuestion: _.get(currentQuestion, 'slug'),
      sessionId,
      messages: _.size(messages),
      origin: 'footer'
    };

    if (opening) {
      trackActivity('tax assistant: panel opened', analyticsProps);
    } else {
      trackActivity('tax assistant: panel closed', analyticsProps);
    }

    toggleAssistantOpen();
  };

  if (fullWidth) {
    return (
      <div
        className={classNames('taxflow-foot', {
          'taxflow-foot-loading': contextLoading,
          'taxflow-foot-app': isFilingSandwiched,
          'taxflow-foot-web': !isFilingSandwiched
        })}
      >
        <Button
          data-testid='next-button'
          fullWidth
          className='taxflow-foot-button'
          size='large'
          variant='contained'
          onClick={onNext}
        >
          <div className='taxflow-foot-button-content'>{buttonLabel}</div>
        </Button>
      </div>
    );
  }

  return (
    <div
      className={classNames('taxflow-foot', {
        'taxflow-foot-loading': contextLoading,
        'taxflow-foot-visible': showFooter,
        'taxflow-foot-app': isFilingSandwiched,
        'taxflow-foot-web': !isFilingSandwiched
      })}
    >
      <div className='taxflow-foot-left-spacer' />
      <div className='taxflow-foot-content'>
        {renderFooterLeft
          ? renderFooterLeft()
          : !isFilingSandwiched && (
              <div className='taxflow-foot-content-nav'>
                <Button
                  variant='outlined'
                  className={classNames('taxflow-foot-left-button', 'mobile-only', {
                    'taxflow-foot-left-button-disabled': !isNavigationPanelEnabled
                  })}
                  onClick={onNavigationPanelButtonClicked}
                >
                  <MenuIcon />
                </Button>
                {shouldShowAssistantBadge && (
                  <Badge
                    color='error'
                    className='taxflow-foot-left-button mobile-only'
                    badgeContent={(() => {
                      if (assistantOpen) {
                        return null;
                      }

                      if (!hideAssistantBadge) {
                        return ' ';
                      }

                      if (hideAssistantBadge && unreadMessages > 0) {
                        return ' ';
                      }

                      return null;
                    })()}
                  >
                    <Button disableFocusRipple variant='outlined' onClick={handleAssistantToggle}>
                      <UserTag />
                    </Button>
                  </Badge>
                )}
              </div>
            )}
        {showFooter && (
          <Stack spacing={1} direction='row' className='actions-tools'>
            {isPrev && (
              <Button
                data-testid='prev-button'
                variant={isPrevDisabled ? 'contained' : 'outlined'}
                disableElevation
                className='taxflow-foot-button-prev'
                disabled={isPrevDisabled}
                onClick={handlePreviousClick}
              >
                <ArrowLeft />
              </Button>
            )}
            {isNext && (
              <div>
                <LoadingButton
                  data-testid='next-button'
                  variant={isSkipEnabled && !isDisabled ? 'outlined' : 'contained'}
                  color='primary'
                  disableElevation
                  className='taxflow-foot-button'
                  disabled={isDisabled}
                  loading={isNextOrPrevButtonLoading}
                  onClick={handleNextClick}
                >
                  <div className='taxflow-foot-button-content'>{buttonLabel}</div>
                </LoadingButton>
              </div>
            )}
          </Stack>
        )}
      </div>
      <div className='taxflow-foot-right-spacer' />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isNavigationPanelEnabled: isNavigationPanelEnabledSelector(state),
  isNextOrPrevButtonLoading: isNextOrPrevButtonLoadingSelector(state),
  showFooter: showFooterSelector(state),
  sessionId: assistantSessionIdSelector(state),
  messages: assistantMessagesSelector(state),
  assistantOpen: assistantOpenSelector(state),
  currentQuestion: currentQuestionSelector(state),
  hideAssistantBadge: hideAssistantBadgeSelector(state),
  unreadMessages: assistantUnreadMessagesSelector(state),
  resultLoading: resultLoadingSelector(state),
  messagingPlatform: messagingPlatformSelector(state)
});

const mapDispatchToProps = {
  setIsNavigationPanelOpen,
  toggleAssistantOpen,
  updateHideAssistantBadgeCampaign,
  setIsNextOrPrevButtonLoading
};
const ConnectedTaxFlowQuestionFooter = connect(mapStateToProps, mapDispatchToProps)(TaxFlowQuestionFooter);

export default ConnectedTaxFlowQuestionFooter;
