import * as Sentry from '@sentry/react';

export default function defaultCaptureMessage(message, data, level = 'error') {
  if (level === 'error') {
    // eslint-disable-next-line
    console.error('ERROR:', message);
  } else {
    // eslint-disable-next-line
    console.log(message);
  }
  Sentry.captureMessage(message, { level, extra: data });
}
