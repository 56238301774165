import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import TaxValidationSelect from '@app/src/Components/TaxValidation/TaxValidationSelect';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';

const TaxFlowFormDropdownItem = ({ question, answer, onChange, onFocus, onBlur }) => {
  useEffect(() => {
    if (_.isNil(_.get(answer, 'value'))) {
      if (question.question_meta) {
        const item = question.question_meta.find((x) => x.default === 1);
        if (item) {
          onChange({ slug: question.slug, value: item.value });
        }
      }
    }
  }, [question, answer, onChange]);

  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    if (onFocus) {
      onFocus({ endpoint_attr: question.endpoint_attr });
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur({ question, answer });
    }
  };

  const question_meta = question.question_meta;

  return (
    <div className='form-group'>
      <TaxValidationSelect
        question={question}
        answer={answer}
        className='browser-default'
        name={question.slug}
        id={question.slug}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={answer && !_.isNil(answer.value) ? answer.value : ''}
      >
        {question_meta.map((item) => (
          <option value={item.value} key={item.value}>
            {item.text}
          </option>
        ))}
      </TaxValidationSelect>
      <TaxValidationWarnings question={question} answer={answer} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  taxFlow: state.taxFlow
});

const ConnectedTaxFlowFormDropdownItem = connect(mapStateToProps)(TaxFlowFormDropdownItem);

export default ConnectedTaxFlowFormDropdownItem;
