import axios from 'axios';
import { setExpenseReviewModalType, setIsExporting } from '@app/src/actions/expenseReviewActions';
import { setOrigin, setOverrides } from '@app/src/actions/pricingActions';
import { EXPENSE_REVIEW_MODAL_TYPES } from '@app/src/constants/dashboardConstants';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { serverUrl } from '@app/src/global/Environment';
import { userSettingsSelector } from '@app/src/selectors/authSelectors';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { availablePlansSelector, subscriptionStatusSelector } from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const handleExportWriteOffs =
  (newSubscriptionStarted = false) =>
  async (dispatch, getState) => {
    const isActiveUser = subscriptionStatusSelector(getState()) === 'active';

    if (!isActiveUser && !newSubscriptionStarted) {
      dispatch(
        setOverrides({
          subscriptionBenefits: {
            title: 'Exporting deductions requires a subscription',
            CTA: {
              primary: { text: 'Continue', plans: availablePlansSelector(getState()) },
              tertiary: null
            },
            body: {
              image: 'keeper-ui'
            },
            refundCopy: true,
            ratings: true
          },
          pricingMethod: {
            title: 'Choose payment method',
            subtitle: "You'll be charged {price} / {period}. Cancel anytime.",
            CTA: {
              primary: { text: 'Pay {price}' }
            }
          }
        })
      );

      dispatch(setOrigin({ origin: PRICING_ORIGINS.EXPORT }));
      dispatch(setExpenseReviewModalType(EXPENSE_REVIEW_MODAL_TYPES.EXPORT_PAYWALL));
    } else {
      dispatch(setIsExporting(true));

      const year = expensesYearSelector(getState());
      const start = `${year}-01-01`;
      const end = `${year}-12-31`;

      const success = await dispatch(exportWriteOffsRemote({ start, end }));

      if (success) {
        trackActivity('export write offs', {
          origin: 'web dashboard',
          year,
          newSubscriptionStarted: !!newSubscriptionStarted
        });

        const settings = userSettingsSelector(getState());
        const email = settings.email;

        notify(`Export spreadsheet sent! Check ${email}.`);
      }

      dispatch(setIsExporting(false));
      dispatch(setExpenseReviewModalType(null));
    }
  };

const exportWriteOffsRemote =
  ({ start, end }) =>
  async (dispatch) => {
    try {
      await axios.get(`${baseUrl}api/expense/export-writeoffs`, {
        params: {
          start,
          end
        }
      });

      return true;
    } catch (e) {
      notify('Something went wrong while exporting your write offs. Please try again.');
      defaultCaptureException(e);
    } finally {
      dispatch(setIsExporting(false));
    }
  };
