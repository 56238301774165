import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect, useSelector } from 'react-redux';
import { setIsConfirmationModalOpen } from '@app/src/actions/taxFlowActions';
import { useGetUIStageQuery, useUpdateUIStageMutation, useGetIsReturnBlockedQuery } from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { trackQuestionAnswer, trackUiStageChange } from '@app/src/taxflow/main/services/mainService';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { SLUG__SUBMIT_CONFIRMATION_MODAL } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { updateReadyToFile } from '@app/src/taxflow/sections/submit/services/submitService';
import {
  TAXFLOW_BASE_URL,
  UI_STAGE__BLOCKED_FINAL_REVIEW,
  UI_STAGE__FINAL_REVIEW
} from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';

const TaxFlowSubmitConfirmationModel = ({
  history,
  modalQuestion,
  trackUiStageChange,
  updateReadyToFile,
  setIsConfirmationModalOpen,
  trackQuestionAnswer
}) => {
  const year = useSelector(yearSelector);
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const { data: isReturnBlocked, isLoading: isIsReturnBlockedLoading } = useGetIsReturnBlockedQuery({ year });

  const [updateUIStage] = useUpdateUIStageMutation();

  const onClose = () => {
    setIsConfirmationModalOpen(false);
  };

  const onClick = async () => {
    try {
      setIsConfirmationModalOpen(false);
      trackQuestionAnswer({ modalQuestion });

      const nextUiStage = isReturnBlocked ? UI_STAGE__BLOCKED_FINAL_REVIEW : UI_STAGE__FINAL_REVIEW;

      await updateUIStage({ uiStage: nextUiStage, year });
      trackUiStageChange({
        prevUiStage: uiStage,
        newUiStage: nextUiStage,
        origin: 'tax filing ui (user confirmed amounts)'
      });
      history.push(`/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`);
      await updateReadyToFile();
    } catch (e) {
      defaultCaptureException(e);
    }
  };

  if (uiStageLoading || isIsReturnBlockedLoading) {
    return null;
  }

  return (
    <Dialog maxWidth='xs' open={true} onClose={onClose}>
      <DialogTitle className='submit-confirmation-modal-title'>{modalQuestion.title}</DialogTitle>
      <DialogContent className='submit-confirmation-modal-body'>
        <DialogContentText>{modalQuestion.summary}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant='contained' onClick={onClick}>
          {modalQuestion.nextButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state, props) => ({
  modalQuestion: props.currentQuestion.sub_question.find((x) => x.slug === SLUG__SUBMIT_CONFIRMATION_MODAL)
});

const mapDispatchToProps = {
  updateReadyToFile,
  setIsConfirmationModalOpen,
  trackQuestionAnswer,
  trackUiStageChange
};

const ConnectedTaxFlowSubmitConfirmationModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowSubmitConfirmationModel);

export default ConnectedTaxFlowSubmitConfirmationModel;
