import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { useGetRetroStatusQuery } from '@app/src/api/profileApi';
import { bankLinkPendingSelector } from '@app/src/selectors/bankSelectors';

export const useTransactionsSyncInProgress = ({ year }) => {
  const [syncInProgress, setSyncInProgress] = useState(false);
  const bankLinkPending = useSelector(bankLinkPendingSelector);
  const { data: retroStatus, isLoading: isRetroStatusLoading } = useGetRetroStatusQuery({ year });

  const retroInProgress = retroStatus === 'waiting';

  // Ensure we don't set syncInProgress until loading is done
  useEffect(() => {
    if (!isRetroStatusLoading && (retroInProgress || bankLinkPending)) {
      setSyncInProgress(true);
    }
  }, [retroInProgress, bankLinkPending, isRetroStatusLoading]);

  // Debounce clearing syncInProgress only after loading is fully complete
  useDebounce(
    () => {
      if (!isRetroStatusLoading && !retroInProgress && !bankLinkPending) {
        setSyncInProgress(false);
      }
    },
    3000,
    [retroInProgress, bankLinkPending, isRetroStatusLoading]
  );

  return { syncInProgress, isLoading: isRetroStatusLoading };
};
