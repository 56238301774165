import React from 'react';
import { Filter, ArrowDown2 } from 'iconsax-react';
import { colorSecondary } from '@app/src/theme';

export const ExpenseReviewTableChevronFilterIcon = ({ hasOptions, filterSelected, isOpen }) => {
  const shouldComponentRender = hasOptions && filterSelected !== undefined && isOpen !== undefined;
  if (!shouldComponentRender) return null;

  return (
    <>
      {filterSelected ? (
        <Filter variant='Bold' size={16} style={{ color: colorSecondary, cursor: 'pointer' }} />
      ) : (
        <ArrowDown2
          size={16}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            cursor: 'pointer'
          }}
        />
      )}
    </>
  );
};
