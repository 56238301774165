import { useEffect } from 'react';
import isOnline from 'is-online';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTimeoutFn } from 'react-use';
import { useGetUIStageQuery } from '@app/src/api/taxDataApi';
import { sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsFocused } from '@app/src/hooks/useIsFocused';
import { trackActivity } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
/**
 * Reload page if isLoading for time greater than thresholdMs
 */
export const useReloadPageOnLongLoad = ({ isLoading, loadingReasons, thresholdMs }) => {
  const location = useLocation();
  const year = useSelector(yearSelector);
  const { data: uiStage } = useGetUIStageQuery({ year });
  const isFocused = useIsFocused();
  const reloadPage = async () => {
    trackActivity('page load timeout', {
      path: location.pathname,
      uiStage,
      loadingReasons,
      isOnline: await isOnline()
    });
    sentMsgToReactNative('get_net_info');
    window.location.reload();
  };
  const [, cancelReload, queueReload] = useTimeoutFn(reloadPage, thresholdMs);
  useEffect(() => {
    // Skip reload if in-app and webview or web is not focused (ie the user is not on the taxes tab, the user is on a diff web tab)
    if (isLoading && isFocused) {
      queueReload();
    } else {
      cancelReload();
    }
  }, [isLoading, isFocused, queueReload, cancelReload]);
};
