import React, { useRef, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { ArrowDown2, SearchNormal1 } from 'iconsax-react';
import { connect } from 'react-redux';
import { setMerchantNameSearchResults } from '@app/src/actions/expenseReviewActions';
import {
  merchantSearchLoadingSelector,
  merchantSearchResultsSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { getMerchantsWithCounts } from '@app/src/services/expenseReviewService';
import { colorNeutralGraphite, colorSecondary } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewMerchantSearchBar.scss';

const ExpenseReviewMerchantSearchBar = ({
  getMerchantsWithCounts,
  isLoading,
  merchantsWithCounts,
  onSelect,
  origin
}) => {
  const searchInputRef = useRef(null);
  // Local State (merchantSearchQuery) → Handles real-time filtering for the merchant dropdown.
  // Global State (merchantNameSearchSelector) → Updates only when a merchant is selected, triggering the expense table filter.
  const [merchantSearchQuery, setMerchantSearchQuery] = useState('');
  // Controls the selected merchant option in the Autocomplete component
  // Required to maintain MUI Autocomplete's controlled component pattern and
  // prevent value/option mismatches when typing after a selection
  const [value, setValue] = useState(null);

  const filteredMerchants = useMemo(() => {
    if (!merchantSearchQuery) return merchantsWithCounts;
    return merchantsWithCounts.filter((merchant) =>
      merchant.clean_name?.toLowerCase().includes(merchantSearchQuery.toLowerCase())
    );
  }, [merchantsWithCounts, merchantSearchQuery]);

  useEffect(() => {
    getMerchantsWithCounts();
  }, [getMerchantsWithCounts]);

  const handleFocus = () => {
    trackActivity('search: focus search bar', { origin });
  };

  return (
    <div className='expense-review-search-bar'>
      <Autocomplete
        value={value}
        options={filteredMerchants}
        loading={isLoading}
        getOptionLabel={({ clean_name }) => clean_name}
        noOptionsText='No merchants found'
        onInputChange={(_, newValue, reason) => {
          // Only update search query if we have a value or it's an explicit input
          // This prevents clearing the input when typing over a selection
          if (newValue || reason === 'input') {
            setMerchantSearchQuery(newValue);
          }

          if (reason === 'input') {
            setValue(null);
          }
        }}
        onChange={(_, newValue) => {
          setValue(newValue);
          setMerchantSearchQuery(newValue?.clean_name || '');
          onSelect(newValue?.clean_name || '');
        }}
        inputValue={merchantSearchQuery}
        fullWidth
        isOptionEqualToValue={(option, value) => option.clean_name === value.clean_name}
        popupIcon={<ArrowDown2 />}
        PaperComponent={({ children }) => (
          <Paper
            elevation={0}
            style={{
              border: `2px solid ${colorSecondary}`,
              borderRadius: '0 0 8px 8px',
              borderTop: 'none',
              marginTop: '-8px'
            }}
          >
            {children}
          </Paper>
        )}
        renderOption={(props, option) => (
          <ListItem {...props} key={option.clean_name} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{option.clean_name}</span>
            <span style={{ color: colorNeutralGraphite }}>
              {option.count} {option.count === 1 ? 'transaction' : 'transactions'}
            </span>
          </ListItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            onFocus={handleFocus}
            inputRef={searchInputRef}
            value={merchantSearchQuery}
            placeholder='e.g. Netflix'
            color='secondary'
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchNormal1 size={24} />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  merchantsWithCounts: merchantSearchResultsSelector(state),
  isLoading: merchantSearchLoadingSelector(state)
});

const mapDispatchToProps = {
  getMerchantsWithCounts,
  setMerchantNameSearchResults
};

const ConnectedExpenseReviewMerchantSearchBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpenseReviewMerchantSearchBar);

export default ConnectedExpenseReviewMerchantSearchBar;
