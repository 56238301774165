import React from 'react';
import _ from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDismissBulkUploadAlertMutation, useGetBulkUploadAlertQuery } from '@app/src/api/taxDataApi';
import CloseIcon from '@app/src/assets/close.svg?react';
import { PRICING_ORIGINS } from '@app/src/constants/pricingConstants';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { useIsPremiumSubscriber } from '@app/src/hooks/pricingHooks';
import { useIsBulkUploadInProgress } from '@app/src/hooks/useIsBulkUploadInProgress';
import { uploadErrorSelector } from '@app/src/selectors/taxFlowSelectors';
import { FORM_UPLOAD_ERRORS } from '@app/src/taxflow/collection/constants/formUploadConstants';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { setUploadError } from '@app/src/taxflow/shared/actions/sharedActions';
import { colorNeutralGainsboro } from '@app/src/theme';
import '@app/src/taxflow/collection/components/TaxFlowBulkUploadModal.scss';

/**
 * Modal surfaced upon user-facing alerts pertaining to bulk upload
 * Handles both FE upload errors (failure in uploading to S3, incorrect file formats) & classification / parse errors
 */
const TaxFlowBulkUploadModal = () => {
  const dispatch = useDispatch();
  const year = useSelector(yearSelector);
  // Potential non classification / parse related error (ex issue with S3 upload)
  const uploadErrorText = useSelector(uploadErrorSelector);

  const { data: isPremiumSubscriber, isLoading: isPremiumSubscriberLoading } = useIsPremiumSubscriber();
  const isBulkUploadInProgress = useIsBulkUploadInProgress();
  const { data: bulkUploadAlert, isLoading: bulkUploadAlertLoading } = useGetBulkUploadAlertQuery(
    { year },
    { skip: isBulkUploadInProgress }
  );
  const [dismissBulkUploadAlert] = useDismissBulkUploadAlertMutation();

  const onClose = () => {
    if (_.isEmpty(uploadErrorText)) {
      dismissBulkUploadAlert({ error: bulkUploadAlert.error, year });
    } else {
      dispatch(setUploadError(null));
    }
  };

  if (bulkUploadAlertLoading || isPremiumSubscriberLoading) {
    return null;
  }

  // Don't show modals when parse still in progress (more modals might be produced once the ongoing parse completes, and we want to aggregate related modals)
  if (isBulkUploadInProgress) {
    return null;
  }

  const errorHeader = getModalHeader({ bulkUploadAlert, uploadErrorText });

  if (_.isNil(errorHeader)) {
    return null;
  }

  return (
    <Dialog className='form-upload-modal' maxWidth={'sm'} onClose={onClose} open>
      <DialogTitle
        sx={{
          marginRight: '16px',
          marginLeft: '16px',
          paddingLeft: 0,
          paddingRight: 0,
          borderColor: colorNeutralGainsboro
        }}
        className='form-upload-modal-header'
      >
        <div>{errorHeader}</div>
        <IconButton aria-label='close' onClick={onClose} color={'yellow'}>
          <CloseIcon color={'red'} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ paddingBottom: 0 }}>
        <div className={'form-upload-modal-body'}>
          <TaxFlowBulkUploadModalBody
            bulkUploadAlert={bulkUploadAlert}
            uploadErrorText={uploadErrorText}
            isPremiumSubscriber={isPremiumSubscriber}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

const getModalHeader = ({ bulkUploadAlert, uploadErrorText }) => {
  if (!_.isEmpty(uploadErrorText)) {
    return uploadErrorText;
  }

  if (_.isNil(bulkUploadAlert)) {
    return null;
  }

  const { error, fileNames } = bulkUploadAlert;

  if (error === FORM_UPLOAD_ERRORS.INCORRECT_YEAR) {
    return _.size(fileNames) > 1
      ? `It looks like these forms are from the wrong tax year`
      : `It looks like form ${_.first(fileNames)} is from the wrong tax year`;
  }

  return _.size(fileNames) > 1
    ? `We’re having difficulties reading some of your forms`
    : `We’re having difficulties reading ${_.first(fileNames)}`;
};

const TaxFlowBulkUploadModalBody = ({ bulkUploadAlert, uploadErrorText, isPremiumSubscriber }) => {
  const year = useSelector(yearSelector);

  if (!_.isEmpty(uploadErrorText)) {
    return <p>{uploadErrorText}</p>;
  }

  if (_.isNil(bulkUploadAlert)) {
    return null;
  }

  const { error, fileNames } = bulkUploadAlert;

  if (error === FORM_UPLOAD_ERRORS.INCORRECT_YEAR) {
    return _.size(fileNames) > 1 ? (
      <>
        <ReactMarkdown>{`The following files that were uploaded were not for the year **${year}**.`}</ReactMarkdown>
        <FileNamesList fileNames={fileNames} />
        <p>Please delete these forms and try again or enter the information manually.</p>
        <ReactMarkdown>
          Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
        </ReactMarkdown>
      </>
    ) : (
      <>
        <ReactMarkdown>{`The file that was uploaded was not for the year **${year}**.`}</ReactMarkdown>
        <p>Please delete this form and try again or enter the information manually.</p>
        <ReactMarkdown>
          Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
        </ReactMarkdown>
      </>
    );
  }

  if (error === FORM_UPLOAD_ERRORS.PREMIUM_REQUIRED && !isPremiumSubscriber) {
    return _.size(fileNames) > 1 ? (
      <>
        <p>It looks like the following might not be valid tax forms:</p>
        <FileNamesList fileNames={fileNames} />
        <p>If you think this is a mistake, please upload this form again.</p>
        <div>
          Premium users are allowed to upload any documents (including non-tax forms) for their dedicated tax
          professional. If you have specialized documents you’d like to share, you’ll need to <UpgradeToPremiumText />.
        </div>
        <ReactMarkdown>
          Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
        </ReactMarkdown>
      </>
    ) : (
      <>
        <p>{`It looks like ${_.first(fileNames)} might not be a valid tax form. If you think this is a mistake, please upload this form again`}</p>
        <div>
          Premium users are allowed to upload any documents (including non-tax forms) for their dedicated tax
          professional. If you have specialized documents you’d like to share, you’ll need to <UpgradeToPremiumText />.
        </div>
        <ReactMarkdown>
          Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
        </ReactMarkdown>
      </>
    );
  }

  return _.size(fileNames) > 1 ? (
    <>
      <p>We ran into some issues when reading the following forms:</p>
      <FileNamesList fileNames={fileNames} />
      <p>
        {error === FORM_UPLOAD_ERRORS.DONUT_400
          ? 'We are unable to read password protected files. If your file is password protected, you will need to upload an unprotected version. Please delete these forms and try again or enter the information manually.'
          : 'Please delete these forms and try again or enter the information manually.'}
      </p>
      <ReactMarkdown>
        Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
      </ReactMarkdown>
    </>
  ) : (
    <>
      <p>We ran into an issue when reading your uploaded form</p>
      <p>
        {error === FORM_UPLOAD_ERRORS.DONUT_400
          ? 'We are unable to read password protected files. If your file is password protected, you will need to upload an unprotected version. Please delete this form and try again or enter the information manually.'
          : 'Please delete this form and try again or enter the information manually.'}
      </p>
      <ReactMarkdown>
        Please reach out to [support@keepertax.com](mailto:support@keepertax.com) if you continue to have issues.
      </ReactMarkdown>
    </>
  );
};

const FileNamesList = ({ fileNames }) => {
  return (
    <ul>
      {_.map(fileNames, (fileName, idx) => (
        <li key={`${fileName}_${idx}`}>{fileName}</li>
      ))}
    </ul>
  );
};

const UpgradeToPremiumText = () => {
  const history = useHistory();
  const navigateUpgradeToPremium = () => {
    if (isReactNative()) {
      sentMsgToReactNative('open_subscribe_modal', { origin: PRICING_ORIGINS.TAX_FILING_PREMIUM_PAYWALL });
    } else {
      history.push('/settings/subscription');
    }
  };
  return (
    <div className='form-upload-modal-body-link' onClick={navigateUpgradeToPremium}>
      upgrade to premium
    </div>
  );
};
export default TaxFlowBulkUploadModal;
