import {
  GET_BANK_LIST,
  LOAD_BANK_DETAILS,
  CLEAR_BANK_LIST,
  SET_BANK_LIST_UPDATE_STATUS,
  SET_CHARGEABLE_ACCOUNT,
  UPDATE_BANK_RES_MSG,
  SET_LINK_TOKEN,
  SET_BANK_INFO,
  SET_BANK_INFO_LOADING,
  SET_BANK_INFO_ERROR_MSG,
  SET_BANK_LINK_FAILURE,
  SET_BANK_LINK_SKIPPED,
  SET_BANK_LINK_CHASE,
  FORCE_PLAID_REFRESH,
  SET_BANK_LIST_PAGE_LOADING
} from '@app/src/actions/types';

const initialState = {
  bankList: [],
  isBankListChanged: false,
  loading: false,
  successMsg: '',
  errorMsg: '',
  chargeableAccount: {},
  linkToken: null,
  bankInfo: {},
  bankInfoLoading: false,
  bankInfoErrorMsg: null,
  bankLinkFailure: false,
  bankLinkSkipped: false,
  bankLinkChase: false,
  forceRefresh: false,
  pageLoading: true
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BANK_LIST:
      return {
        ...state,
        bankList: action.payload,
        loading: false
      };
    case LOAD_BANK_DETAILS:
      return {
        ...state,
        loading: action.payload
      };
    case CLEAR_BANK_LIST:
      return {
        ...state,
        bankList: []
      };
    case SET_CHARGEABLE_ACCOUNT:
      return {
        ...state,
        chargeableAccount: action.payload
      };
    case SET_BANK_LIST_UPDATE_STATUS:
      return {
        ...state,
        isBankListChanged: action.payload
      };
    case UPDATE_BANK_RES_MSG:
      return {
        ...state,
        successMsg: action.payload.success,
        errorMsg: action.payload.error
      };
    case SET_LINK_TOKEN:
      return {
        ...state,
        linkToken: action.payload
      };
    case SET_BANK_INFO:
      return {
        ...state,
        bankInfo: action.payload
      };
    case SET_BANK_INFO_LOADING:
      return {
        ...state,
        bankInfoLoading: action.payload
      };
    case SET_BANK_INFO_ERROR_MSG:
      return {
        ...state,
        bankInfoErrorMsg: action.payload
      };
    case SET_BANK_LINK_FAILURE:
      return {
        ...state,
        bankLinkFailure: action.payload
      };
    case SET_BANK_LINK_SKIPPED:
      return {
        ...state,
        bankLinkSkipped: action.payload
      };
    case SET_BANK_LINK_CHASE:
      return {
        ...state,
        bankLinkChase: action.payload
      };
    case FORCE_PLAID_REFRESH:
      return {
        ...state,
        forceRefresh: Date.now()
      };
    case SET_BANK_LIST_PAGE_LOADING:
      return {
        ...state,
        pageLoading: action.payload
      };
    default:
      return state;
  }
}
