import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import TaxFlowMultiOptionElement from '@app/src/Components/TaxFlow/Question/TaxFlowMultiOptionElement';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { dependentFirstNameOptionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { SLUG__CREDIT_CHILD_CARE_WHO } from '@app/src/taxflow/sections/credit/constants/creditConstants';

const TaxFlowFormMultiOptionItem = (props) => {
  const { question, answer, replaceStrings, onChange, onFocus = _.noop, onBlur = _.noop } = props;

  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  const dependentOptions = useSelector((state) => dependentFirstNameOptionsSelector(state, true));

  if (!question) return null;

  return (
    <div className='form-group'>
      <TaxValidationLabel
        question={question}
        answer={answer}
        className='margin-bottom-none'
        title={replaceStrings(question.title)}
      />
      <TaxFlowMultiOptionElement
        {...props}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {dependentOptions.length === 0 && question.slug === SLUG__CREDIT_CHILD_CARE_WHO ? (
        <TaxValidationWarnings question={question} answer={answer} addDependent />
      ) : (
        <TaxValidationWarnings question={question} answer={answer} />
      )}
    </div>
  );
};

export default TaxFlowFormMultiOptionItem;
