import React from 'react';
import { useSelector } from 'react-redux';
import { BankLinkOptions } from '@app/src/Components/BankLinkOptions/BankLinkOptions';
import { viewableAccountsSelector } from '@app/src/selectors/bankSelectors';
import { hasFiltersSelectedSelector } from '@app/src/selectors/expenseReviewSelectors';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesNoResultsText.scss';

const ExpenseReviewExpensesNoResultsText = () => {
  const bankList = useSelector(viewableAccountsSelector);
  const hasLinkedAccount = Array.isArray(bankList) && bankList.length > 0;
  const isFilteredView = useSelector(hasFiltersSelectedSelector);

  return (
    <div className='expense-review-expenses-no-results-container'>
      <BankLinkOptions
        origin='expenseReviewNoResults'
        title={hasLinkedAccount && isFilteredView ? 'No matching transactions found' : "Let's scan for deductions"}
        subTitle={
          hasLinkedAccount && isFilteredView
            ? "Link bank account(s) you use for work and we'll automatically scan your past purchases for eligible deductions."
            : 'Give Keeper read-only access to your transaction data to find eligible deductions.'
        }
        syncBehavior='showLoader'
      />
    </div>
  );
};

export default ExpenseReviewExpensesNoResultsText;
