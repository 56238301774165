import React from 'react';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pageShouldTransitionSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const PageTransition = ({ children }) => {
  const { action, location } = useHistory();
  const { pathname } = location;

  const pageShouldTransition = useSelector((state) => pageShouldTransitionSelector(state, location));

  return pageShouldTransition ? (
    <Slide key={pathname} in timeout={300} direction={action !== 'POP' ? 'left' : 'right'}>
      {children}
    </Slide>
  ) : (
    <div>{children}</div>
  );
};

export default PageTransition;
