import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PricingMethodContent from '@app/src/Components/Subscription/PricingMethodContent/PricingMethodContent';
import SubscriptionContent from '@app/src/Components/Subscription/SubscriptionContent/SubscriptionContent';
import SubscriptionType from '@app/src/Components/Subscription/SubscriptionType/SubscriptionType';
import { SUBSCRIPTION_MODAL_TYPES } from '@app/src/constants/constants';
import { originSelector } from '@app/src/selectors/pricingSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { setSubscriptionOverrides } from '@app/src/services/pricingService';

const SubscriptionFlow = ({ initialScreen }) => {
  const dispatch = useDispatch();

  const [screen, setScreen] = useState(initialScreen || SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_BENEFITS);
  const origin = useSelector(originSelector);

  useEffect(() => {
    dispatch(setSubscriptionOverrides(origin));

    return () => {
      dispatch(setSubscriptionOverrides({}));

      trackActivity('subscription modal closed', { origin });
    };
  }, [dispatch, origin]);

  const showBackButton = (screen) => screen !== initialScreen;

  return (
    <>
      {screen === SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_BENEFITS && <SubscriptionContent onNext={setScreen} />}
      {screen === SUBSCRIPTION_MODAL_TYPES.SUBSCRIPTION_TYPE && (
        <SubscriptionType onNext={setScreen} showBackButton={showBackButton} />
      )}
      {screen === SUBSCRIPTION_MODAL_TYPES.PRICING_METHOD && (
        <PricingMethodContent onNext={setScreen} showBackButton={showBackButton} />
      )}
    </>
  );
};

export default SubscriptionFlow;
