import React, { useEffect, useState } from 'react';
import url from 'url';
import _ from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import qs from 'qs';
import { connect, useDispatch } from 'react-redux';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';
import LoginHeader from '@app/src/Components/Login/LoginWithOTP/LoginHeader';
import LoginLink from '@app/src/Components/Login/LoginWithOTP/LoginLink';
import LoginWindow from '@app/src/Components/Login/LoginWithOTP/LoginWindow';
import { baseApi } from '@app/src/api/baseApi';
import { KFA_PATHNAMES, RETURN_URL_KEY_NAME } from '@app/src/constants/constants';
import { REACT_APP_ENV } from '@app/src/global/Environment';
import history from '@app/src/keeperHistory';
import { trackActivity } from '@app/src/services/analyticsService';
import { requestLoginEmail, requestLoginOtp } from '@app/src/services/authService';
import '@app/src/Components/Login/LoginWithOTP/LoginId.scss';

const LoginId = ({ className, type, location, loading, requestLoginOtp, requestLoginEmail, kfaRoute }) => {
  const dispatch = useDispatch();
  const [id, setId] = useState('');

  const returnUrl = _.get(qs.parse(location.search, { ignoreQueryPrefix: true }), RETURN_URL_KEY_NAME);

  useEffect(() => {
    trackActivity(type === 'phone' ? 'navigation: sign in' : 'navigation: sign in email');
  }, [type]);

  // Reset api cache upon log-out / pre-log-in
  useEffect(() => dispatch(baseApi.util.resetApiState()), [dispatch]);

  const onIdChange = (e) => {
    setId(e.target.value);
  };

  const onRequestOtpSuccess = (id) => {
    history.push(
      url.format({
        pathname: '/login-verify',
        query: {
          type,
          id,
          origin: 'login',
          [RETURN_URL_KEY_NAME]: returnUrl,
          ...(kfaRoute ? { kfaRoute: !!kfaRoute } : {})
        }
      })
    );
  };

  const requestOtp = () => {
    if (loading) {
      return;
    }

    if (type === 'phone') {
      const parsedId = id.replace(/\D/g, '');
      requestLoginOtp({ phone: parsedId }, () => onRequestOtpSuccess(parsedId));
    } else {
      requestLoginEmail({ email: id }, () => onRequestOtpSuccess(id));
    }
  };

  const onIdKeyPress = (e) => {
    if (e.key === 'Enter') {
      onContinue();
    }
  };

  const onContinue = () => {
    requestOtp();
  };

  const onAltSignInClick = (type) => {
    if (kfaRoute) {
      history.push(url.format({ pathname: type === 'phone' ? KFA_PATHNAMES.LOGIN_EMAIL : KFA_PATHNAMES.LOGIN_PHONE }));
    } else {
      history.push(url.format({ pathname: type === 'phone' ? '/login-email' : '/login' }));
    }
  };

  const isStagingProdDb = REACT_APP_ENV === 'staging-prod-db';

  return (
    <LoginWindow className={classNames('login-id', className)}>
      <div className='login-id-top'>
        <LoginHeader
          className='login-id-header'
          title='Sign in to Keeper'
          subtitle={REACT_APP_ENV === 'staging-prod-db' ? 'Login is disabled for staging-prod-db' : null}
          kfaRoute={kfaRoute}
        />
        <div className='login-id-form'>
          {type === 'phone' ? (
            <div className='login-id-form-group form-group'>
              <label className='login-id-label' htmlFor='id'>
                Phone number
              </label>
              <PhoneField
                className='login-id-input form-control'
                id='id'
                mask='+1 (###) ###-####'
                placeholder='(123) 456-7890'
                value={id}
                onChange={onIdChange}
                onKeyPress={onIdKeyPress}
                type='tel'
                inputMode='tel'
                autoComplete='tel'
              />
            </div>
          ) : (
            <div className='login-id-form-group form-group'>
              <label className='login-id-label' htmlFor='id'>
                Email address
              </label>
              <TextField
                fullWidth
                className='login-id-input form-control'
                id='id'
                type='email'
                name='email'
                placeholder='jordan@example.com'
                value={id}
                onChange={onIdChange}
                onKeyPress={onIdKeyPress}
                inputProps={{
                  'data-testid': 'email'
                }}
              />
            </div>
          )}
        </div>
      </div>

      <div className='login-id-bottom'>
        <div className='login-id-buttons'>
          <LoadingButton
            loading={loading}
            fullWidth
            size='large'
            variant='contained'
            onClick={onContinue}
            disabled={loading || isStagingProdDb}
          >
            Continue
          </LoadingButton>
          <LoadingButton
            fullWidth
            size='large'
            variant='passive'
            onClick={() => onAltSignInClick(type)}
            disabled={isStagingProdDb}
          >
            Sign in with {type === 'phone' ? 'email' : 'phone'}
          </LoadingButton>
        </div>

        {!isStagingProdDb && !kfaRoute && (
          <div className='login-id-signup'>
            Don't have an account?
            <LoginLink className='login-id-signup-link' to='/onboarding'>
              Sign up
            </LoginLink>
          </div>
        )}
      </div>
    </LoginWindow>
  );
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['auth', 'loading']),
  resError: _.get(state, ['auth', 'resError'])
});

const mapDispatchToProps = {
  requestLoginOtp,
  requestLoginEmail
};

const ConnectedLoginId = connect(mapStateToProps, mapDispatchToProps)(LoginId);

export default ConnectedLoginId;
