import _ from 'lodash';
import axios from 'axios';
import { useSnackbarRef } from '@app/src/Components/Snackbar/Snackbar';
import { serverUrl } from '@app/src/global/Environment';
import { trackActivity } from '@app/src/services/analyticsService';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

export const expenseUpload = async ({ file }) => {
  try {
    trackActivity('expense upload: create upload attempt', {
      file_name: file.filename,
      file_format: file.type
    });

    if (file.type === 'file/image') {
      throw new Error('The file type is not supported. Please upload a different file.');
    }

    notify(`${file.filename} upload in process. Expect an update within 10 minutes.`, {
      origin: 'expense upload'
    });

    const response = await axios.post(`${baseUrl}api/expense/expense-upload`, file);

    if (_.get(response, ['data', 'status']) !== 'ok') {
      useSnackbarRef.closeSnackbar();
      notify(_.get(response, ['data', 'msg']) || 'Unable to upload file');
    }

    return response.data;
  } catch (e) {
    useSnackbarRef.closeSnackbar();
    notify('An error occurred while uploading the file');
    defaultCaptureException(e);
  }
};
