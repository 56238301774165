import { useCalculateTaxEstimateQuery } from '@app/src/api/taxProfileApi';

const useTaxEstimate = (year) => {
  const result = useCalculateTaxEstimateQuery({
    year
  });

  return result;
};

export default useTaxEstimate;
