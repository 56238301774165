import { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import { TAGS } from '@app/src/api/baseApi';
import taxDataApi, { useLazyGetUIStageQuery } from '@app/src/api/taxDataApi';
import { isReactNative } from '@app/src/global/Helpers';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

/**
 * Hook refetching all api data given a change in ui stage
 */
export const useUpdatePageOnUIStageChange = ({ uiStagePollingIntervalMs }) => {
  const year = useSelector(yearSelector);
  const isWebviewFocused = useSelector(isWebviewFocusedSelector);
  const [getUIStage] = useLazyGetUIStageQuery();
  const [previousUIStage, setPreviousUIStage] = useState(null);
  const [previousYear, setPreviousYear] = useState(year);
  const dispatch = useDispatch();

  useInterval(async () => {
    // Don't poll when taxes in background
    if (!isWebviewFocused && isReactNative()) {
      return;
    }
    const { data: uiStage } = await getUIStage({ year });

    if (!_.isNil(previousUIStage) && uiStage !== previousUIStage && year === previousYear) {
      dispatch(taxDataApi.util.invalidateTags(_.values(TAGS)));
    }

    setPreviousUIStage(uiStage);
    setPreviousYear(year);
  }, uiStagePollingIntervalMs);
};
