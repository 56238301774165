import _ from 'lodash';
import { createSelector } from 'reselect';
import { substitutionsSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getStringWithSubstitutions } from '@app/src/taxflow/main/utils/mainUtils';
import {
  currentQuestionSelector,
  cachedNavigationItemsSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';

export const taxProgressStageSelector = (state) =>
  _.get(state, ['taxFlowNavigation', 'currentNavigationSection', 'stage']);
export const isNavigationPanelOpenSelector = (state) => _.get(state, ['taxFlowNavigation', 'isNavigationPanelOpen']);
export const supportAccessOriginSelector = (state) => _.get(state, ['taxFlowNavigation', 'supportAccessOrigin']);
export const selfUnlockModalOpenSelector = (state) => _.get(state, ['taxFlowNavigation', 'selfUnlockModalOpen']);
export const blockingModalPropsSelector = (state) => _.get(state, ['taxFlowNavigation', 'blockingModalProps']);

const navigationItemsSelector = createSelector(
  [cachedNavigationItemsSelector, substitutionsSelector],
  (items, substitutions) =>
    items.map((item) => {
      item = {
        ...item,
        title: item.title ? getStringWithSubstitutions({ str: item.title, substitutions }) : null,
        description: item.description ? getStringWithSubstitutions({ str: item.description, substitutions }) : null,
        url: item.url ? getStringWithSubstitutions({ str: item.url, substitutions }) : null
      };
      return item;
    })
);

export const aboutYouEnabledSelector = createSelector([cachedNavigationItemsSelector], (navigationItems) => {
  const dashboardNavItem = navigationItems.find((item) => item.id === 'dashboard');
  const aboutYouEnabled = _.get(dashboardNavItem, ['meta', 'aboutYouEnabled']);
  return !!aboutYouEnabled;
});

export const currentNavigationItemSelector = createSelector(
  [navigationItemsSelector, currentQuestionSelector],
  (navigationItems, currentQuestion) => {
    const currentSection = _.get(currentQuestion, ['section', 'fields', 'slug']);
    return navigationItems.find((item) => item.id === currentSection);
  }
);

export const isNavigationPanelEnabledSelector = createSelector([currentQuestionSelector], (currentQuestion) => {
  return _.get(currentQuestion, 'flow') === 'tax file';
});
