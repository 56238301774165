import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CategorySearch from '@app/src/Components/ExpenseReview/Common/CategorySearch/CategorySearch';
import { ExpenseReviewTableChevronFilterIcon } from '@app/src/Components/ExpenseReview/ExpenseReviewTableChevronFilterIcon.jsx';
import { colorPrimary } from '@app/src/theme';

const ExpenseReviewFilterSelectCategory = ({ value, filterSelected, onChange, filterOptions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const hasOptions = filterOptions.length > 0;
  const cursorType = hasOptions ? 'pointer' : 'default';

  const handleClick = ({ currentTarget }) => {
    if (hasOptions) {
      setAnchorEl(currentTarget);
    }
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }

    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        endIcon={
          <ExpenseReviewTableChevronFilterIcon hasOptions={hasOptions} filterSelected={filterSelected} isOpen={open} />
        }
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            cursor: cursorType
          }
        }}
        style={{ fontSize: '14px', padding: 0, fontWeight: 700, color: colorPrimary }}
      >
        Category
      </Button>
      <CategorySearch
        open={open}
        anchorEl={anchorEl}
        value={value}
        onChange={onChange}
        handleClose={handleClose}
        filterOptions={filterOptions}
      />
    </>
  );
};

export default ExpenseReviewFilterSelectCategory;
