import React, { forwardRef, useCallback, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import useTheme from '@mui/material/styles/useTheme';
import classNames from 'classnames';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useToggle, useWindowSize } from 'react-use';
import AnimatedNumber from '@app/src/Components/Common/AnimatedNumber/AnimatedNumber';
import ExpenseReviewCategoryPills from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryPills';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import {
  savingsToDeductionsAnnouncementViewedSelector,
  useGetCampaignQuery,
  useUpdateTestCampaignsMutation
} from '@app/src/api/profileApi';
import { numberWith0DecimalPlaces } from '@app/src/global/Helpers';
import {
  expensesYearSelector,
  isInitialExpensesLoadSelector,
  savingsAmountSelector,
  sidebarComponentSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import '@app/src/Components/ExpenseReview/ExpenseReviewExpensesSavingsContainer.scss';

// TODO: Remove when mobile is released
const ANNOUNCEMENT_DISABLED = true;

const ExpenseReviewSavingsContainer = (props) => {
  const { announcementViewed, ...getCampaignQueryResult } = useGetCampaignQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      announcementViewed: savingsToDeductionsAnnouncementViewedSelector(result)
    })
  });

  const isInitialExpensesLoad = useSelector(isInitialExpensesLoadSelector);

  if (isInitialExpensesLoad || !getCampaignQueryResult.isSuccess || ANNOUNCEMENT_DISABLED || announcementViewed) {
    return <ConnectedExpenseReviewExpensesSavingsContainer {...props} />;
  }

  return <SavingsContainerWithPopover announcementViewed={announcementViewed} {...props} />;
};

const ExpenseReviewExpensesSavingsContainerBody = forwardRef(
  ({ isInitialExpensesLoad, empty, isSidebarOpen, setSidebarComponent, expensesYear, zIndex, totalSavings }, ref) => {
    const { width: windowWidth } = useWindowSize();
    const isDesktop = windowWidth >= 1024;

    const handleSavingsClick = () => {
      if (isSidebarOpen) {
        setSidebarComponent(null);
        return;
      }

      trackActivity('navigation: tax savings info', { origin: 'web' });
      setSidebarComponent('SavingsInfo');
    };

    return (
      <div className='expense-review-expenses-savings-container' style={{ marginBottom: '16px', zIndex }} ref={ref}>
        <Button className='header-savings' variant='outlined' onClick={handleSavingsClick}>
          <div>
            <div className='expense-review-expenses-savings-text'>Total savings</div>
            <div
              className={classNames('expense-review-expenses-savings-amount', {
                'expense-review-expenses-savings-amount-loading': isInitialExpensesLoad || empty
              })}
            >
              {isInitialExpensesLoad || empty ? (
                '$???'
              ) : (
                <div className='flex align-center'>
                  $<AnimatedNumber value={numberWith0DecimalPlaces(totalSavings)} />
                </div>
              )}
            </div>
            {isDesktop && <ExpenseReviewCategoryPills loading={isInitialExpensesLoad} expensesYear={expensesYear} />}
          </div>
        </Button>
      </div>
    );
  }
);

// SVG from replace savings from deductions figma
const TooltipArrow = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40.7' height='24' viewBox='0 0 40.7 24' fill='none'>
      <g filter='url(#filter0_d_11297_64199)'>
        <path d='M19.6667 0L40.8516 24H0.148438L19.6667 0Z' fill='white' />
      </g>
      <defs>
        <filter
          id='filter0_d_11297_64199'
          x='0.148438'
          y='0'
          width='49.7031'
          height='33'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='5' dy='5' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11297_64199' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11297_64199' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};

const SavingsContainerWithPopover = (props) => {
  const dispatch = useDispatch();
  const { announcementViewed } = props;
  const [popoverOpen, togglePopoverOpen] = useToggle(!announcementViewed);
  const theme = useTheme();
  const [updateTestCampaigns] = useUpdateTestCampaignsMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorElRefCallback = useCallback((el) => {
    if (el) {
      setAnchorEl(el);
    }
  }, []);

  const handleClose = useCallback(async () => {
    togglePopoverOpen(false);
    updateTestCampaigns({
      campaign: 'savings_to_deductions_announcement_viewed'
    });
  }, [togglePopoverOpen, updateTestCampaigns]);

  const openTaxProfile = useCallback(() => {
    togglePopoverOpen(false);
    dispatch(setSidebarComponent('TaxProfile'));
  }, [dispatch, togglePopoverOpen]);

  return (
    <>
      <ConnectedExpenseReviewExpensesSavingsContainer
        ref={anchorElRefCallback}
        zIndex={popoverOpen ? theme.zIndex.drawer + 2 : null}
        {...props}
      />
      <Backdrop
        open={popoverOpen}
        onClose={handleClose}
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
      >
        {anchorEl && (
          <Popover
            open={popoverOpen}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            PaperProps={{
              sx: {
                backgroundColor: 'transparent'
              }
            }}
          >
            <div className='deductions-announcement-container'>
              <TooltipArrow />
              <Paper
                square={false}
                className='deductions-announcement-paper'
                sx={{
                  padding: 2,
                  borderRadius: 2
                }}
              >
                <h6>Deductions tracking simplified</h6>
                <p>This section now shows total deductions, which reduce your taxable income.</p>
                <Button onClick={openTaxProfile}>Learn more</Button>
              </Paper>
            </div>
          </Popover>
        )}
      </Backdrop>
    </>
  );
};

const mapStateToProps = (state) => ({
  isInitialExpensesLoad: isInitialExpensesLoadSelector(state),
  totalSavings: savingsAmountSelector(state),
  isSidebarOpen: sidebarComponentSelector(state) === 'SavingsInfo',
  expensesYear: expensesYearSelector(state)
});

const mapDispatchToProps = {
  setSidebarComponent
};

const ConnectedExpenseReviewExpensesSavingsContainer = connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(ExpenseReviewExpensesSavingsContainerBody);

export default ExpenseReviewSavingsContainer;
