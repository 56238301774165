import React, { useEffect } from 'react';
import url from 'url';
import _ from 'lodash';
import { Clock } from 'iconsax-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Banner } from '@app/src/Components/Common/Banner/Banner';
import WarningCircle from '@app/src/assets/warning-circle.svg?react';
import { BANNER_TEXT, BANNER_VARIANT } from '@app/src/constants/constants';
import useIsManualExtensionFilingEnabled from '@app/src/hooks/useIsManualExtensionFilingEnabled';
import { useSubstitutedText } from '@app/src/hooks/useSubstitutedText';
import { TAX_YEAR_LIFECYCLE_STAGES, useTaxYearLifecycle } from '@app/src/hooks/useTaxYearLifecycle';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__EXTENSION_START } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

/**  Banner communicating important tax-related information */
export const TaxFlowBanner = ({ setLoading = _.noop }) => {
  const { isLoading: bannerLoading, bannerText, bannerVariant, icon, onBannerClick } = useTaxHomeBannerState();
  const { isLoading: substitutedTextLoading, substitutedText: substitutedBannerText } = useSubstitutedText({
    text: bannerText
  });

  const isLoading = bannerLoading || substitutedTextLoading;

  useEffect(() => setLoading(isLoading), [isLoading, setLoading]);

  if (_.isEmpty(bannerText) || isLoading) {
    return null;
  }

  return <Banner icon={icon} text={substitutedBannerText} variant={bannerVariant} onClick={onBannerClick} />;
};

const useTaxHomeBannerState = () => {
  const year = useSelector(yearSelector);
  const history = useHistory();
  const { stage: taxLifecycleStage, isLoading: taxLifecycleLoading } = useTaxYearLifecycle({ taxYear: year });
  const { isManualExtensionFilingEnabled, isLoading: isManualExtensionFilingEnabledLoading } =
    useIsManualExtensionFilingEnabled();

  if (taxLifecycleLoading || isManualExtensionFilingEnabledLoading) {
    return { isLoading: true, bannerText: null, bannerVariant: BANNER_VARIANT.DEFAULT, onBannerClick: _.noop };
  }

  const isFullFilingOpen = _.includes(
    [TAX_YEAR_LIFECYCLE_STAGES.FULL, TAX_YEAR_LIFECYCLE_STAGES.FULL_EXCEPT_SIGN_AND_FILE],
    taxLifecycleStage
  );
  const isTaxFilingClosed = taxLifecycleStage === TAX_YEAR_LIFECYCLE_STAGES.CLOSED;
  if (!isFullFilingOpen && !isTaxFilingClosed) {
    return {
      isLoading: false,
      bannerText: BANNER_TEXT.TAX_FILING_OPEN,
      bannerVariant: BANNER_VARIANT.DEFAULT,
      icon: <WarningCircle />,
      onBannerClick: _.noop
    };
  }

  if (isManualExtensionFilingEnabled) {
    return {
      isLoading: false,
      bannerText: BANNER_TEXT.MANUAL_EXTENSION,
      bannerVariant: BANNER_VARIANT.CLICK_THROUGH,
      icon: <Clock size={20} />,
      onBannerClick: () => {
        history.push(
          url.format({
            pathname: `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__EXTENSION_START}`,
            query: { origin: 'extension' }
          })
        );
      }
    };
  }

  return {
    isLoading: false,
    bannerText: null,
    icon: null,
    bannerVariant: BANNER_VARIANT.DEFAULT,
    onBannerClick: _.noop
  };
};
