import {
  TAX_VALIDATION__SET_ERRORS,
  TAX_VALIDATION__SET_CURRENT_PAGE_HAS_NEW_ERROR
} from '@app/src/constants/taxValidationConstants';

export const setErrors = (payload) => ({
  type: TAX_VALIDATION__SET_ERRORS,
  payload
});

export const setCurrentPageHasNewError = (payload) => ({
  type: TAX_VALIDATION__SET_CURRENT_PAGE_HAS_NEW_ERROR,
  payload
});
