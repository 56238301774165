import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import CategoryUsageSection from '@app/src/Components/TaxProfile/FormSections/CategoryUsageSection';
import CreditsAndDeductionsSection from '@app/src/Components/TaxProfile/FormSections/CreditsAndDeductionsSection';
import HouseholdDetailsSection from '@app/src/Components/TaxProfile/FormSections/HouseholdDetailsSection';
import IncomeSection from '@app/src/Components/TaxProfile/FormSections/IncomeSection';
import TaxesPaidSection from '@app/src/Components/TaxProfile/FormSections/TaxesPaidSection';
import TaxProfileFormProvider from '@app/src/Components/TaxProfile/TaxProfileFormProvider';

const BaseTaxProfileForm = (props) => {
  return (
    <TaxProfileFormProvider {...props}>
      <BaseTaxProfileFormBody {...props} />
    </TaxProfileFormProvider>
  );
};

export default BaseTaxProfileForm;

const useScrollToSection = (initialSection) => {
  const categoryUsageRef = useRef();

  useEffect(() => {
    const ref = (() => {
      switch (initialSection) {
        case 'CategoryUsage':
          return categoryUsageRef;
        default:
          break;
      }
    })();

    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [initialSection]);

  return {
    categoryUsageRef
  };
};

const BaseTaxProfileFormBody = ({
  year,
  initialSection,
  header = `Estimated tax refund (${year})`,
  renderBeforeSections
}) => {
  const {
    formState: { isSubmitting }
  } = useFormContext();

  const { categoryUsageRef } = useScrollToSection(initialSection);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1.5',
        width: 450,
        cursor: isSubmitting ? 'progress' : undefined
      }}
    >
      <h2
        style={{
          margin: 0,
          fontSize: 24,
          fontWeight: 500
        }}
      >
        {header}
      </h2>
      <hr
        style={{
          marginTop: 0,
          marginBottom: 24,
          width: '100%'
        }}
      />
      {renderBeforeSections && renderBeforeSections()}
      <HouseholdDetailsSection />
      <IncomeSection />
      <CategoryUsageSection ref={categoryUsageRef} />
      <TaxesPaidSection />
      <CreditsAndDeductionsSection />
    </div>
  );
};
