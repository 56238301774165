import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  COLLECTION_TYPE__SELF,
  ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS,
  ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE
} from '@app/src/taxflow/sections/personal/constants/personalConstants';
import { SLUG__SUBMIT_EMAIL_INFO } from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  DEFAULT_COLLECTION_ID,
  SLUG__CONTACT_SUPPORT,
  SLUG__LINKED_ACCOUNTS,
  SLUG__PAST_RETURNS,
  SLUG__SETTINGS,
  SLUG__SWITCH_TO_DESKTOP
} from '@app/src/taxflow/shared/constants/sharedConstants';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';

export const currentQuestionSelector = (state) => _.get(state, ['taxFlow', 'currentQuestion']);
export const currentAnswerSelector = (state) => _.get(state, ['taxFlow', 'currentAnswer']);
export const contextLoadingSelector = (state) => _.get(state, ['taxFlow', 'contextLoading']);
export const resultLoadingSelector = (state) => _.get(state, ['taxFlow', 'resultLoading']);
export const updatingSelector = (state) => _.get(state, ['taxFlow', 'updating']);
export const selectedCollectionTypesSelector = (state) => _.get(state, ['taxFlow', 'selectedCollectionTypes']);
export const resErrorSelector = (state) => _.get(state, ['taxFlow', 'resError']);
export const isWebviewFocusedSelector = (state) => _.get(state, ['taxFlow', 'isWebviewFocused']);

export const currentCollectionIdSelector = (state) => _.get(state, ['taxFlow', 'currentCollectionId']);
export const currentTaxStateSelector = (state) => _.get(state, ['taxFlow', 'currentTaxState']);
export const queryResultsSelector = (state) => _.get(state, ['taxFlow', 'queryResults']);
export const cachedNavigationItemsSelector = (state) => _.get(state, ['taxFlowNavigation', 'navigationItems']);
export const deductionsSelector = (state) => _.get(state, ['taxFlow', 'deductions']);
export const irsPaymentAccountSelector = (state) => _.get(state, ['taxFlow', 'irsPaymentAccount']);
export const ratingSelector = (state) => _.get(state, ['taxFlow', 'rating']);
export const hoverRatingSelector = (state) => _.get(state, ['taxFlow', 'hoverRating']);
export const isNextOrPrevButtonLoadingSelector = (state) => _.get(state, ['taxFlow', 'isNextOrPrevButtonLoading']);

export const standardDeductionSelector = createSelector([deductionsSelector], (deductions) =>
  _.get(deductions, 'standardDeduction')
);

export const itemizedDeductionSelector = createSelector([deductionsSelector], (deductions) =>
  _.get(deductions, 'itemizedDeduction')
);

export const taxFilePriceSelector = (state) =>
  _.get(state, ['onboarding', 'contentfulPriceEntry', 'fields', 'taxFilePrice']);

export const statusSelector = createSelector([queryResultsSelector], (queryResults) => {
  const queryResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__SELF,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__SELF_TAX_STATUS
  });

  return _.get(queryResult, ['answer', 'value']);
});

export const isAlabamaOrMinnesotaSelector = (state) =>
  _.get(state, ['taxFlow', 'queryResults'], []).some(
    (result) =>
      result.slug === ENDPOINT_ATTRIBUTE__HOME_ADDRESS_STATE &&
      ['AL', 'MN'].includes(_.get(result, ['answer', 'value']))
  );

export const queryResultsMapSelector = createSelector([queryResultsSelector], (queryResults) => {
  return queryResults.reduce((results, result) => {
    const value = _.get(result, ['answer', 'value']);

    const path = [result.coll_type, result.coll_id, result.slug];

    return _.set(results, path, value);
  }, {});
});

export const showFooterSelector = createSelector([currentQuestionSelector], (currentQuestion) => {
  return ![
    SLUG__CONTACT_SUPPORT,
    SLUG__PAST_RETURNS,
    SLUG__SETTINGS,
    SLUG__LINKED_ACCOUNTS,
    SLUG__SWITCH_TO_DESKTOP
  ].includes(_.get(currentQuestion, 'slug'));
});

export const persistErrorSelector = createSelector(
  [currentQuestionSelector, resErrorSelector],
  (currentQuestion, error) => {
    return _.get(currentQuestion, 'slug') === SLUG__SUBMIT_EMAIL_INFO && error.startsWith('A copy of your');
  }
);

export const currentCollectionTypeSelector = (state) => _.get(state, ['taxFlow', 'currentQuestion', 'collectionType']);
