import React from 'react';
import Button from '@mui/material/Button';
import { colorSecondary } from '@app/src/theme';

/** @type {typeof Button} */
const OutlinedButton = (props) => {
  return (
    <Button
      {...props}
      sx={{
        borderWidth: '1.5px',
        borderStyle: 'solid',
        borderColor: colorSecondary,
        marginTop: 2,
        borderRadius: 1,
        flex: 1,
        ...props.sx
      }}
    />
  );
};

export default OutlinedButton;
