import React, { useState } from 'react';
import url from 'url';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TaxFlowPill from '@app/src/Components/TaxFlow/Common/TaxFlowPill';
import { setContextLoading } from '@app/src/actions/taxFlowActions';
import { useGetMiniDashboardsQuery, useUpdateTaxDataMutation } from '@app/src/api/taxDataApi';
import history from '@app/src/keeperHistory';
import { isQuestionnaireFlowSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import TaxFlowCollectionDeleteModal from '@app/src/taxflow/collection/components/TaxFlowCollectionDeleteModal';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { advanceToNextQuestionnaireQuestion } from '@app/src/taxflow/main/services/mainService';
import { getPathComponentBySlug } from '@app/src/taxflow/main/utils/mainUtils';
import { DEFAULT_COLLECTION_ID, TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';

export const TaxFlowMiniDashboardPills = ({ collectionType, endpointAttribute }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const year = useSelector(yearSelector);
  const isQuestionnaireFlow = useSelector(isQuestionnaireFlowSelector);

  const { data: miniDashboards, isLoading: miniDashboardsLoading } = useGetMiniDashboardsQuery({ year });

  const [showCollectionDeleteModal, setShowCollectionDeleteModal] = useState(false);
  const [deleteModalTargetCollectionId, setDeleteModalTargetCollectionId] = useState();
  const [updateTaxData] = useUpdateTaxDataMutation();

  if (miniDashboardsLoading) {
    return null;
  }

  const miniDashboardPills = _.chain(miniDashboards).find({ collectionType }).get('pills').value();

  const confirmPillDeletion = ({ collectionId, text }) => {
    trackActivity(`${collectionType}: pill removal requested`, { collectionId, pillName: text });
    setShowCollectionDeleteModal(true);
    setDeleteModalTargetCollectionId(collectionId);
  };

  const onClickPill = async ({ startSlug, collectionId, text }) => {
    trackActivity(`${collectionType}: pill clicked`, { collectionId, pillName: text });
    const nextPathComponent = getPathComponentBySlug(startSlug);
    if (_.isNil(nextPathComponent)) {
      defaultCaptureMessage(`Could not advance to next ${collectionType} question`, {
        coll_id: collectionId,
        slug: startSlug
      });
      return;
    }
    dispatch(setContextLoading(true));
    await updateTaxData({
      taxData: [
        {
          coll_id: DEFAULT_COLLECTION_ID,
          coll_type: collectionType,
          slug: endpointAttribute,
          value: '1'
        }
      ],
      generateSharedCollectionId: false,
      year
    });

    const searchParams = new URLSearchParams(location.search);

    if (isQuestionnaireFlow) {
      await dispatch(
        advanceToNextQuestionnaireQuestion({
          nextSlug: startSlug,
          nextCollectionId: collectionId,
          questionnaireEarlyExit: false,
          history
        })
      );
    } else {
      history.push(
        url.format({
          pathname: `/${TAXFLOW_BASE_URL}/${nextPathComponent}`,
          query: {
            ..._.fromPairs([...searchParams.entries()]),
            collectionId: collectionId === '0' ? undefined : collectionId
          }
        })
      );
    }
  };

  const deriveVariant = (pill) => {
    if (pill.warning) {
      return 'warning';
    }
    if (pill.addable) {
      return 'add';
    }
    return 'default';
  };

  return _.map(miniDashboardPills, (pill, index) => {
    const { collectionId, text } = pill;
    return (
      <div key={index}>
        <TaxFlowPill
          text={pill.text}
          removable={pill.removable}
          variant={deriveVariant(pill)}
          onClick={() => onClickPill(pill)}
          onRemove={() => confirmPillDeletion({ collectionId, text })}
        />
        {showCollectionDeleteModal && (
          <TaxFlowCollectionDeleteModal
            collectionType={collectionType}
            collectionId={deleteModalTargetCollectionId}
            hideModal={() => setShowCollectionDeleteModal(false)}
          />
        )}
      </div>
    );
  });
};
