import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import Loader from '@app/src/Components/Common/Loader/Loader';
import TaxFlowPastReturnItem from '@app/src/Components/TaxFlow/Question/TaxFlowPastReturnItem';
import { useGetPastReturnsQuery } from '@app/src/api/taxDataApi';
import NoPastReturnsImage from '@app/src/assets/no-past-returns.svg?react';
import { trackQuestionView } from '@app/src/taxflow/main/services/mainService';
import '@app/src/Components/TaxFlow/Question/TaxFlowPastReturns.scss';

const TaxFlowPastReturns = ({ currentQuestion, trackQuestionView }) => {
  const { data: pastReturns, isLoading: pastReturnsLoading } = useGetPastReturnsQuery();

  useEffect(() => {
    if (!pastReturnsLoading) {
      trackQuestionView(currentQuestion, { count: pastReturns.length, years: _.map(pastReturns, 'year') });
    }
  }, [currentQuestion, trackQuestionView, pastReturns, pastReturnsLoading]);

  if (pastReturnsLoading) {
    return <Loader type='screen' />;
  }

  if (_.isNil(pastReturns) || _.isEmpty(pastReturns)) {
    return (
      <div className='tax-flow-no-past-returns'>
        <div className='steps-head'>
          <NoPastReturnsImage />
          <div className='taxflow-header'>
            <h1>{_.get(currentQuestion, ['question_meta', 'noPastReturnsTitle'])}</h1>
          </div>
          <div className='step-summary'>
            <p>{_.get(currentQuestion, ['question_meta', 'noPastReturnsDescription'])}</p>
          </div>
        </div>
        <div className='steps-body'></div>
      </div>
    );
  }

  return (
    <>
      <div className='steps-head'>
        <h1>{_.get(currentQuestion, 'title')}</h1>
      </div>
      <div className='tax-flow-past-returns steps-body'>
        {pastReturns.map(({ id, year, amounts }) => (
          <TaxFlowPastReturnItem key={id} year={year} amounts={amounts} />
        ))}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  trackQuestionView
};

const ConnectedTaxFlowPastReturns = connect(null, mapDispatchToProps)(TaxFlowPastReturns);

export default ConnectedTaxFlowPastReturns;
