import { baseApi, TAGS } from '@app/src/api/baseApi';

/************************************************** API **************************************************/
const taxValidationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /** Get validation errors for the given tax data */
    getValidationErrors: builder.query({
      query: ({ year, taxData }) => ({
        url: `tax-validation/validate`,
        method: 'GET',
        params: { year, taxData: JSON.stringify(taxData) }
      }),
      transformResponse: (response) => response.data.errors,
      providesTags: (result, error, { year }) => [{ type: TAGS.TAX_VALIDATION_ERRORS, id: year }]
    }),
    /** Bulk dismiss multiple validation errors */
    dismissValidations: builder.mutation({
      query: ({ validationSlugs, collectionType, collectionId, year }) => ({
        url: 'tax-validation/dismiss-validations',
        method: 'POST',
        body: { validationSlugs, collectionType, collectionId, year }
      }),
      invalidatesTags: (result, error, { year }) => [
        { type: TAGS.TAX_VALIDATION_ERRORS, id: year },
        { type: TAGS.TAX_INFO, id: year }
      ]
    })
  })
});

/************************************************** HOOKS **************************************************/
export const { useLazyGetValidationErrorsQuery } = taxValidationApi;

/************************************************** ACTIONS **************************************************/
export const dismissValidations =
  ({ validationSlugs, collectionType, collectionId, year }) =>
  async (dispatch) => {
    await dispatch(
      taxValidationApi.endpoints.dismissValidations.initiate({ validationSlugs, collectionType, collectionId, year })
    );
  };
