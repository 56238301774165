/* eslint no-console:0*/
import _ from 'lodash';
import { datadogRum } from '@datadog/browser-rum';
import debug from 'debug';
import { v4 as uuidv4 } from 'uuid';
import {
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_TIKTOK_STORAGE_KEY,
  Url_TUNE_TRANSACTION_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import { REACT_APP_ENV, REACT_APP_VERSION, serverUrl, TUNE_GOALS_MAP } from '@app/src/global/Environment';
import {
  formatPhoneNumber,
  getBrowserName,
  getBrowserOS,
  isReactNative,
  sentMsgToReactNative
} from '@app/src/global/Helpers';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { isSignupLinkSkippable } from '@app/src/utils/userUtils';

const logger = debug('keeper:analytics');

/**
 * @desc set userid and email for segment analytics
 */
export const setUser = async (user) => {
  const signup_link_test_group = isSignupLinkSkippable(user);

  try {
    logger('setUser', user.phone, { ...user, signup_link_test_group });

    if (user && user.email) {
      await window.analytics.identify(user.phone, {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        phone: formatPhoneNumber(user.phone),
        signup_link_test_group
      });
      identifyTatariUid(user?.id);
      initUserExceptSegment(user);
    } else {
      clearUser();
    }
  } catch (e) {
    defaultCaptureException(e);
  }
};

/**
 * @desc if we have UTMs, call identify without user info
 */
export const sendUtms = ({ params }) => {
  ['utm_source', 'utm_medium', 'utm_content', 'utm_campaign', 'utm_term', 'utm_placement'].forEach((utmParam) => {
    if (utmParam in params) {
      window.analytics.identify({ [utmParam]: params[utmParam] });
    }
  });
};

/**
 * @desc set userid and set object with property like firstname,lastname,emails, jobs for segment analytics
 */
export const setUserWithObj = (id, obj) => {
  try {
    if (isReactNative()) {
      sentMsgToReactNative('identify', { phone: id, traits: obj });
    }

    logger('setUserWithObj', id, obj);

    if (id && obj) {
      window.analytics.identify(id, obj);
    } else {
      clearUser();
    }
  } catch (e) {
    defaultCaptureException(e);
  }
};

/**
 * @desc clear user information for segment analytics
 */
export const clearUser = () => {
  try {
    logger('clearUser');
    window.analytics.reset();
    clearUserExceptSegment();
  } catch (e) {
    defaultCaptureException(e);
  }
};

const initUserExceptSegment = (user) => {
  datadogRum.setUser({
    ..._.pick(user, ['id', 'phone', 'email']),
    name: `${user.firstname} ${user.lastname}`
  });
};

const clearUserExceptSegment = () => {
  datadogRum.clearUser();
};

const trackRokuPixel = (...args) => {
  if (window.rkp) {
    logger('trackRokuPixel', ...args);
    window.rkp('event', ...args);
  }
};

const identifyTatariUid = (uid) => {
  if (window.tatari) {
    window.tatari.identify(uid);
  }
};

const trackTatariPixel = (...args) => {
  if (window.tatari) {
    logger('trackTatariPixel', ...args);
    window.tatari.track(...args);
  }
};

const getPlatform = () => {
  if (!isReactNative()) {
    return 'web';
  }

  const browserOS = getBrowserOS();

  switch (browserOS) {
    case 'ios':
    case 'android':
      return browserOS;
    default:
      return 'unknown';
  }
};

export const getDeviceInfo = () => ({
  platform: getPlatform(),
  browserOS: getBrowserOS(),
  browserName: getBrowserName(),
  environment: REACT_APP_ENV,
  version: REACT_APP_VERSION
});

/**
 * @desc track activity in segment analytics
 */
export const trackActivity = (name, params) => {
  try {
    const newParams = {
      ...params,
      ...getDeviceInfo()
    };

    let facebookClickId = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
    let tuneTransactionId = getAnalyticsTrait(Url_TUNE_TRANSACTION_STORAGE_KEY);
    let tiktokClickId = getAnalyticsTrait(Url_TIKTOK_STORAGE_KEY);

    if (name === 'signup (frontend)' || name === 'bank account linked (frontend)') {
      if (facebookClickId) {
        newParams[Url_FACEBOOK_CLICK_ID_STORAGE_KEY] = facebookClickId;
      }

      if (tiktokClickId) {
        newParams[Url_TIKTOK_STORAGE_KEY] = tiktokClickId;
      }
    }

    if (name === 'question: view' && newParams.flow === 'onboarding') {
      const eventInfo = {
        client_dedup_id: newParams.client_dedup_id,
        description: newParams.question
      };

      trackRokuPixel('PAGE_VIEW', eventInfo);
      trackTatariPixel('PAGE_VIEW', eventInfo);
    } else if (name === 'signup (frontend)') {
      trackRokuPixel('SIGN_UP');
      trackTatariPixel('SIGN_UP');
    } else if (name === 'bank account linked (frontend)') {
      trackRokuPixel('INITIATE_CHECKOUT');
      trackTatariPixel('INITIATE_CHECKOUT');
    } else if (name === 'Subscribe') {
      trackRokuPixel('START_TRIAL');
      trackTatariPixel('START_TRIAL');
    }

    logger('trackActivity', name, newParams, tuneTransactionId);

    // Handle adding Tune Transaction Id to Amplitude tracking.
    if (tuneTransactionId) {
      let goalId;

      if (name === 'signup (frontend)') {
        goalId = TUNE_GOALS_MAP.signup;
      } else if (name === 'bank account linked (frontend)') {
        goalId = TUNE_GOALS_MAP.link;
      }

      if (goalId) {
        newParams['goal_id'] = goalId;
      }

      window.analytics.track(name, newParams, {
        hasoffers: {
          transaction_id: tuneTransactionId
        }
      });
    } else {
      window.analytics.track(name, newParams);
    }
  } catch (e) {
    defaultCaptureException(e);
  }
};

export const getAnalyticsTrait = (trait) => {
  // Note: This is false if adblocker is enabled.
  if (window.analytics.user) {
    return _.get(window.analytics.user().traits(), trait);
  }
};

export const generateClientDedupId = () => {
  return uuidv4();
};

export const initDatadogRum = () => {
  const pathname = window.location.pathname || '';
  const isTaxFiling = /tax-filing/.test(pathname);

  const sessionSampleRate = isTaxFiling ? 100 : 10;

  datadogRum.init({
    applicationId: '9fd2db5c-d528-49cf-8220-6f6d75db9a04',
    clientToken: 'pub683226c806ca6f069ae5add7cc5b0695',
    site: 'datadoghq.com',
    service: 'keeper-tax',
    env: REACT_APP_ENV,
    version: REACT_APP_VERSION,
    sessionSampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [serverUrl()]
  });
};
