import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetOrCreateTaxProfileQuery from '@app/src/Components/TaxProfile/hooks/useGetOrCreateTaxProfileQuery';
import useTaxEstimate from '@app/src/Components/TaxProfile/hooks/useTaxEstimate';
import useTaxEstimateDetails from '@app/src/Components/TaxProfile/hooks/useTaxEstimateDetails';
import useTaxProfileForm from '@app/src/Components/TaxProfile/hooks/useTaxProfileForm';
import { useGetWorkInfoQuery } from '@app/src/api/profileApi';
import { useRefetchTaxProfileAndEstimateMutation } from '@app/src/api/taxProfileApi';
import { deductionsSelector, expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { getJobCategoryList } from '@app/src/services/contentfulService';

export const useGetTaxProfileData = () => {
  const getWorkInfoQueryResults = useGetWorkInfoQuery();
  const getTaxProfileQueryResults = useGetOrCreateTaxProfileQuery();

  const queryResults = useMemo(
    () => [getWorkInfoQueryResults, getTaxProfileQueryResults],
    [getTaxProfileQueryResults, getWorkInfoQueryResults]
  );

  const data = useMemo(() => {
    return {
      income_type: getWorkInfoQueryResults?.data?.income_type,
      ...getTaxProfileQueryResults.data
    };
  }, [getTaxProfileQueryResults.data, getWorkInfoQueryResults.data?.income_type]);

  const isLoading = useMemo(() => queryResults.some((result) => result.isLoading || result.isError), [queryResults]);

  return {
    data,
    queryResults,
    isLoading
  };
};

const useTaxProfile = ({ year: yearOverride } = {}) => {
  const dispatch = useDispatch();
  const expensesYear = useSelector(expensesYearSelector);
  const totalDeductions = useSelector(deductionsSelector);
  const [refetchTaxProfileAndEstimate] = useRefetchTaxProfileAndEstimateMutation();

  const year = yearOverride ?? expensesYear;

  const taxEstimateResults = useTaxEstimate(year);
  const { data: values, isLoading } = useGetTaxProfileData();

  useEffect(() => {
    // Update the tax estimate when any deductions are updated
    refetchTaxProfileAndEstimate({ year });
  }, [refetchTaxProfileAndEstimate, totalDeductions, year]);

  useEffect(() => {
    dispatch(getJobCategoryList());
  }, [dispatch]);

  const formProps = useTaxProfileForm({
    year,
    values,
    taxEstimate: taxEstimateResults.data
  });

  const detailsProps = useTaxEstimateDetails({
    year,
    taxEstimateResults
  });

  return {
    year,
    isLoading,
    formProps,
    detailsProps
  };
};

export default useTaxProfile;
