import { createTheme } from '@mui/material/styles';

// colors
export const colorFontDark = '#000a2c'; // darkest font color (headlines) - "midnight"
export const colorFontLight = '#424a60'; // lighter font color (secondary text) - "gunmetal"

export const colorSurface = '#ffffff'; // surface - "white"
export const colorNeutralGainsboro = '#dbdbdb'; // neutral - "gainsboro"
export const colorNeutralMetallica = '#aaaaab'; // neutral - "metallica"
export const colorNeutralGraphite = '#79797c'; // neutral - "graphite"
export const colorNeutralGranite = '#626263'; // neutral - "granite"
export const colorNeutralEerieBlack = `#19191B`; // neutral -- "eerie black";
const colorNeutralOuterSpace = '#49494B'; // neutral -- "outer space"

export const colorPrimary = '#001149'; // primary - "navy"
export const colorPrimaryAlt = '#4b5475'; // primary variant - "smoke"
export const colorPrimaryAlt2 = '#d6e0ff'; // primary variant 2 - "iceberg"
export const colorPrimaryAlt3 = '#f2f6ff'; // primary variant 3 - "alice blue"

export const colorSecondary = '#875dd5'; // secondary - "amethyst"
export const colorSecondaryHover = '#6f4cb0'; // secondary hover - "cyber"
const colorSecondaryAlt = '#cfbeee'; // secondary variant - "soap"
export const colorSecondaryAlt2 = '#efe5ff'; // secondary variant 2 - "lavender"
const colorSecondaryAlt3 = '#f3effb'; // secondary variant 3 - "periwinkle"

export const colorError = '#d55858';
const colorRufous = '#A60F0F';
export const colorWarning = '#f2b50a'; // warning - "amber"
const colorWarningLight = '#fcf6e5'; // lighter warning - "champagne"
export const colorSuccess = '#4abc78'; // success - "emerald"
const colorSuccessMid = '#4fc57e'; // success - "grassy"
const colorSuccessLight = '#c9eed8'; // lighter success - "green tea"

export const colorAccent = '#5fa8ff'; // accent - "sky blue"
export const colorAccentLight = '#e3f0ff'; // accent - "bubbles"
// eslint-disable-next-line import/no-unused-modules
export const colorLightWhite = '#f9fbfb'; // neutral - "light white"

export const colorDeactivated = '#90949e'; // deactivated - "gray"
export const colorDeactivatedLight = '#eff0f0'; // lightest deactivated - "cultured"
export const colorDeactivatedMid = '#d8d8d8'; // deactivated mid - "silver"

export const colorFilterSecondary =
  'brightness(0) saturate(100%) invert(40%) sepia(85%) saturate(447%) hue-rotate(219deg) brightness(93%) contrast(94%)';

const fontBodyTitle = {
  fontSize: '16px',
  fontWeight: 500
};

const fontBodyCaption = {
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px'
};

const fontBodyCopy = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '26px'
};

const fontSelectedBtn = {
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '24px'
};

export const fontUnselectedBtn = {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px'
};

const fontModalTitle = {
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '22px'
};

const fontTextXL = {
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '32px'
};

const theme = createTheme({
  components: {
    // default font family and color
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: ['"F37Blanka"', 'sans-serif'].join(','),
          color: colorFontLight
        }
      }
    },

    // slider
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '8px'
        },
        track: {
          color: colorSuccessMid
        },
        rail: {
          color: colorNeutralGainsboro
        },
        thumb: {
          color: colorSuccessMid,
          width: '20px',
          height: '20px'
        }
      },
      variants: [
        {
          props: { variant: 'keeper-slider' },
          style: {
            '& .MuiSlider-rail': {
              backgroundColor: colorNeutralMetallica,
              height: '8px'
            },
            '& .MuiSlider-thumb': {
              height: '32px',
              backgroundColor: colorSurface,
              border: `2.5px solid ${colorPrimary}`,
              boxShadow: '0px 4px 12px rgba(31, 31, 31, 0.25)',
              width: '32px'
            },
            '& .MuiSlider-track': {
              backgroundColor: colorPrimary,
              border: 'none',
              height: '10px'
            }
          }
        }
      ]
    },

    // buttons
    MuiButton: {
      styleOverrides: {
        root: {
          ...fontSelectedBtn,
          textTransform: 'none',
          outline: 'none',
          borderRadius: '8px',
          padding: '16px',

          // all outlined buttons
          // Mui Button components with prop variant='outlined'
          '&.MuiButton-outlined': {
            backgroundColor: colorSurface,
            border: `1px solid ${colorNeutralMetallica}`
          },

          // all disabled buttons
          // Mui Button components with prop disabled=true
          '&.Mui-disabled': {
            backgroundColor: colorDeactivatedLight,
            color: colorDeactivated
          },

          // Navy-colored buttons with white text
          // Mui Button components with props: variant='contained' and no color or color='primary'
          '&.Mui-containedPrimary': {
            '&:hover': {
              backgroundColor: colorFontDark,
              color: colorSurface
            }
          },

          // Amethyst-colored buttons with white text
          // Mui Button components with props: variant='contained', color='secondary'
          '&.Mui-containedSecondary': {
            '&:hover': {
              backgroundColor: colorSecondaryHover
            }
          },

          '&.action-button': {
            ...fontUnselectedBtn,
            display: 'flex',
            justifyContent: 'flex-start',
            gap: '8px'
          },

          '&.onboarding-resend-link': {
            color: colorPrimary,
            fontWeight: 600,
            padding: '4px'
          },

          '&.base-pill': {
            backgroundColor: colorAccentLight,
            color: colorFontDark,
            padding: '4px 8px',
            '&:hover': {
              backgroundColor: colorLightWhite
            }
          },
          '&.header-savings': {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: '16px',
            rippleColor: 'red',
            textAlign: 'left',
            width: '100%',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }
      },
      variants: [
        // White button with metallica outline and granite text
        // To use: import Mui Button component, pass in prop: variant='passive'
        {
          props: { variant: 'passive' },
          style: {
            ...fontBodyCopy,
            color: colorNeutralGranite,
            backgroundColor: colorSurface,
            border: `1px solid ${colorNeutralMetallica}`,
            minWidth: '32px'
          }
        },
        // Pill-like button
        {
          props: { variant: 'pill' },
          style: {
            color: colorNeutralOuterSpace,
            fontWeight: 500,
            fontSize: 12,
            padding: '3px 24px',
            backgroundColor: 'translucent',
            border: `1px solid ${colorNeutralOuterSpace}`,
            whiteSpace: 'nowrap'
          }
        },
        // Purple button with dotted outline
        {
          props: { variant: 'dashed' },
          style: {
            ...fontBodyCopy,
            color: colorSecondary,
            backgroundColor: colorSecondaryAlt3,
            border: `1px dashed ${colorSecondary}`,

            '&:hover': {
              backgroundColor: colorSecondaryAlt2
            },

            '&.Mui-disabled': {
              backgroundColor: colorSecondaryAlt3
            },

            '&:active': {
              backgroundColor: colorSecondaryAlt3
            },

            '&.tax-flow-add-stock-btn': {
              marginTop: '24px'
            },

            '&.tax-flow-add-dependent-btn': {
              marginTop: ' 16px'
            }
          }
        },
        // Label button
        {
          props: { variant: 'label' },
          style: {
            ...fontBodyTitle,
            color: colorSecondary,
            border: `2px solid ${colorSecondary}`,

            '&:hover': {
              backgroundColor: colorSecondaryAlt3
            },

            '&:active': {
              backgroundColor: colorSecondaryAlt3
            }
          }
        },
        // Text button
        {
          props: { variant: 'text' },
          style: {
            ...fontBodyTitle,
            color: colorSecondary,
            minWidth: 'auto',

            '&:hover': {
              backgroundColor: colorSecondaryAlt3
            },

            '&:active': {
              backgroundColor: colorSecondaryAlt3
            },

            '&.taxflow-collection-list-add': {
              height: 40
            }
          }
        },
        // Vertical icon button
        {
          props: { variant: 'vertical-icon-button' },
          style: {
            display: 'flex',
            flexDirection: 'column',
            border: `2px solid ${colorNeutralMetallica}`,
            borderRadius: '8px',
            fontFamily: 'F37Blanka, sans-serif',
            fontSize: '14px',
            lineHeight: '16px',
            minHeight: '110px',
            width: '47%'
          }
        },
        // Icon button
        {
          props: { variant: 'icon-button' },
          style: {
            ...fontBodyCaption,
            backgroundColor: colorDeactivatedLight,
            padding: '0',
            borderRadius: '50px',
            minWidth: '30px',
            minHeight: '30px'
          }
        },
        {
          props: { variant: 'subscription-type' },
          style: {
            ...fontBodyCopy,
            color: colorFontDark,
            backgroundColor: colorSurface,
            border: `2px solid ${colorNeutralMetallica}`,
            borderRadius: '8px',
            margin: 0,
            padding: 0,
            textAlign: 'left',
            '&:hover': {
              backgroundColor: colorAccentLight
            }
          }
        },
        {
          props: { variant: 'subscription-type-selected' },
          style: {
            ...fontBodyCopy,
            color: colorFontDark,
            backgroundColor: colorSurface,
            border: `2px solid ${colorSecondary}`,
            borderRadius: '8px',
            padding: 0,
            margin: 0,
            textAlign: 'left'
          }
        },
        {
          props: { variant: 'warning-button' },
          style: {
            ...fontBodyCopy,
            color: colorFontLight,
            backgroundColor: colorWarningLight,
            borderRadius: '8px',
            padding: '16px',
            textAlign: 'left'
          }
        },
        {
          props: { variant: 'toolbar-button' },
          style: {
            ...fontBodyCopy,
            color: colorSecondary,
            height: '24px',
            margin: '0 8px',
            '&:hover': {
              backgroundColor: colorSecondaryAlt3
            }
          }
        }
      ],
      defaultProps: {
        disableElevation: true
      }
    },

    // links
    MuiLink: {
      variants: [
        // Sky-blue-colored, bold text link
        // To use: import Mui Link component, pass in prop: variant='ask'
        {
          props: { variant: 'ask' },
          style: {
            ...fontUnselectedBtn,
            color: colorAccent
          }
        },
        {
          props: { variant: 'page-link' },
          style: {
            ...fontUnselectedBtn,
            color: colorSecondary,
            fontWeight: 500
          }
        }
      ]
    },

    MuiList: {
      variants: [
        {
          props: { variant: 'category-list' },
          style: {
            borderRadius: '8px',
            border: `1px solid ${colorNeutralGranite}`,
            width: '100%'
          }
        },
        {
          props: { variant: 'scroll' },
          style: {
            height: '92vh',
            overflow: 'auto',
            width: '100%',
            scrollBarColor: 'transparent transparent'
          }
        }
      ]
    },

    MuiListItem: {
      variants: [
        {
          props: { variant: 'category-item' },
          style: {
            ...fontBodyCopy,
            borderBottom: `1px solid ${colorNeutralMetallica}`,
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: colorAccentLight
            },
            '&:first-of-type': {
              borderTopRightRadius: '8px',
              borderTopLeftRadius: '8px'
            },
            '&:last-child': {
              borderBottom: 'none',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px'
            }
          }
        }
      ]
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiAccordion-root': {
            '>.MuiAccordionSummary-root': {
              padding: '16px',
              borderRadius: '8px',
              border: `1px solid ${colorNeutralMetallica}`
            }
          }
        }
      }
    },

    // modal title
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [`@media (min-width:0px)`]: {
            ...fontModalTitle,
            fontSize: '20px'
          },
          [`@media (min-width:600px)`]: {
            ...fontTextXL
          },
          color: colorFontDark,
          borderBottom: `1px solid ${colorNeutralMetallica}`
        }
      },
      variants: [
        {
          props: { variant: 'paywall' },
          style: {
            ...fontTextXL,
            color: colorFontDark,
            textAlign: 'center',
            borderBottom: `1px solid ${colorNeutralMetallica}`,
            padding: '0px 16px 16px'
          }
        }
      ]
    },
    // modal body react markdown
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...fontUnselectedBtn,
          color: colorFontLight,
          padding: '16px',

          '&.submit-confirmation-modal-body': {
            marginTop: '16px'
          }
        }
      }
    },
    // modal body
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          ...fontBodyCopy,
          color: colorFontLight
        }
      }
    },
    // modal accordion section title
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          ...fontBodyTitle,
          lineHeight: '18px',
          color: colorFontDark,
          marginBottom: '0px'
        }
      }
    },
    // modal accordion section body
    MuiAccordion: {
      styleOverrides: {
        root: {
          ...fontUnselectedBtn,
          color: colorFontLight,
          margin: '0px',

          '&.Mui-expanded': {
            margin: '16px 0px',
            padding: '16px',
            borderRadius: '8px',
            border: `1px solid ${colorNeutralMetallica}`,

            '>.MuiAccordionSummary-root': {
              padding: '0px',
              border: 'none'
            }
          }
        }
      }
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          marginTop: '16px'
        }
      }
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colorNeutralGainsboro,
          border: 0,
          height: '1.5px',
          margin: '16px 0px'
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          ...fontUnselectedBtn,
          color: colorFontDark,
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px'
          },
          backgroundColor: colorSurface,
          height: '56px',
          border: '0px',
          borderRadius: '8px',
          padding: '16px',
          outline: 'none',

          '>.MuiInputAdornment-root': {
            color: colorNeutralGraphite
          },

          '&.Mui-focused': {
            border: '0px',
            outline: 'none',

            '>.MuiInputAdornment-root': {
              color: colorFontLight
            }
          }
        },
        notchedOutline: {
          border: `1px solid ${colorNeutralMetallica}`,
          outline: 'none',

          '&.Mui-focused': {
            border: `2px solid ${colorPrimary}`,
            outline: 'none'
          }
        },
        input: {
          padding: '0px',

          '&::placeholder': {
            color: colorNeutralGraphite,
            opacity: 1
          },

          'label[data-shrink=false] + .MuiInputBase-formControl &::-webkit-input-placeholder': {
            opacity: '1 !important'
          }
        }
      },
      variants: [
        {
          props: { variant: 'table-input' },
          style: {
            height: '36px'
          }
        }
      ]
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...fontBodyTitle,
          color: colorFontDark,
          position: 'relative',
          transform: 'none',

          '> .MuiSvgIcon-root': {
            margin: '-5px 0px 0px 5px'
          },

          '&.Mui-error': {
            color: colorFontDark
          }
        }
      },
      defaultProps: {
        shrink: false
      }
    },

    // icons
    MuiIcon: {
      styleOverrides: {
        root: {
          '&.tax-flow-list-item-icon': {
            img: {
              verticalAlign: 'text-top'
            }
          },

          '&.taxflow-submit-progress-timer-icon': {
            width: '12px',
            height: '12px',
            color: colorFontLight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },

          '&.taxflow-chip-icon': {
            width: '16px',
            height: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          '&.taxflow-collection-item-right-icon': {
            verticalAlign: 'middle'
          }
        }
      }
    },

    // icon buttons (e.g. modal close, calendar icon for date picker)
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colorFontDark
        }
      }
    },

    // select dropdown button
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.onboarding': {
            ...fontUnselectedBtn
          },

          '&.bank-link': {
            width: '100%'
          },

          '&.grey-text': {
            color: colorNeutralGraphite,
            opacity: 1
          },

          '.MuiNativeSelect-icon': {
            color: colorFontDark,
            position: 'absolute',
            top: '16px',
            right: '16px'
          }
        }
      }
    },

    MuiTableCell: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          padding: '0',
          height: '36px',
          cursor: 'pointer'
        }
      }
    },

    MuiTableRow: {
      defaultProps: {
        hover: true
      }
    },

    MuiTable: {
      styleOverrides: {
        root: {
          borderColor: colorNeutralMetallica
        }
      }
    },

    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            minHeight: '36px'
          }
        }
      }
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          color: colorFontDark
        }
      }
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          // onboarding job category searchbox
          '&.search-list-searchbox': {
            '> .MuiOutlinedInput-root': {
              ...fontUnselectedBtn
            },

            '> .MuiOutlinedInput-input': {
              '&::placeholder': {
                ...fontSelectedBtn
              }
            }
          },

          '&.tax-flow-custom-job-item-selected': {
            '> .MuiOutlinedInput-root': {
              '> .MuiOutlinedInput-notchedOutline': {
                border: `2px solid ${colorPrimary}`,
                outline: 'none'
              }
            }
          }
        }
      }
    },

    MuiSkeleton: {
      defaultProps: {
        animation: 'pulse',
        variant: 'rectangular'
      },
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          scrollMarginTop: '1em'
        },
        listbox: ({ theme }) => ({
          maxHeight: 'min(250px, 40vh)',
          [theme.breakpoints.down(1023)]: {
            maxHeight: 'calc(90vh - 84px - 8em)'
          }
        })
      }
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 4,
          '& .MuiSwitch-switchBase': {
            padding: 5,
            margin: 2,
            '&.Mui-checked': {
              '& + .MuiSwitch-track': {
                backgroundColor: colorSecondary,
                opacity: 1
              }
            }
          },
          '& .MuiSwitch-track': {
            backgroundColor: colorNeutralMetallica,
            borderRadius: 15,
            height: 30
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: colorSurface,
            boxShadow: 'none',
            width: 24,
            height: 24
          }
        }
      }
    }
  },
  palette: {
    primary: {
      main: colorPrimary,
      variant: colorPrimaryAlt,
      variant2: colorPrimaryAlt2,
      variant3: colorPrimaryAlt3
    },
    secondary: {
      main: colorSecondary,
      variant: colorSecondaryAlt,
      variant2: colorSecondaryAlt2,
      hover: colorSecondaryHover
    },
    error: {
      main: colorRufous,
      light: colorWarningLight
    },
    warning: {
      main: colorWarning,
      light: colorWarningLight
    },
    info: {
      main: colorAccentLight,
      dark: colorPrimaryAlt,
      light: colorFontLight,
      accent: colorAccent
    },
    success: {
      main: colorSuccess,
      light: colorSuccessLight
    },
    danger: {
      main: colorError
    }
  },
  typography: {
    fontFamily: ['"F37Blanka"', 'sans-serif'].join(',')
  }
});

export default theme;
