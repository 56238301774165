import React, { useRef } from 'react';
import AssistantContainer from '@app/src/Components/Assistant/AssistantContainer';
import AssistantFooter from '@app/src/Components/Assistant/AssistantFooter';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { colorSurface } from '@app/src/theme';
import '@app/src/Components/Assistant/Assistant.scss';

/**
 * This component renders as a bottom sheet if the window width is < 1024px
 * and as a chat window if the window width is >= 1024px.
 */
const BaseAssistant = ({ submitMessage, typing, messages, error, disableSubmit, isLoading }) => {
  const messagesRef = useRef(null);

  return (
    <AssistantContainer
      isLoading={isLoading}
      header={
        <header className='assistant-header' aria-label='Ask'>
          <HelpSupportIcon width={24} height={24} color={colorSurface} />
          <h2 className='assistant-title'>Ask</h2>
        </header>
      }
      footer={
        <AssistantFooter
          submitMessage={submitMessage}
          isLoading={isLoading}
          disableSubmit={disableSubmit}
          typing={typing}
        />
      }
      messagesRef={messagesRef}
      submitMessage={submitMessage}
      virtualizerProps={{
        messages,
        error
      }}
    />
  );
};

export default BaseAssistant;
