import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import TaxValidationNumberFormat from '@app/src/Components/TaxValidation/TaxValidationNumberFormat';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { CATEGORY_TYPE_MONEY } from '@app/src/constants/constants';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';

class TaxFlowNumberItem extends Component {
  onValueChange = ({ value }) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    } else {
      this.props.setCurrentAnswer({ value });
    }
  };

  render() {
    const {
      className,
      currentQuestion,
      currentAnswer,
      resultLoading,
      isChildQuestion,
      short,
      id,
      overrideCollectionId,
      onFocus = _.noop,
      onBlur = _.noop
    } = this.props;
    if (resultLoading) return null;
    const value = currentAnswer && !_.isNil(currentAnswer.value) ? currentAnswer.value : null;
    const prefix = _.get(currentQuestion, ['question_meta', 'prefix'])
      ? `${_.get(currentQuestion, ['question_meta', 'prefix'])} `
      : '';
    const suffix = _.get(currentQuestion, ['question_meta', 'suffix'])
      ? ` ${_.get(currentQuestion, ['question_meta', 'suffix'])}`
      : '';
    const maxLength = _.get(
      currentQuestion,
      ['question_meta', 'maxLength'],
      currentQuestion.question_type === CATEGORY_TYPE_MONEY ? 19 : null
    );
    const allowLeadingZeros = _.get(currentQuestion, ['question_meta', 'allowLeadingZeros']);

    return (
      <div className={classNames({ 'steps-body': !isChildQuestion }, className)}>
        <TaxValidationNumberFormat
          id={id}
          question={currentQuestion}
          answer={currentAnswer}
          suffix={suffix}
          prefix={prefix}
          short={short}
          thousandSeparator={_.get(currentQuestion, ['question_meta', 'hideThousandSeparator']) ? '' : ','}
          placeholder={currentQuestion.question_meta.default}
          onValueChange={this.onValueChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
          allowNegative={_.get(currentQuestion, ['question_meta', 'allowNegative'])}
          decimalScale={currentQuestion.question_meta.decimalScale ?? this.props.decimalScale ?? 2}
          inputMode={_.defaultTo(this.props.inputMode, 'decimal')}
          maxLength={maxLength}
          overridecollectionid={overrideCollectionId}
          isAllowed={
            this.props.isAllowed
              ? this.props.isAllowed
              : () => {
                  return true;
                }
          }
          allowLeadingZeros={allowLeadingZeros}
        />
        <TaxValidationWarnings
          question={currentQuestion}
          answer={currentAnswer}
          overrideCollectionId={overrideCollectionId}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  currentQuestion: _.get(props, ['currentQuestion']),
  currentAnswer: _.get(props, ['currentAnswer']),
  resultLoading: _.get(props, ['resultLoading'], state.taxFlow.resultLoading)
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowNumberItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowNumberItem);

export default ConnectedTaxFlowNumberItem;
