import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { AddCircle, ExportCurve, MoreCircle, Setting5, Math } from 'iconsax-react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ExpenseReviewMerchantSearchBar from '@app/src/Components/ExpenseReview/ExpenseReviewMerchantSearchBar';
import {
  setExpenseReviewModalType,
  setIsExporting,
  setMerchantNameFilterQuery,
  setMerchantNameSearch,
  setSidebarComponent
} from '@app/src/actions/expenseReviewActions';
import { isPreSubmitSelector, useGetUIStageQuery } from '@app/src/api/taxDataApi';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import { EXPENSE_REVIEW_MODAL_TYPES } from '@app/src/constants/dashboardConstants';
import useIsInConfirmAmounts from '@app/src/hooks/useIsInConfirmAmounts';
import {
  expensesYearSelector,
  isExportingSelector,
  sidebarComponentSelector
} from '@app/src/selectors/expenseReviewSelectors';
import { isExpensesYearEqualToCurrentTaxYearSelector } from '@app/src/selectors/taxFlowSelectors';
import { trackActivity } from '@app/src/services/analyticsService';
import { handleExportWriteOffs } from '@app/src/services/expenseReviewExportService';
import { updateMerchantNameFilter } from '@app/src/services/expenseReviewService';
import store from '@app/src/store/store';
import { setSelfUnlockModalOpen } from '@app/src/taxflow/navigation/actions/navigationActions';
import { PATH_COMPONENT__LINKED_ACCOUNTS } from '@app/src/taxflow/shared/constants/sharedConstants';
import { colorNeutralMetallica, colorPrimary } from '@app/src/theme';
import '@app/src/Components/ExpenseReview/ExpenseReviewMenuBar.scss';

const ExpenseReviewMenuBar = ({
  className,
  isExporting,
  setSidebarComponent,
  setExpenseReviewModalType,
  handleExportWriteOffs,
  setSelfUnlockModalOpen,
  updateMerchantNameFilter,
  setMerchantNameSearch,
  setMerchantNameFilterQuery,
  sidebarComponent,
  year
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { data: isInConfirmAmounts, isLoading: isInConfirmAmountsLoading } = useIsInConfirmAmounts();

  const { isPreSubmit } = useGetUIStageQuery(
    { year },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          isPreSubmit: isPreSubmitSelector(result)
        };
      }
    }
  );

  const onMoreClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onMoreClose = async ({ target: { type } }) => {
    if (type) {
      trackActivity('web dashboard: more dropdown action clicked', { type });
    }

    switch (type) {
      case 'export':
        await handleExportWriteOffs();
        break;
      case 'link':
        history.push(`/settings/${PATH_COMPONENT__LINKED_ACCOUNTS}`);
        break;
      case 'tax-profile':
        setSidebarComponent('TaxProfile');
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  const onAddExpenseClick = () => {
    trackActivity('navigation: manual expense add', { origin: 'web dashboard' });
    const isExpensesYearEqualToCurrentTaxYear = isExpensesYearEqualToCurrentTaxYearSelector(store.getState());
    if (isInConfirmAmounts && isExpensesYearEqualToCurrentTaxYear) {
      setSelfUnlockModalOpen(true);
      return;
    }

    setExpenseReviewModalType(EXPENSE_REVIEW_MODAL_TYPES.ADD);
  };

  const onRulesClick = () => {
    setSidebarComponent(sidebarComponent === 'Rules' ? null : 'Rules');
  };

  const handleMerchantSearch = (value) => {
    setSidebarComponent('');
    setMerchantNameSearch(value);
    setMerchantNameFilterQuery(value);
    updateMerchantNameFilter();
  };

  if (isInConfirmAmountsLoading) {
    return null;
  }

  return (
    <div className={classNames('expense-review-menu-bar', className)}>
      <ExpenseReviewMerchantSearchBar
        className='expense-review-menu-bar-search'
        onSelect={handleMerchantSearch}
        origin='web'
      />
      <Button
        className='expense-review-menu-bar-manual-expense'
        onClick={onAddExpenseClick}
        sx={{ '& .MuiButton-startIcon': { marginRight: 0 } }}
        startIcon={<AddCircle className='expense-review-menu-bar-button-icon' size={24} color={colorPrimary} />}
      >
        <div className='expense-review-menu-bar-button-text'>Add expense</div>
      </Button>
      <Button
        onClick={onRulesClick}
        disabled={isExporting}
        className='expense-review-menu-bar-manual-expense'
        sx={{ '& .MuiButton-startIcon': { marginRight: 0 } }}
        startIcon={<Setting5 className='expense-review-menu-bar-button-icon' size={24} color={colorPrimary} />}
      >
        <div className='expense-review-menu-bar-button-text'>Rules</div>
      </Button>
      <Button
        id='menu-list-button-more'
        className='expense-review-menu-bar-more'
        onClick={onMoreClick}
        sx={{ '& .MuiButton-startIcon': { marginRight: 0 } }}
        startIcon={<MoreCircle className='expense-review-menu-bar-button-icon' size={24} color={colorPrimary} />}
      >
        <div className='expense-review-menu-bar-button-text'>More</div>
      </Button>
      <Menu
        id='menu-list'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMoreClose}
        MenuListProps={{
          'aria-labelledby': 'menu-list-button-more'
        }}
        PaperProps={{
          sx: {
            borderColor: colorNeutralMetallica,
            borderRadius: '8px',
            borderStyle: 'solid',
            borderWidth: '1px',
            padding: '8px'
          },
          elevation: 0,
          className: 'expense-review-menu-bar-menu'
        }}
      >
        <MenuItem onClick={onMoreClose} type='link'>
          <BankIcon className='expense-review-menu-bar-icon' /> Link another account
        </MenuItem>
        <MenuItem onClick={onMoreClose} type='export' disabled={isExporting}>
          <ExportCurve className='expense-review-menu-bar-icon' /> Export
        </MenuItem>
        {isPreSubmit && (
          <MenuItem onClick={onMoreClose} type='tax-profile'>
            <Math className='expense-review-menu-bar-icon' />
            Tax estimate
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state) => ({
  year: expensesYearSelector(state),
  isExporting: isExportingSelector(state),
  sidebarComponent: sidebarComponentSelector(state)
});

const mapDispatchToProps = {
  setExpenseReviewModalType,
  handleExportWriteOffs,
  setIsExporting,
  setSidebarComponent,
  setSelfUnlockModalOpen,
  updateMerchantNameFilter,
  setMerchantNameSearch,
  setMerchantNameFilterQuery
};

const ConnectedExpenseReviewMenuBar = connect(mapStateToProps, mapDispatchToProps)(ExpenseReviewMenuBar);

export default ConnectedExpenseReviewMenuBar;
