import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  SLUG__CREDIT_ADD_MORE,
  SLUG__INCOME_ADD_MORE
} from '@app/src//taxflow/sections/special/constants/specialConstants';
import { allTaxYearExpensesSelector } from '@app/src/api/expensesApi';
import {
  CATEGORY_TYPE_OPTIONS,
  CATEGORY_TYPE_TAXFLOW_CALENDAR,
  CATEGORY_TYPE_TAXFLOW_MULTI_OPTION
} from '@app/src/constants/constants';
import { isW2OnlySelector } from '@app/src/selectors/dashboardSelectors';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import { jobCategoriesSelector } from '@app/src/selectors/onboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import {
  TAXFLOW__FORM_UPLOAD_FAILED_STATES,
  TAXFLOW__FORM_UPLOAD_PENDING_STATES,
  TAXFLOW__FORM_UPLOAD_VALID_STATES
} from '@app/src/taxflow/collection/constants/formUploadConstants';
import {
  bulkUploadItemsSelector,
  formUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { allQuestionsSelector, questionSelector, yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { getDoneUpdates, getQuestionUpdates, getStartedUpdates } from '@app/src/taxflow/main/utils/mainUtils';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import {
  COLLECTION_TYPE__REVIEW,
  DEFAULT_COLLECTION_ID,
  ENDPOINT_ATTRIBUTE__REVIEW_STARTED
} from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  queryResultsSelector,
  currentQuestionSelector,
  currentAnswerSelector,
  currentCollectionIdSelector,
  irsPaymentAccountSelector,
  queryResultsMapSelector,
  cachedNavigationItemsSelector,
  statusSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import { showWarningMessages } from '@app/src/taxflow/shared/utils/warningUtils';
import defaultCaptureMessage from '@app/src/utils/sentry/defaultCaptureMessage';
import { isQuestionPresent } from '@app/src/utils/taxValidationUtils';

export const currentUploadsSelector = (state) => _.get(state, ['taxFlow', 'currentUploads']);
export const uploadErrorSelector = (state) => _.get(state, ['taxFlow', 'uploadError']);
export const businessCodeSelector = (state) => _.get(state, ['taxFlow', 'businessCode']);
export const isQuestionnaireFlowSelector = (state) => _.get(state, ['taxFlow', 'isBulkUploadFlow']);
export const questionWrapperSelector = (state) => _.get(state, ['taxFlow', 'questionWrapper']);
export const currentGetQuestionAnswerLoadingSlugSelector = (state) =>
  _.get(state, ['taxFlow', 'getQuestionAnswerLoadingSlug']);

export const stateTaxSlugsSelector = createSelector([cachedNavigationItemsSelector], (navigationItems) => {
  const stateSection = navigationItems.find((item) => item.id === 'state');
  return _.get(stateSection, ['meta', 'states']);
});

export const isReviewModeSelector = createSelector([queryResultsSelector], (queryResults) => {
  const queryResult = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__REVIEW,
    collectionId: DEFAULT_COLLECTION_ID,
    slug: ENDPOINT_ATTRIBUTE__REVIEW_STARTED
  });

  return _.get(queryResult, ['answer', 'value']) === '1';
});

export const responseTextsSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    isW2OnlySelector,
    irsPaymentAccountSelector,
    allQuestionsSelector,
    yearSelector
  ],
  (
    currentQuestion,
    currentAnswer,
    queryResults,
    currentCollectionId,
    isW2Only,
    irsPaymentAccount,
    allQuestions,
    year
  ) => {
    let response_texts = [];
    let has_warning = false;
    let warning_message = _.get(currentQuestion, ['question_meta', 'warningMessage']);

    if (
      currentQuestion.question_type === CATEGORY_TYPE_OPTIONS ||
      currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_CALENDAR
    ) {
      const relatedOption = currentQuestion.question_meta.find(
        (option) => `${option.value}` === `${currentAnswer.value}`
      );
      if (relatedOption && relatedOption.response_text) {
        response_texts = [relatedOption.response_text];
      } else if (relatedOption && relatedOption.warning_message) {
        response_texts = [relatedOption.warning_message];
        has_warning = true;
      }
    } else if (currentQuestion.question_type === CATEGORY_TYPE_TAXFLOW_MULTI_OPTION) {
      const relatedOptions = _.filter(
        currentQuestion.question_meta,
        (option) => _.get(currentAnswer, ['value'], []).indexOf(`${option.value}`) !== -1
      );

      response_texts = relatedOptions.map((option) => option.response_text);
    } else if (
      showWarningMessages({
        question: currentQuestion,
        answer: currentAnswer,
        props: { queryResults, currentCollectionId, allQuestions },
        year
      })
    ) {
      response_texts = [warning_message];
      has_warning = true;
    }

    // if the sub-question has a warning, we don't need to show it at the form level
    response_texts = _.filter(response_texts, (response_text) => !_.isNil(response_text));

    return {
      warningTexts: response_texts,
      isWarningMessage: has_warning
    };
  }
);

export const collectionUploadStatusSelector = createSelector(
  [currentUploadsSelector, formUploadAttemptsSelector, currentQuestionSelector, currentCollectionIdSelector],
  (currentUploads, formUploadAttemptsMap, currentQuestion, currentCollectionId) => {
    const currentCollectionUpload = currentUploads.find(
      (upload) =>
        upload.collection_type === currentQuestion.collectionType && upload.collection_id === currentCollectionId
    );
    /* newUploadStatus reflects the status of the upload to AWS s3. A status of 'pending' means that the user is
       currently uploading a file */
    const newUploadStatus = currentCollectionUpload ? currentCollectionUpload.status : '';
    if (newUploadStatus === 'pending') {
      return { status: 'pending' };
    } else if (newUploadStatus === 'deleted') {
      return null;
    }

    const existingUpload = _.get(formUploadAttemptsMap, [currentQuestion.collectionType, `${currentCollectionId}`]);
    const existingUploadStatus = _.get(existingUpload, 'status');

    if (TAXFLOW__FORM_UPLOAD_PENDING_STATES.includes(existingUploadStatus)) {
      return { status: 'pending', originalStatus: existingUploadStatus };
    } else if (TAXFLOW__FORM_UPLOAD_VALID_STATES.includes(existingUploadStatus)) {
      return { status: 'success', originalStatus: existingUploadStatus };
    } else if (TAXFLOW__FORM_UPLOAD_FAILED_STATES.includes(existingUploadStatus)) {
      return {
        status: 'failed',
        errorMessage: _.get(existingUpload, 'errorMessage'),
        originalStatus: existingUploadStatus
      };
    } else {
      return null;
    }
  }
);

export const multiOptionExplanationSelector = createSelector(
  [currentQuestionSelector, currentAnswerSelector],
  (question, answer) => {
    if (question.type !== 'multi-option') return;

    const options = _.get(question, 'question_meta', []);

    const optionsWithExplanation = _.filter(options, 'explanation');

    const selectedAnswers = new Set(_.get(answer, 'value', []));

    return _.get(
      optionsWithExplanation.find((option) => selectedAnswers.has(_.get(option, 'value'))),
      'explanation'
    );
  }
);

export const incomeFreelanceFormSelector = createSelector(
  [queryResultsMapSelector, currentCollectionIdSelector],
  (queryResultsMap, currentCollectionId) =>
    _.get(queryResultsMap, [INCOME_COLLECTION_TYPES.FREELANCE, currentCollectionId, INCOME_SLUGS.FREELANCE_1099_TYPE])
);

export const isHeaderHiddenSelector = createSelector(
  [questionSelector, isQuestionnaireFlowSelector],
  (question, isQuestionnaireFlow) => {
    if (question?.header_style === 'hidden') {
      return !isQuestionnaireFlow || !question?.question_meta?.showClarifyingQuestionHeader;
    }
    return false;
  }
);

export const isFormMissingHeaderSelector = createSelector([currentQuestionSelector], (question) => {
  if (_.isArray(question.sub_question) && !_.isEmpty(question.sub_question) && _.isEmpty(question.title)) {
    return true;
  }
  return false;
});

export const firstFormQuestionSlugSelector = createSelector(
  [currentQuestionSelector, currentAnswerSelector, statusSelector],
  (question, answer, status) => {
    return _.chain(question)
      .get('sub_question')
      .find((subQuestion) => isQuestionPresent({ currentQuestion: question, question: subQuestion, answer, status }))
      .get('slug')
      .value();
  }
);

export const formUploadCollectionTypesSelector = createSelector([bulkUploadItemsSelector], (bulkUploadItems) =>
  _.chain(bulkUploadItems).map('collectionType').uniq().value()
);

/**
 * Should the current question render with a wider body on desktop? (in contrast to the typical narrow view for most questions)
 */
export const renderWideDesktopContentSelector = createSelector([currentQuestionSelector], (currentQuestion) => {
  return _.includes([SLUG__INCOME_ADD_MORE, SLUG__CREDIT_ADD_MORE], currentQuestion.slug);
});

export const isExpensesYearEqualToCurrentTaxYearSelector = createSelector(
  [expensesYearSelector, yearSelector],
  (expensesYear, year) => {
    return Number(expensesYear) === Number(year);
  }
);

export const expensesLinkDataSelector = (state, subquestion = null) =>
  createSelector([currentQuestionSelector, allTaxYearExpensesSelector], (currentQuestion, expenses) => {
    // To handle subquestions on forms, do a coalesce on the subquestion from props with the currentQuestion selector value
    const question = subquestion || currentQuestion;

    // Throw an error if no categoryIds were specified in Contentful
    if (!question.question_meta?.categoryIds?.length) {
      defaultCaptureMessage('Could not get categoryIds from question meta in Contentful');
    }

    const filteredExpenses = expenses.filter(
      (e) => question.question_meta.categoryIds.includes(e.keeper_category_id) && e.status === 'yes'
    );
    return {
      sum: _.round(_.sumBy(filteredExpenses, 'amount'), 2),
      count: filteredExpenses.length,
      categoryIds: question.question_meta.categoryIds
    };
  })(state);

export const subscriptionModalShowSelector = (state) => _.get(state, ['taxFlow', 'subscriptionModalShow']);

/** Gets tax data including any pending updates */
export const allTaxDataUpdatesSelector = createSelector(
  [
    currentQuestionSelector,
    currentAnswerSelector,
    queryResultsSelector,
    currentCollectionIdSelector,
    userSelector,
    jobCategoriesSelector,
    businessCodeSelector,
    bulkUploadItemsSelector,
    stateTaxSlugsSelector,
    statusSelector
  ],
  (
    question,
    answer,
    queryResults,
    collectionId,
    user,
    jobCategories,
    businessCode,
    bulkUploadItems,
    stateTaxSlugs,
    filingStatus
  ) => {
    if (_.isEmpty(question) || _.isEmpty(answer)) {
      return [];
    }

    return [
      ...getStartedUpdates({
        question,
        collectionId,
        stateTaxSlugs,
        queryResults
      }),
      ...getQuestionUpdates({
        question,
        answer,
        queryResults,
        collectionType: question.collectionType,
        collectionId,
        user,
        jobCategories,
        businessCode,
        bulkUploadItems,
        filingStatus
      }),
      ...getDoneUpdates({
        question,
        collectionId
      })
    ];
  }
);

export const changedEndpointAttributesSelector = (state) => _.get(state, ['taxFlow', 'changedEndpointAttributes']);
export const currentlyFocusedEndpointAttributeSelector = (state) =>
  _.get(state, ['taxFlow', 'currentlyFocusedEndpointAttribute']);
