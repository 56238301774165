import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import SettingsModalContainer from '@app/src/Components/Settings/Modals/SettingsModalContainer';
import SettingsFooter from '@app/src/Components/Settings/SettingsFooter';
import SettingsLinkedAccounts from '@app/src/Components/Settings/SettingsLinkedAccounts';
import SettingsLoginCredentials from '@app/src/Components/Settings/SettingsLoginCredentials';
import SettingsNotifications from '@app/src/Components/Settings/SettingsNotifications';
import SettingsSubscription from '@app/src/Components/Settings/SettingsSubscription';
import StripeProvider from '@app/src/Components/Stripe/StripeProvider';
import { trackActivity } from '@app/src/services/analyticsService';
import { loadSettings } from '@app/src/services/settingsService';
import TaxFlowNavigationPanel from '@app/src/taxflow/navigation/components/TaxFlowNavigationPanel';
import '@app/src/Components/Settings/SettingsContainer.scss';

const SettingsContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    trackActivity('navigation: settings');

    dispatch(loadSettings());
  }, [dispatch]);

  return (
    <StripeProvider>
      <TaxFlowNavigationPanel />
      <div className='settings settings-container'>
        <div className='left-spacer' />
        <div className='settings-content'>
          <Switch>
            <Route path='/settings' component={SettingsLinkedAccounts} exact />
            <Route path='/settings/linked-accounts' component={SettingsLinkedAccounts} />
            <Route path='/settings/notifications' component={SettingsNotifications} />
            <Route path='/settings/login-credentials' component={SettingsLoginCredentials} />
            <Route path='/settings/subscription' component={SettingsSubscription} />
          </Switch>
          <SettingsFooter />
        </div>
        <SettingsModalContainer />
      </div>
    </StripeProvider>
  );
};

export default SettingsContainer;
