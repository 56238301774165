import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetFeaturesQuery } from '@app/src/api/profileApi';
import { useUIState } from '@app/src/hooks/useUIState';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

/** Derive whether the user is able to file an extension manually. Extensions may be filed when
 *
 * 1. The user is in ui state 'bulk', 'bulk-started' or 'questionnaire'
 * AND
 * 2. The manual extension filing feature is enabled
 */
const useIsManualExtensionFilingEnabled = () => {
  const year = useSelector(yearSelector);
  const { isManualExtensionFilingFeatureEnabled, isLoading: isLoadingFeatures } = useGetFeaturesQuery(undefined, {
    selectFromResult: ({ data: features, ...other }) => ({
      isManualExtensionFilingFeatureEnabled: _.some(features, {
        name: `ty${year}-manual-extension-filing`,
        value: 1
      }),
      ...other
    })
  });
  const { uiState, isLoading: isUIStateLoading } = useUIState({ taxYear: year });
  const isManualExtensionFilingEnabled =
    isManualExtensionFilingFeatureEnabled &&
    _.includes(['bulk', 'bulk-started', 'questionnaire'], _.get(uiState, 'slug'));

  return { isLoading: isLoadingFeatures || isUIStateLoading, isManualExtensionFilingEnabled };
};

export default useIsManualExtensionFilingEnabled;
