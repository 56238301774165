import React, { useState } from 'react';
import _ from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import ExpenseReviewFilterSelectCategory from '@app/src/Components/ExpenseReview/ExpenseReviewFilterSelectCategory';
import { ExpenseReviewTableChevronFilterIcon as ExpenseReviewTableChevronFilterIcon } from '@app/src/Components/ExpenseReview/ExpenseReviewTableChevronFilterIcon.jsx';
import { colorPrimary, colorPrimaryAlt, colorSurface } from '@app/src/theme';

const ExpenseReviewFilterSelect = ({
  className,
  value,
  filterSelected,
  onChange,
  label,
  filterOptions,
  menuItemValueKey,
  menuItemDisplayKey
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasOptions = filterOptions.length > 0;

  const onClick = () => {
    if (hasOptions) {
      setIsOpen(!isOpen);
    }
  };

  if (label === 'Category') {
    return (
      <ExpenseReviewFilterSelectCategory
        {...{
          className,
          value,
          filterSelected,
          onChange,
          filterOptions,
          menuItemValueKey,
          menuItemDisplayKey
        }}
      />
    );
  }

  return (
    <Select
      multiple
      className={className}
      displayEmpty
      open={isOpen}
      style={{ padding: 0, fontSize: '14px', fontWeight: 700 }}
      value={value}
      onChange={onChange}
      onClick={onClick}
      sx={{
        boxShadow: 'none',
        '& .MuiOutlinedInput-notchedOutline': { border: 0 },
        '&:hover .MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
        '& .MuiSelect-select.MuiOutlinedInput-input.MuiInputBase-input': {
          paddingRight: '4px',
          cursor: hasOptions ? 'pointer' : 'default'
        },
        cursor: hasOptions ? 'pointer' : 'default'
      }}
      input={<OutlinedInput variant='table-input' />}
      // IconComponent is required - removing it would show the default MUI icon.
      // We set a custom icon here to utilize MUI's default right-aligned positioning.
      // This is a workaround where we render a component that returns null since
      // the necessary props aren't passed to it.
      IconComponent={() => <ExpenseReviewTableChevronFilterIcon />}
      // The actual icon is rendered via renderValue to keep it adjacent to the label
      renderValue={(props) => (
        <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {label}
          <ExpenseReviewTableChevronFilterIcon
            hasOptions={hasOptions}
            filterSelected={filterSelected}
            isOpen={isOpen}
            {...props}
          />
        </span>
      )}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      {filterOptions.map((option) => (
        <MenuItem
          key={_.get(option, menuItemValueKey)}
          value={_.get(option, menuItemValueKey)}
          sx={{
            '&.Mui-selected': { backgroundColor: colorPrimaryAlt, color: colorSurface },
            '&.Mui-selected:hover': { backgroundColor: colorPrimary, color: colorSurface }
          }}
        >
          {_.get(option, menuItemDisplayKey)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ExpenseReviewFilterSelect;
