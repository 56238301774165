import React from 'react';
import _ from 'lodash';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import TaxValidationMaskedInputField from '@app/src/Components/TaxValidation/TaxValidationMaskedInputField';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';

const TaxFlowFormSSNItem = ({ question, answer, replaceStrings, onChange, onFocus = _.noop, onBlur = _.noop }) => {
  const handleChange = (value) => {
    value = value.replace(/\D/g, '');
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  return (
    <div className='form-group'>
      <TaxValidationLabel
        question={question}
        answer={answer}
        title={replaceStrings(question.title)}
        htmlFor={question.slug}
      />
      <div className='tax-flow-input-wrap'>
        <TaxValidationMaskedInputField
          question={question}
          answer={answer}
          mask='###-##-####'
          name={question.slug}
          id={question.slug}
          className='form-control'
          placeholder={question.question_meta.default}
          required={question.required}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={answer && !_.isNil(answer.value) ? answer.value : ''}
        />
        <TaxFlowInfoButton currentQuestion={question} />
      </div>
      <TaxValidationWarnings question={question} answer={answer} />
    </div>
  );
};

export default TaxFlowFormSSNItem;
