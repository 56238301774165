import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useGetBulkUploadPillsQuery } from '@app/src/api/taxDataApi';
import { dropzoneLoadingSelector } from '@app/src/taxflow/main/selectors/formUploadSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useIsBulkUploadInProgress = () => {
  const year = useSelector(yearSelector);
  const isDropzoneLoading = useSelector(dropzoneLoadingSelector);
  const [debouncedIsDropzoneLoading, setDebouncedIsDropzoneLoading] = useState(isDropzoneLoading);

  const { data: bulkUploadPills, isFetching: areBulkUploadPillsBeingFetched } = useGetBulkUploadPillsQuery({ year });

  useEffect(() => isDropzoneLoading && setDebouncedIsDropzoneLoading(true), [isDropzoneLoading]);
  // Debounce to account for the time it takes between isDropzoneLoading -> false and for bulkUploadPills to re-fetch
  useEffect(() => {
    if (!areBulkUploadPillsBeingFetched && !isDropzoneLoading && debouncedIsDropzoneLoading) {
      setDebouncedIsDropzoneLoading(false);
    }
  }, [areBulkUploadPillsBeingFetched, debouncedIsDropzoneLoading, isDropzoneLoading]);

  const isParseInProgress = _.some(bulkUploadPills, { loading: true });
  const isBulkUploadInProgress = isParseInProgress || debouncedIsDropzoneLoading;

  return isBulkUploadInProgress;
};
