import { useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useUpdateOnesignalDataTagsMutation } from '@app/src/api/profileApi';
import { useGetUIStageQuery } from '@app/src/api/taxDataApi';
import { useUIState } from '@app/src/hooks/useUIState';
import { userSelector } from '@app/src/selectors/userSelectors';
import { setUserWithObj } from '@app/src/services/analyticsService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useReportUserTaxStage = () => {
  const year = useSelector(yearSelector);
  const { phone } = useSelector(userSelector);
  const { uiState, isLoading: isUIStateLoading } = useUIState({ taxYear: year });
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const [updateOnesignalDataTags] = useUpdateOnesignalDataTagsMutation();

  const taxStageFieldName = `taxstagety${year % 1000}`;
  const uiStageFieldName = `ui_stage_ty${year}`;

  const taxStage = _.get(uiState, 'slug');

  useEffect(() => {
    if (isUIStateLoading || uiStageLoading || _.isNil(phone)) {
      return;
    }
    const taxStageDataTags = { [taxStageFieldName]: taxStage };
    const uiStageDataTags = _.isNil(uiStage) ? {} : { [`ui_stage_ty${year}`]: uiStage };
    updateOnesignalDataTags({ dataTags: { ...taxStageDataTags, ...uiStageDataTags } });
    setUserWithObj(phone, {
      [taxStageFieldName]: taxStage
    });
  }, [
    taxStageFieldName,
    uiStageFieldName,
    taxStage,
    uiStage,
    phone,
    year,
    isUIStateLoading,
    uiStageLoading,
    updateOnesignalDataTags
  ]);
};
