import React, { useMemo } from 'react';
import _ from 'lodash';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';
import { ArrowDown2 } from 'iconsax-react';
import 'odometer/themes/odometer-theme-default.css';
import ReactOdometer from 'react-odometerjs';
import { useToggle } from 'react-use';
import { LoadingContext } from '@app/src/Components/TaxProfile/LoadingContext';
import TaxEstimateDetailsRow from '@app/src/Components/TaxProfile/TaxEstimateDetailsRow';
import { colorSecondaryAlt2, colorSecondaryHover } from '@app/src/theme';
import '@app/src/Components/TaxProfile/TaxEstimateDetails.scss';

const TaxEstimateDetails = ({ taxEstimate, isLoading, totalDeductions }) => {
  const [on, toggle] = useToggle(false);

  const unknownAdjustments =
    taxEstimate?.federal?.totalIncome -
    taxEstimate?.federal?.deductions?.scheduleC -
    taxEstimate?.federal?.selfEmploymentTax / 2 -
    taxEstimate?.federal?.adjustedGrossIncome;

  return (
    <LoadingContext.Provider value={isLoading}>
      <div className='tax-estimate-details-container'>
        <div className='tax-estimate-details-summary'>
          <div className='amounts'>
            <TaxAmountSummary
              label={taxEstimate?.federal?.taxesOwed > 0 ? 'Est. federal tax bill' : 'Est. federal refund'}
              value={taxEstimate?.federal?.taxesOwed}
            />
            {!!taxEstimate?.state && (
              <TaxAmountSummary
                label={
                  taxEstimate?.state?.taxesOwed > 0
                    ? `Est. ${taxEstimate?.state?.stateAbbreviation} tax bill`
                    : `Est. ${taxEstimate?.state?.stateAbbreviation} refund`
                }
                value={taxEstimate?.state?.taxesOwed}
              />
            )}
          </div>
          <button onClick={toggle}>
            <span>{on ? 'Hide details' : 'Show details'}</span>
            <ArrowDown2
              size={16}
              className={classNames('arrow-icon', {
                open: on
              })}
            />
          </button>
        </div>
        <Collapse in={on} sx={{ marginTop: 0 }}>
          <TaxEstimateDetailsRow label='Total income' value={taxEstimate?.federal?.totalIncome} />
          <TaxEstimateDetailsRow
            label='Business deductions'
            value={totalDeductions ? -totalDeductions : -taxEstimate?.federal?.deductions?.scheduleC}
            valueStyles={{
              padding: '0 8px',
              backgroundColor: colorSecondaryAlt2,
              color: colorSecondaryHover,
              fontWeight: 500,
              borderRadius: 4,
              marginRight: -8
            }}
          />
          {taxEstimate?.federal?.selfEmploymentTax > 0 && (
            <TaxEstimateDetailsRow
              label='Self employment tax deduction'
              value={-taxEstimate?.federal?.selfEmploymentTax / 2}
            />
          )}
          {unknownAdjustments > 0 && (
            <TaxEstimateDetailsRow
              label='Other deductions'
              value={
                -(
                  taxEstimate?.federal?.totalIncome -
                  taxEstimate?.federal?.deductions?.scheduleC -
                  taxEstimate?.federal?.selfEmploymentTax / 2 -
                  taxEstimate?.federal?.adjustedGrossIncome
                )
              }
            />
          )}
          <hr />
          <TaxEstimateDetailsRow label='Adjusted gross income' value={taxEstimate?.federal?.adjustedGrossIncome} />
          <TaxEstimateDetailsRow label='Standard deduction' value={-taxEstimate?.federal?.zeroBracketAmount} />
          <TaxEstimateDetailsRow label='QBI deductions' value={-taxEstimate?.federal?.otherDeductions} />
          <hr />
          <TaxEstimateDetailsRow label='Taxable income' value={taxEstimate?.federal?.taxableIncome} />
          <hr />
          <TaxEstimateDetailsRow label='Federal tax liability' value={taxEstimate?.federal?.totalTaxLiability} />
          {!!taxEstimate?.state && (
            <TaxEstimateDetailsRow
              label={(() => {
                if (taxEstimate?.state?.stateAbbreviation) {
                  return `${taxEstimate?.state?.stateAbbreviation} tax liability`;
                }

                return 'State tax liability';
              })()}
              value={taxEstimate?.state?.totalTaxLiability}
            />
          )}
          <TaxEstimateDetailsRow label='Credits' value={-taxEstimate?.federal?.creditsApplied} />
          <TaxEstimateDetailsRow label='Taxes withheld' value={-taxEstimate?.federal?.totalTaxesWithheld} />
          <TaxEstimateDetailsRow label='Quarterly tax payments' value={-taxEstimate?.federal?.quarterlyTaxPayments} />
          <hr />
          <TaxEstimateDetailsRow
            label={taxEstimate?.federal?.taxesOwed > 0 ? 'Estimated federal tax bill' : 'Estimated federal refund'}
            value={taxEstimate?.federal?.taxesOwed}
            fontWeight={500}
            signDisplay='never'
          />
          {!!taxEstimate?.state && (
            <TaxEstimateDetailsRow
              label={(() => {
                if (!taxEstimate?.state?.stateAbbreviation) {
                  return 'Estimated state refund';
                }

                if (taxEstimate?.state?.taxesOwed > 0) {
                  return `Estimated ${taxEstimate?.state?.stateAbbreviation} tax bill`;
                }

                return `Estimated ${taxEstimate?.state?.stateAbbreviation} refund`;
              })()}
              value={taxEstimate?.state?.taxesOwed ?? 0}
              fontWeight={500}
              signDisplay='never'
            />
          )}
        </Collapse>
      </div>
    </LoadingContext.Provider>
  );
};

const TaxAmountSummary = ({ label, value }) => {
  const odometerValue = useMemo(() => {
    if (_.isNil(value)) {
      return 0;
    }

    return Math.abs(Math.round(value));
  }, [value]);

  return (
    <div className='tax-amount-summary'>
      <div className='label'>{label}</div>
      <div
        className={classNames('value-container', {
          refund: value <= 0
        })}
      >
        <span className='currency-symbol'>$</span>
        <ReactOdometer value={odometerValue} format='(,ddd)' duration={500} className='value' />
      </div>
    </div>
  );
};

export default TaxEstimateDetails;
