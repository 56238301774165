import React from 'react';
import _ from 'lodash';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';

const TaxFlowFormMoneyItem = (props) => {
  const { question, answer, overrideCollectionId, onChange, onFocus = _.noop, onBlur = _.noop } = props;

  const handleChange = (value) => {
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  return (
    <div className='form-group'>
      <TaxFlowMoneyItem
        {...props}
        isChildQuestion
        currentQuestion={question}
        currentAnswer={answer}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        overrideCollectionId={overrideCollectionId}
      />
    </div>
  );
};

export default TaxFlowFormMoneyItem;
