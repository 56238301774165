import React from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import TaxValidationWarnings from '@app/src/Components/TaxValidation/TaxValidationWarnings';
import { setCurrentAnswer } from '@app/src/services/taxFlowAnswerService';
import '@app/src/Components/TaxFlow/Question/TaxFlowSliderItem.scss';

const DEFAULT_STEP_SIZE = 5;

const TaxFlowSliderItem = ({
  currentQuestion,
  currentAnswer,
  isChildQuestion,
  onChange,
  onFocus = _.noop,
  onBlur = _.noop,
  setCurrentAnswer,
  resultLoading
}) => {
  const handleChange = (value) => {
    value = parseInt(value);

    if (isChildQuestion) {
      onChange({
        value
      });
    } else {
      setCurrentAnswer({
        value
      });
    }
  };

  if (resultLoading) return null;

  const defaultValue =
    currentQuestion.question_meta && !_.isNil(currentQuestion.question_meta.default)
      ? currentQuestion.question_meta.default
      : 50;

  const step = _.get(currentQuestion, ['question_meta', 'step'], DEFAULT_STEP_SIZE);

  const value = currentAnswer && !_.isNil(currentAnswer.value) ? currentAnswer.value : defaultValue;
  const max = _.get(currentQuestion, ['question_meta', 'max']);

  const sliderValue = _.isNaN(value) ? 0 : value;

  return (
    <div
      className={classNames({
        'steps-body': !isChildQuestion
      })}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs='auto' className='tax-flow-slider-item-container'>
          <NumberFormat
            id={currentQuestion.slug}
            className='tax-flow-slider-item-number-format'
            customInput={TextField}
            format='###%'
            size='small'
            onValueChange={(e) => handleChange(e.value)}
            onFocus={onFocus}
            onBlur={onBlur}
            value={value}
            inputProps={{ style: { textAlign: 'center' } }}
            isAllowed={({ floatValue }) => {
              if (max) {
                return !floatValue || floatValue <= max;
              }
              return true;
            }}
          />
        </Grid>
        <Grid item xs>
          <Slider step={step} value={sliderValue} onChange={(e) => handleChange(e.target.value)} />
        </Grid>
      </Grid>
      <TaxValidationWarnings question={currentQuestion} answer={currentAnswer} />
    </div>
  );
};

const mapStateToProps = (state, { question, answer, isChildQuestion }) => ({
  currentQuestion: isChildQuestion ? question : _.get(state, ['taxFlow', 'currentQuestion']),
  currentAnswer: isChildQuestion ? answer : _.get(state, ['taxFlow', 'currentAnswer'])
});

const mapDispatchToProps = {
  setCurrentAnswer
};

const ConnectedTaxFlowSliderItem = connect(mapStateToProps, mapDispatchToProps)(TaxFlowSliderItem);

export default ConnectedTaxFlowSliderItem;
