import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import HelpSupportIcon from '@app/src/assets/HelpSupportIcon';
import { currentAnswerSelector, currentQuestionSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowListItem.scss';

const TaxFlowListItem = ({
  item,
  checked,
  handleChange,
  showRightIcon,
  handleRightIconPress,
  rightIcon,
  isWarning,
  ready = true,
  children,
  className,
  onFocus = _.noop,
  onBlur = _.noop,
  question,
  answer
}) => {
  const currentQuestion = useSelector(currentQuestionSelector);
  const currentAnswer = useSelector(currentAnswerSelector);

  return (
    <button
      className={classNames(
        'tax-flow-list-item',
        { 'tax-flow-list-item-checked': checked },
        { 'tax-flow-list-item-warning': isWarning },
        { 'tax-flow-list-item-not-ready': !ready },
        className
      )}
      onFocus={onFocus}
      onClick={async () => {
        await handleChange(item, !checked);
        onBlur({ question: question ?? currentQuestion, answer: answer ?? currentAnswer });
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          await handleChange(item, !checked);
          onBlur({ question: question ?? currentQuestion, answer: answer ?? currentAnswer });
        }
      }}
      type='button'
      aria-pressed={checked}
    >
      <div className='tax-flow-list-item-content'>
        <div className={classNames('tax-flow-list-item-text', { 'tax-flow-list-item-text-checked': checked })}>
          {children}
        </div>
      </div>
      {showRightIcon ? (
        rightIcon ? (
          rightIcon
        ) : (
          <HelpSupportIcon className='tax-flow-list-item-icon' onClick={() => handleRightIconPress(item)} />
        )
      ) : null}
    </button>
  );
};

export default TaxFlowListItem;
