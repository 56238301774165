import React, { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { ArrowIconForDropdown } from '@app/src/Components/Common/DropDown/ArrowIconForDropdown';

class DropDown extends Component {
  state = {
    current: '',
    options: []
  };

  componentDidMount() {
    const { value, options } = this.props;
    this.setState({ current: value, options });
  }

  componentWillReceiveProps(props) {
    const { value, options } = props;
    this.setState({ current: value, options });
  }

  /**
   * @description On select change
   * @param {Object} e
   */
  onSelectChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.props.onChange(value);
    });
  };

  render() {
    const { current, options } = this.state;
    const { label, defaultText, showDefault, isBankLink, inputProps, style, sx, stopPropagation } = this.props;
    return (
      <div>
        {label !== undefined && label !== '' && <InputLabel>Browser Select</InputLabel>}
        <Select
          className={classNames({ 'bank-link': isBankLink })}
          native
          value={current}
          onChange={this.onSelectChange}
          onClick={stopPropagation ? (e) => e.stopPropagation() : null}
          style={style}
          sx={sx}
          inputProps={inputProps}
          IconComponent={ArrowIconForDropdown}
        >
          {showDefault && defaultText !== undefined && defaultText !== '' && <option value=''>{defaultText}</option>}
          {showDefault && defaultText === undefined && <option value=''>Choose</option>}
          {options.map((d, i) =>
            d && d.value ? (
              <option value={d.value} key={i}>
                {d.text || d.value}
              </option>
            ) : (
              <option value={d} key={i}>
                {d}
              </option>
            )
          )}
        </Select>
      </div>
    );
  }
}

export default DropDown;
