import { useSelector } from 'react-redux';
import { useGetUIStageQuery } from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { UI_STAGE__CONFIRM_AMOUNTS } from '@app/src/taxflow/shared/constants/sharedConstants';

const useIsInConfirmAmounts = () => {
  const year = useSelector(yearSelector);
  const { data: uiStage, isLoading: uiStageLoading } = useGetUIStageQuery({ year });
  const isInConfirmAmounts = uiStage === UI_STAGE__CONFIRM_AMOUNTS;
  return { data: isInConfirmAmounts, isLoading: uiStageLoading };
};

export default useIsInConfirmAmounts;
