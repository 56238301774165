import {
  TAX_VALIDATION__SET_ERRORS,
  TAX_VALIDATION__SET_CURRENT_PAGE_HAS_NEW_ERROR
} from '@app/src/constants/taxValidationConstants';

const initialState = {
  errors: [],
  currentPageHasNewError: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TAX_VALIDATION__SET_ERRORS: {
      return {
        ...state,
        errors: action.payload
      };
    }

    case TAX_VALIDATION__SET_CURRENT_PAGE_HAS_NEW_ERROR: {
      return {
        ...state,
        currentPageHasNewError: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
