import React from 'react';
import { useSelector } from 'react-redux';
import ExpenseReviewModalAdd from '@app/src/Components/ExpenseReview/ExpenseReviewModalAdd';
import ExpenseReviewModalAutoRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalAutoRule';
import ExpenseReviewModalBulkNote from '@app/src/Components/ExpenseReview/ExpenseReviewModalBulkNote';
import ExpenseReviewModalBulkSplit from '@app/src/Components/ExpenseReview/ExpenseReviewModalBulkSplit';
import ExpenseReviewModalEdit from '@app/src/Components/ExpenseReview/ExpenseReviewModalEdit';
import ExpenseReviewModalEditRule from '@app/src/Components/ExpenseReview/ExpenseReviewModalEditRule';
import SubscriptionFlow from '@app/src/Components/Subscription/SubscriptionFlow';
import { EXPENSE_REVIEW_MODAL_TYPES } from '@app/src/constants/dashboardConstants';
import { expenseReviewModalTypeSelector } from '@app/src/selectors/expenseReviewSelectors';

const ExpenseReviewModalContent = () => {
  const modalType = useSelector(expenseReviewModalTypeSelector);

  switch (modalType) {
    case EXPENSE_REVIEW_MODAL_TYPES.ADD:
      return <ExpenseReviewModalAdd />;
    case EXPENSE_REVIEW_MODAL_TYPES.EDIT:
      return <ExpenseReviewModalEdit />;
    case EXPENSE_REVIEW_MODAL_TYPES.RULE:
      return <ExpenseReviewModalEditRule />;
    case EXPENSE_REVIEW_MODAL_TYPES.AUTO_RULE:
      return <ExpenseReviewModalAutoRule />;
    case EXPENSE_REVIEW_MODAL_TYPES.BULK_SPLIT:
      return <ExpenseReviewModalBulkSplit />;
    case EXPENSE_REVIEW_MODAL_TYPES.BULK_NOTE:
      return <ExpenseReviewModalBulkNote />;
    case EXPENSE_REVIEW_MODAL_TYPES.PAYWALL:
      return <SubscriptionFlow />;
    case EXPENSE_REVIEW_MODAL_TYPES.EXPORT_PAYWALL:
      return <SubscriptionFlow />;
    default:
      return null;
  }
};

export default ExpenseReviewModalContent;
