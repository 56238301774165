import axios from 'axios';
import { TAGS } from '@app/src/api/baseApi';
import { getPaid } from '@app/src/api/plaidApi';
import profileApi from '@app/src/api/profileApi';
import { updateTaxData } from '@app/src/api/taxDataApi';
import { serverUrl } from '@app/src/global/Environment';
import { freeTaxFilingSelector } from '@app/src/selectors/workSelectors';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
  ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import { DEFAULT_COLLECTION_ID } from '@app/src/taxflow/shared/constants/sharedConstants';

const baseUrl = serverUrl();

export const updateReadyToFile = () => async (dispatch, getState) => {
  await axios.post(`${baseUrl}api/taxes/update-ready-to-file`);
  const year = yearSelector(getState());
  await dispatch(profileApi.util.invalidateTags([{ type: TAGS.UI_STAGE, id: year }]));
};

export const markSubscriptionPromptAsSeen = () => async (dispatch, getState) => {
  const year = yearSelector(getState());
  await dispatch(
    updateTaxData({
      taxData: [
        {
          coll_type: COLLECTION_TYPE__SUBMIT_SUBSCRIPTION_PROMPT,
          coll_id: DEFAULT_COLLECTION_ID,
          slug: ENDPOINT_ATTRIBUTE__SUBMIT_SUBSCRIPTION_PROMPT,
          value: '1'
        }
      ],
      year
    })
  );
};

export const getTaxFilingPaid = () => async (dispatch, getState) => {
  const isFree = freeTaxFilingSelector(getState());
  const paid = await dispatch(getPaid());
  return paid || isFree;
};
