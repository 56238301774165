import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { updateCategoryFilter } from '@app/src/services/expenseReviewService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__EXPENSES } from '@app/src/taxflow/shared/constants/sharedConstants';

/**
 * Universal navigation hook that handles platform-specific navigation (web vs native)
 *
 * For example, when linking to expenses, we accept a link with schema `/${PATH_COMPONENT__EXPENSES}?categoryIds=[...]}`
 * In web, we want to set the provided category ids in state, and navigate to PATH_COMPONENT__EXPENSES.
 * In native, we want to navigate to the deductions tab and pass the category ids to the native filter
 */
export const useDeepNavigation = () => {
  const history = useHistory();
  const year = useSelector(yearSelector);
  const dispatch = useDispatch();

  return ({ path }) => {
    const [basePath, queryString] = path.split('?');
    const searchParams = new URLSearchParams(queryString);

    if (basePath === `/${PATH_COMPONENT__EXPENSES}`) {
      const expenseCategoryIds = _.defaultTo(JSON.parse(searchParams.get('categoryIds')), []);
      if (isReactNative()) {
        sentMsgToReactNative('home', {
          year,
          categoryIds: expenseCategoryIds
        });
        return;
      } else {
        const params = new URLSearchParams();
        params.append('year', year);
        if (!_.isEmpty(expenseCategoryIds)) {
          dispatch(updateCategoryFilter(expenseCategoryIds));
        }
        history.push(`/${PATH_COMPONENT__EXPENSES}?${params}`);
        return;
      }
    }
    history.push(path);
  };
};
