import React from 'react';
import { useSelector } from 'react-redux';
import LinkedAccountLoader from '@app/src/Components/Bank/LinkedAccountLoader/LinkedAccountLoader';
import BankLink from '@app/src/Components/BankLink/BankLink';
import AddIcon from '@app/src/assets/add.svg?react';
import BankIcon from '@app/src/assets/bank-account.svg?react';
import CreditCardIcon from '@app/src/assets/card.svg?react';
import { useTransactionsSyncInProgress } from '@app/src/hooks/useTransactionsSyncInProgress';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import '@app/src/Components/BankLinkOptions/BankLinkOptions.scss';

/**
 * @typedef {'disableWhileSyncing' | 'showLoader'} SyncBehaviorType
 */

/**
 * Bank link options component
 *
 * @param {Object} props
 * @param {string} [props.title] - Optional title
 * @param {string} [props.subTitle] - Optional subtitle
 * @param {string} props.origin - Origin of the bank link
 * @param {SyncBehaviorType} [props.syncBehavior='showLoader'] - Controls syncing behavior
 */
export const BankLinkOptions = ({ title, subTitle, origin, syncBehavior = 'showLoader' }) => {
  const year = useSelector(yearSelector);
  const { syncInProgress, isLoading: isSyncInProgressLoading } = useTransactionsSyncInProgress({ year });
  const shouldShowLoader = syncBehavior === 'showLoader' && syncInProgress && !isSyncInProgressLoading;
  return (
    <div className='bank-link-options'>
      {title && <div className='bank-link-options-header'>{title}</div>}
      {subTitle && <div className='bank-link-options-description'>{subTitle}</div>}
      {shouldShowLoader ? (
        <LinkedAccountLoader className='bank-link-options-loader' />
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
          <BankLink
            icon={<BankIcon />}
            className='bank-link-options-link-button bank-link-options-link-button-1'
            fullWidth={false}
            type={'bankLinkOptions'}
            styles={{ justifyContent: 'space-between', flex: 1, minWidth: 250 }}
            origin={origin}
            disableWhileSyncing={syncBehavior === 'disableWhileSyncing'}
          >
            <span>Checking accounts</span>
            <AddIcon />
          </BankLink>
          <BankLink
            icon={<CreditCardIcon />}
            fullWidth={false}
            className='bank-link-options-link-button bank-link-options-link-button-2'
            type={'bankLinkOptions'}
            styles={{ justifyContent: 'space-between', flex: 1, minWidth: 250 }}
            origin={origin}
            disableWhileSyncing={syncBehavior === 'disableWhileSyncing'}
          >
            <span>Credit card</span>
            <AddIcon />
          </BankLink>
        </div>
      )}
    </div>
  );
};
