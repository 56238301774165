import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { PATHNAME_ONBOARDING__SITUATIONS } from '@app/src/constants/onboardingConstants';
import { initialLoadSelector } from '@app/src/selectors/holisticOnboardingSelectors';

const OnboardingInitial = () => {
  const { search } = useLocation();

  const isInitialLoad = useSelector(initialLoadSelector);

  return isInitialLoad ? (
    <Skeleton width='100%' height={40} variant='rounded' animation='pulse' />
  ) : (
    <Redirect to={PATHNAME_ONBOARDING__SITUATIONS + search} />
  );
};

export default OnboardingInitial;
