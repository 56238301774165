import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isReactNative } from '@app/src/global/Helpers';
import { isWebviewFocusedSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';

/**
 * Is the current (tax filing) page in focus?
 *
 * - on native - true whenever taxes tab in-focus
 * - on web - true whenever page is visible
 */
export const useIsFocused = () => {
  const isWebviewFocused = useSelector(isWebviewFocusedSelector);
  const [isPageVisible, setIsPageVisible] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isReactNative() ? isWebviewFocused : isPageVisible;
};
