import _ from 'lodash';
import moment from 'moment';
import { REFERRER_ID_STORAGE_KEY, REFERRER_STORAGE_KEY } from '@app/src/constants/constants';
import {
  Url_FACEBOOK_CLICK_ID_STORAGE_KEY,
  Url_GOOGLE_CLICK_ID_STORAGE_KEY,
  Url_TIKTOK_STORAGE_KEY
} from '@app/src/constants/onboardingConstants';
import { formatPhoneNumberToDisplay, getCookie } from '@app/src/global/Helpers';
import {
  emailSelector,
  firstNameSelector,
  incomeTypeSelector,
  jobCategoriesSelector,
  jobsSelector,
  marriedSelector,
  phoneSelector
} from '@app/src/selectors/holisticOnboardingSelectors';
import { userSelector } from '@app/src/selectors/userSelectors';
import { generateClientDedupId, getAnalyticsTrait, trackActivity } from '@app/src/services/analyticsService';
import { parseExperimentProperties, parseSignupExperiments } from '@app/src/services/onboardingExperimentService';

export const getMonthsToStartOfLastYear = () => {
  const currentMonth = moment().add(1, 'month').startOf('month');
  const beginningOfLastYear = moment().subtract(1, 'year').startOf('year');

  const months = [];

  while (beginningOfLastYear.isBefore(currentMonth, 'month')) {
    const text = beginningOfLastYear.format('YYYY - MMMM');
    months.push(text);
    beginningOfLastYear.add(1, 'month');
  }

  return months.reverse();
};

export const consolidateContentfulValues = (contentful) => {
  const { items } = contentful;

  return items.reduce((acc, { fields }) => {
    const url = fields.url.replace('/h', '');
    const nextUrl = fields.nextUrl?.replace('/h', '');

    const formattedFields = { ...fields, url, nextUrl };

    return { ...acc, [url]: formattedFields };
  }, {});
};

export const getWorkAsVerb = (incomeTypes) => {
  if (incomeTypes.includes('contractor') && incomeTypes.includes('owner')) {
    return 'business / freelancing';
  } else if (incomeTypes.includes('owner')) {
    return 'business';
  } else {
    return '1099 / freelancing';
  }
};

const getWorkAsNoun = (incomeTypes, hasArticle, isPlural) => {
  if (incomeTypes.includes('contractor') && incomeTypes.includes('owner')) {
    return (hasArticle ? 'a ' : '') + 'freelancer / business owner' + (isPlural ? 's' : '');
  } else if (incomeTypes.includes('owner')) {
    return (hasArticle ? 'a ' : '') + 'business owner' + (isPlural ? 's' : '');
  } else {
    return (hasArticle ? 'a ' : '') + 'freelancer' + (isPlural ? 's' : '');
  }
};

/**
 * Replaces placeholders in the provided string with appropriate values based on the user's job and context.
 *
 * Job prioritization:
 * 1. Use the first non-custom job (contentful job) if it exists.
 * 2. If only custom jobs are available, fallback to custom job logic.
 * 3. Handle mixed cases by prioritizing contentful jobs over custom jobs.
 *
 * @param {string} value - The string containing placeholders to be replaced.
 * @returns {string} - The string with placeholders replaced.
 */
export const getStringSubstitutions =
  (value = '') =>
  (dispatch, getState) => {
    if (!value) return value;

    const localStorage = JSON.parse(getLocalStorage('onboardingState')) || {};

    const jobCategories = jobCategoriesSelector(getState());
    const jobs = jobsSelector(getState()) || _.get(localStorage, 'jobs', []);
    const incomeType = incomeTypeSelector(getState()) || _.get(localStorage, 'incomeType', []);
    const isMarried = _.get(localStorage, 'married', marriedSelector(getState())) === 'married';

    // Separate contentful (non-custom) jobs and custom jobs
    const contentfulJob = jobs.find((job) => jobCategories.some((category) => category.slug === job.slug));
    const customJob = jobs.find((job) => !jobCategories.some((category) => category.slug === job.slug));

    // Prioritize contentful job, fallback to custom job if none exists
    const selectedJob = contentfulJob || customJob;

    // Only use job name if single job and not custom
    const isCustomJobOnly = (jobs.length === 1 && customJob) || jobs.length > 1;

    // Define job info with fallback for custom jobs
    const jobInfo = contentfulJob
      ? jobCategories.find((category) => category.slug === contentfulJob.slug || category.name === contentfulJob.name)
      : {
          name: customJob?.name,
          carRelatedDescription: 'driving to clients, conferences, or training.',
          homeOfficeDescription: 'managing finances and paperwork',
          travelHelpText: 'attending an industry conference, training, or workshop',
          whyMightThisPersonGetABusinessMeal: 'Any meaningful business conversation counts.'
        };

    // Helper function to fetch job details or fallback for custom jobs
    const getJobDetail = (field) => jobInfo[field] || '';

    // incomeType jobs selectedJob isMarried
    const replacements = {
      '{incomeTypeAsNoun}s': getWorkAsNoun(incomeType, false, true),
      '{incomeTypeAsNoun}': getWorkAsNoun(incomeType, true, false),
      '{incomeTypeAsVerb}': getWorkAsVerb(incomeType),
      '{workAsNoun}': isCustomJobOnly ? getWorkAsNoun(incomeType) : selectedJob?.name?.toLowerCase(),
      '{workAsNounWithArticle}': isCustomJobOnly
        ? getWorkAsNoun(incomeType, true, false)
        : _.get(jobInfo, 'jobNameWithArticle', ''),
      '{workAsVerb}': isCustomJobOnly ? getWorkAsVerb(incomeType) : _.get(jobInfo, 'jobAsVerb', ''),
      '{dynamicYouAnd}': isMarried ? 'you and your spouse' : 'you',
      '{dynamicI}': isMarried ? 'I / my spouse' : 'I',
      '{dynamicYouOr}': isMarried ? 'you or your spouse' : 'you',
      '{carRelatedDescription}': getJobDetail('carRelatedDescription'),
      '{homeOfficeDescription}': getJobDetail('homeOfficeDescription'),
      '{travelHelpText}': getJobDetail('travelHelpText'),
      '{whyMightThisPersonGetABusinessMeal}': getJobDetail('whyMightThisPersonGetABusinessMeal'),
      '{contentfulTypeJobs}': userSelector(getState()).contentfulTypeJobs,
      '{firstName}': firstNameSelector(getState()),
      '{displayId}': formatPhoneNumberToDisplay(phoneSelector(getState())) || emailSelector(getState())
    };

    return Object.entries(replacements).reduce(
      (text, [pattern, replacement]) => text.replaceAll(pattern, replacement || ''),
      value
    );
  };

export const getLocalStorage = (key) => localStorage.getItem(key);
const setLocalStorage = (key, value) => localStorage.setItem(key, value);
export const clearOnboardingState = () => localStorage.removeItem('onboardingState');

export const preserveProgress = (answer, analyticsInfo) => {
  const onboardingState = JSON.parse(getLocalStorage('onboardingState')) || {};

  setLocalStorage('onboardingState', JSON.stringify({ ...onboardingState, ...answer }));

  trackActivity('question: answer', {
    flow: 'onboarding',
    type: 'holistic-onboarding',
    ...analyticsInfo
  });
};

export const getExperimentProperties = (experiments) => {
  const experimentProperties = parseExperimentProperties(experiments);
  const signupExperiments = parseSignupExperiments(experimentProperties.experiments);

  return {
    experiments: signupExperiments
  };
};

export const getMarketingData = () => {
  const referrer = getCookie(REFERRER_STORAGE_KEY);
  const referrer_id = getCookie(REFERRER_ID_STORAGE_KEY);
  const fbc = getAnalyticsTrait(Url_FACEBOOK_CLICK_ID_STORAGE_KEY);
  const gclid = getAnalyticsTrait(Url_GOOGLE_CLICK_ID_STORAGE_KEY);
  const ttclid = getAnalyticsTrait(Url_TIKTOK_STORAGE_KEY);
  const client_dedup_id = generateClientDedupId();

  return {
    referrer,
    referrer_id,
    client_dedup_id,
    ...(fbc && { fbc }),
    ...(ttclid && { ttclid }),
    ...(gclid && { gclid })
  };
};
