import React from 'react';
import _ from 'lodash';
import TaxFlowInfoButton from '@app/src/Components/TaxFlow/Common/TaxFlowInfoButton';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';

const TaxFlowFormInitialItem = ({ question, answer, replaceStrings, onChange, onFocus = _.noop, onBlur = _.noop }) => {
  const handleChange = (value) => {
    value = value.toUpperCase();
    onChange({ slug: question.slug, value, endpoint_attr: question.endpoint_attr });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  return (
    <div>
      <div className='form-group'>
        <TaxValidationLabel
          question={question}
          answer={answer}
          title={replaceStrings(question.title)}
          htmlFor={question.slug}
        />
        <div className='tax-flow-input-wrap'>
          <TaxValidationInput
            question={question}
            answer={answer}
            mask={[/[a-zA-Z]/]}
            type='text'
            name={question.slug}
            id={question.slug}
            className='form-control'
            placeholder={question.question_meta.default}
            required={question.required}
            onChange={(e) => handleChange(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={answer && !_.isNil(answer.value) ? answer.value : ''}
          />
          <TaxFlowInfoButton currentQuestion={question} />
        </div>
      </div>
    </div>
  );
};

export default TaxFlowFormInitialItem;
