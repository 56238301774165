import { useSelector } from 'react-redux';
import { deductionsSelector } from '@app/src/selectors/expenseReviewSelectors';

const useTaxEstimateDetails = ({ year, taxEstimateResults }) => {
  const totalDeductions = useSelector(deductionsSelector);

  return {
    year,
    isLoading: taxEstimateResults.isLoading || taxEstimateResults.isUninitialized || taxEstimateResults.error,
    taxEstimate: taxEstimateResults.data,
    totalDeductions
  };
};

export default useTaxEstimateDetails;
