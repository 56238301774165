import React, { useCallback } from 'react';
import Divider from '@mui/material/Divider';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import OutlinedButton from '@app/src/Components/Common/OutlinedButton';
import ExpenseReviewCategoryList from '@app/src/Components/ExpenseReview/ExpenseReviewCategoryList';
import { setSidebarComponent } from '@app/src/actions/expenseReviewActions';
import { isPreSubmitSelector, useGetUIStageQuery } from '@app/src/api/taxDataApi';
import { useCalculateTaxEstimateQuery } from '@app/src/api/taxProfileApi';
import { expensesYearSelector } from '@app/src/selectors/expenseReviewSelectors';
import '@app/src/Components/ExpenseReview/ExpenseReviewSidebarSavingsInfo.scss';

const ExpenseReviewSidebarSavingsInfo = () => {
  const dispatch = useDispatch();

  const year = useSelector(expensesYearSelector);

  const openTaxEstimate = useCallback(() => {
    dispatch(setSidebarComponent('TaxProfile'));
  }, [dispatch]);

  const { isPreSubmit } = useGetUIStageQuery(
    { year },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          isPreSubmit: isPreSubmitSelector(result)
        };
      }
    }
  );

  const { taxEstimateLabel } = useCalculateTaxEstimateQuery(
    {
      year
    },
    {
      selectFromResult: (result) => {
        return {
          ...result,
          taxEstimateLabel: selectTaxEstimateLabel(result)
        };
      }
    }
  );

  return (
    <>
      <h1 className='text-xl bold'>Deductions by category ({year})</h1>
      <div>
        <Divider />
      </div>
      <div className='savings-info-section'>
        <div className='savings-info-item savings-info-item-content-column'>
          <ExpenseReviewCategoryList />
        </div>
      </div>
      {isPreSubmit && (
        <OutlinedButton onClick={openTaxEstimate} sx={{ width: '100%' }}>
          {taxEstimateLabel}
        </OutlinedButton>
      )}
    </>
  );
};

const selectTaxEstimateLabel = createSelector(
  (result) => result.isSuccess,
  (result) => result.data,
  (isSuccess, taxEstimate) => {
    if (!isSuccess) {
      return 'See impact on tax estimate';
    }

    const federalTaxesOwed = taxEstimate?.federal?.taxesOwed;
    const stateTaxesOwed = taxEstimate?.state?.taxesOwed ?? 0;
    const overallTaxesOwed = federalTaxesOwed + stateTaxesOwed;

    if (federalTaxesOwed > 0 && stateTaxesOwed > 0) {
      return 'See impact on total tax bill';
    }

    if (federalTaxesOwed <= 0 && stateTaxesOwed <= 0) {
      return 'See impact on total tax refund';
    }

    if (overallTaxesOwed > 0) {
      return 'See impact on net tax bill';
    }

    return 'See impact on net tax refund';
  }
);

export default ExpenseReviewSidebarSavingsInfo;
