import {
  SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES,
  SET_HOLISTIC_ONBOARDING_DEPENDENTS,
  SET_HOLISTIC_ONBOARDING_DRAWER_OPEN,
  SET_HOLISTIC_ONBOARDING_DRIVE,
  SET_HOLISTIC_ONBOARDING_EMAIL,
  SET_HOLISTIC_ONBOARDING_ERROR,
  SET_HOLISTIC_ONBOARDING_FIRST_NAME,
  SET_HOLISTIC_ONBOARDING_HOME,
  SET_HOLISTIC_ONBOARDING_HOME_OWNER,
  SET_HOLISTIC_ONBOARDING_INCOME_TYPE,
  SET_HOLISTIC_ONBOARDING_INITIAL_LOAD,
  SET_HOLISTIC_ONBOARDING_JOB,
  SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES,
  SET_HOLISTIC_ONBOARDING_LAST_NAME,
  SET_HOLISTIC_ONBOARDING_LOADING,
  SET_HOLISTIC_ONBOARDING_MARRIED,
  SET_HOLISTIC_ONBOARDING_MEAL,
  SET_HOLISTIC_ONBOARDING_PHONE,
  SET_HOLISTIC_ONBOARDING_RENT,
  SET_HOLISTIC_ONBOARDING_START_DATE,
  SET_HOLISTIC_ONBOARDING_STUDENT_LOANS,
  SET_HOLISTIC_ONBOARDING_TRAVEL,
  SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED,
  SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS
} from '@app/src/actions/types';

export const setHolisticOnboardingLoading = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_LOADING,
  payload
});

export const setHolisticOnboardingError = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_ERROR,
  payload
});

export const setHolisticOnboardingInitialLoad = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_INITIAL_LOAD,
  payload
});

export const setHolisticOnboardingContentfulValues = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_CONTENTFUL_VALUES,
  payload
});

export const setHolisticOnboardingJobCategories = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_JOB_CATEGORIES,
  payload
});

export const setHolisticOnboardingDrawerOpen = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_DRAWER_OPEN,
  payload
});

export const setHolisticOnboardingFirstName = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_FIRST_NAME,
  payload
});

export const setHolisticOnboardingLastName = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_LAST_NAME,
  payload
});

export const setHolisticOnboardingEmail = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_EMAIL,
  payload
});

export const setHolisticOnboardingPhone = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_PHONE,
  payload
});

export const setHolisticOnboardingMarried = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_MARRIED,
  payload
});

export const setHolisticOnboardingDependents = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_DEPENDENTS,
  payload
});

export const setHolisticOnboardingRent = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_RENT,
  payload
});

export const setHolisticOnboardingStudentLoans = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_STUDENT_LOANS,
  payload
});

export const setHolisticOnboardingHomeOwner = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_HOME_OWNER,
  payload
});

export const setHolisticOnboardingIncomeType = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_INCOME_TYPE,
  payload
});

export const setHolisticOnboardingJob = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_JOB,
  payload
});

export const setHolisticOnboardingStartDate = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_START_DATE,
  payload
});

export const setHolisticOnboardingDrive = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_DRIVE,
  payload
});

export const setHolisticOnboardingHome = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_HOME,
  payload
});

export const setHolisticOnboardingMeal = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_MEAL,
  payload
});

export const setHolisticOnboardingTravel = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_TRAVEL,
  payload
});

export const setHolisticOnboardingWebviewBankLinked = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_WEBVIEW_BANK_LINKED,
  payload
});

export const setOnboardingExperiments = (payload) => ({
  type: SET_HOLISTIC_ONBOARDING_ONBOARDING_EXPERIMENTS,
  payload
});

export const clearError = () => setHolisticOnboardingError(null);
