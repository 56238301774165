import React from 'react';
import _ from 'lodash';
import TaxFlowMoneyItem from '@app/src/Components/TaxFlow/Question/TaxFlowMoneyItem';
import TaxValidationInput from '@app/src/Components/TaxValidation/TaxValidationInput';
import TaxValidationLabel from '@app/src/Components/TaxValidation/TaxValidationLabel';
import '@app/src/Components/TaxFlow/Form/TaxFlowFormBox14Item.scss';

const TaxFlowFormBox14Item = ({ question, answer, replaceStrings, onChange, onFocus = _.noop, onBlur = _.noop }) => {
  const handleCodeChange = (e) => {
    onChange({
      slug: question.slug,
      value: { ..._.get(answer, 'value'), code: e.target.value },
      endpoint_attr: question.endpoint_attr
    });
  };

  const handleAmountChange = (value) => {
    onChange({
      slug: question.slug,
      value: { ..._.get(answer, 'value'), amount: value }
    });
  };

  const handleFocus = () => {
    onFocus({ endpoint_attr: question.endpoint_attr });
  };

  const handleBlur = () => {
    onBlur({ question, answer });
  };

  const codeQuestion = {
    ...question,
    question_meta: {
      ...question.question_meta,
      default: _.get(question, ['question_meta', 'defaultCode']),
      maxLength: 8
    }
  };
  const codeAnswer = { value: _.get(answer, ['value', 'code'], '') };

  const amountQuestion = {
    ...question,
    header_style: 'hidden',
    question_meta: {
      ...question.question_meta,
      default: _.get(question, ['question_meta', 'defaultAmount']),
      // Accounts for the extra characters from formatting with currency. Max 8 digits.
      maxLength: 12
    }
  };
  const amountAnswer = { value: _.get(answer, ['value', 'amount'], '') };

  return (
    <div className='taxflow-form-box-14-item form-group'>
      <TaxValidationLabel question={question} answer={answer} title={replaceStrings(question.title)} />
      <div className='taxflow-form-box-14-item-row'>
        <div className='taxflow-form-box-14-item-field'>
          <TaxValidationInput
            className='taxflow-form-box-14-item-input'
            question={codeQuestion}
            answer={answer}
            placeholder={_.get(codeQuestion, ['question_meta', 'default'])}
            value={_.get(codeAnswer, 'value')}
            onChange={handleCodeChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </div>
        <TaxFlowMoneyItem
          className='taxflow-form-box-14-item-field'
          isChildQuestion
          currentQuestion={amountQuestion}
          currentAnswer={amountAnswer}
          inputMode='decimal'
          decimalScale={2}
          onChange={handleAmountChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
};

export default TaxFlowFormBox14Item;
