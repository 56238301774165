import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Confetti from 'react-confetti';
import { connect, useSelector } from 'react-redux';
import TaxFlowFeedbackModal from '@app/src/Components/TaxFlow/Common/TaxFlowFeedbackModal';
import TaxFlowRatingFeedbackStar from '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackStar';
import { setTaxFlowHoverRating, setFeedbackModalShow, setTaxFlowRating } from '@app/src/actions/taxFlowActions';
import { useGetTaxFeedbackQuery, useUpdateTaxFeedbackMutation } from '@app/src/api/taxDataApi';
import { isReactNative, sentMsgToReactNative } from '@app/src/global/Helpers';
import { trackActivity } from '@app/src/services/analyticsService';
import { sendRatingNotification } from '@app/src/services/taxFlowService';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { ratingSelector, hoverRatingSelector } from '@app/src/taxflow/shared/selectors/sharedSelectors';
import '@app/src/Components/TaxFlow/Question/TaxFlowRatingFeedbackElement.scss';

const TaxFlowRatingFeedbackElement = ({
  rating,
  hoverRating,
  setTaxFlowHoverRating,
  setFeedbackModalShow,
  setTaxFlowRating
}) => {
  const [feedbackSavedCount, setFeedbackSavedCount] = useState(0);
  const [hasRequestedAppStoreRating, setHasRequestedAppStoreRating] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const year = useSelector(yearSelector);
  const { data: recordedRating } = useGetTaxFeedbackQuery({ year });
  const [setTaxFlowFeedback] = useUpdateTaxFeedbackMutation();

  useEffect(() => {
    setTaxFlowRating(recordedRating?.rating);
  }, [recordedRating, setTaxFlowRating]);

  const hasRating = rating > 0;
  const visibleRating = hoverRating || rating;
  const feedbackSavedWord = feedbackSavedCount < 2 ? 'recorded' : 'updated';
  const placeholderText = 'What could have been better?';
  const ratingMessage = {
    1: 'Terrible!',
    2: "Didn't like it",
    3: 'It was okay',
    4: 'Pretty good',
    5: 'It was great!'
  };

  const shouldShowFeedbackText = hasRating;

  useEffect(() => {
    const saveRating = async () => {
      await setTaxFlowFeedback({ rating, text: recordedRating?.comment, year });
    };
    if (hasRating && rating !== recordedRating?.rating) {
      saveRating();
    }

    if (rating === 5 && recordedRating?.rating !== 5) {
      setShowConfetti(true);
      if (!isReactNative()) {
        trackActivity('tax filing: reviews modal opened');
        setFeedbackModalShow(true);
      } else if (!hasRequestedAppStoreRating) {
        sentMsgToReactNative('tax_rating');
        setHasRequestedAppStoreRating(true);
      }
    }
  }, [
    rating,
    hasRating,
    shouldShowFeedbackText,
    hasRequestedAppStoreRating,
    setFeedbackModalShow,
    setTaxFlowFeedback,
    recordedRating,
    year
  ]);

  const sendVerbatimFeedback = async (e) => {
    const text = e.target.value;
    if (text && text !== recordedRating?.comment) {
      trackActivity('filing feedback: verbatim', { rating, text });
      await sendRatingNotification(text, rating);
      await setTaxFlowFeedback({ rating, text, year });
      setFeedbackSavedCount(feedbackSavedCount + 1);
    }
  };

  return (
    <div className='taxflow-rating-feedback-element'>
      <h3>Rate your tax filing experience</h3>
      <div className='taxflow-rating-feedback-element-star-group' onMouseLeave={() => setTaxFlowHoverRating(0)}>
        {_.range(1, 6).map((val) => {
          return (
            <TaxFlowRatingFeedbackStar
              starValue={val}
              key={val}
              isFilled={visibleRating >= val}
              className='taxflow-rating-feedback-element-star'
            />
          );
        })}
      </div>
      {hasRating && <div className='taxflow-rating-feedback-element-star-message'>{ratingMessage[visibleRating]}</div>}
      {shouldShowFeedbackText && (
        <TextareaAutosize
          className='form-control'
          rows='5'
          maxLength='1000'
          placeholder={placeholderText}
          id='feedback'
          onBlur={sendVerbatimFeedback}
          defaultValue={recordedRating?.comment}
        ></TextareaAutosize>
      )}
      {feedbackSavedCount > 0 && (
        <p className='taxflow-rating-feedback-element-confirmation'>
          Your feedback was {feedbackSavedWord} - thank you!
        </p>
      )}
      <TaxFlowFeedbackModal />
      {showConfetti && (
        <Confetti
          numberOfPieces={1000}
          recycle={false}
          onConfettiComplete={(confetti) => {
            confetti.reset();
            setShowConfetti(false);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rating: ratingSelector(state),
  hoverRating: hoverRatingSelector(state)
});

const mapDispatchToProps = {
  setTaxFlowHoverRating,
  setFeedbackModalShow,
  setTaxFlowRating
};

const ConnectedTaxFlowRatingFeedbackElement = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxFlowRatingFeedbackElement);

export default ConnectedTaxFlowRatingFeedbackElement;
