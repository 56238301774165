import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import {
  useGenerateQuestionnaireMutation,
  useGetBulkUploadPillsQuery,
  useGetQuestionnaireProgressQuery
} from '@app/src/api/taxDataApi';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';
import { PATH_COMPONENT__TAX_HOME } from '@app/src/taxflow/sections/special/constants/specialConstants';
import { TAXFLOW_BASE_URL } from '@app/src/taxflow/shared/constants/sharedConstants';

/**
 * Re-generate the questionnaire when the user adds / removes forms in bulk upload, if the questionnaire is not yet started
 */
export const useRegenerateQuestionnaire = () => {
  const { pathname } = useLocation();
  const year = useSelector(yearSelector);
  const [isRegenerationQueued, setIsRegenerationQueued] = useState(false);
  const { data: bulkUploadPills } = useGetBulkUploadPillsQuery({ year });
  const { data: questionnaireProgress } = useGetQuestionnaireProgressQuery({
    year
  });
  const [generateQuestionnaire] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const isTaxHome = pathname === `/${TAXFLOW_BASE_URL}/${PATH_COMPONENT__TAX_HOME}`;

  const isParseInProgress = _.some(bulkUploadPills, { loading: true });
  const isQuestionnaireComplete = questionnaireProgress?.questionnaireComplete;

  useEffect(() => {
    if (isRegenerationQueued && !isQuestionnaireComplete && !isParseInProgress && isTaxHome) {
      generateQuestionnaire({ year });
      setIsRegenerationQueued(false);
    }
  }, [
    bulkUploadPills,
    isParseInProgress,
    isQuestionnaireComplete,
    isTaxHome,
    isRegenerationQueued,
    year,
    generateQuestionnaire
  ]);

  useDeepCompareEffect(() => {
    // Don't regenerate on initial page load, but regenerate on subsequent bulk upload pills changes
    if (isInitialLoad) {
      setIsInitialLoad(false);
    } else {
      setIsRegenerationQueued(true);
    }
  }, [_.defaultTo(bulkUploadPills, [])]);
};
