import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FIXED_CACHE_KEYS } from '@app/src/api/baseApi';
import { useGenerateQuestionnaireMutation, useGetQuestionnaireSummaryPillsQuery } from '@app/src/api/taxDataApi';
import { useIsBulkUploadInProgress } from '@app/src/hooks/useIsBulkUploadInProgress';
import { yearSelector } from '@app/src/taxflow/main/selectors/mainSelectors';

export const useQuestionnaireLoading = ({ taxYear } = {}) => {
  const selectedYear = useSelector(yearSelector);
  const year = _.defaultTo(taxYear, selectedYear);

  const { isFetching: questionnaireSummaryPillsFetching } = useGetQuestionnaireSummaryPillsQuery({ year });
  const [, { isLoading: questionnaireGenerationInProgress }] = useGenerateQuestionnaireMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.GENERATE_QUESTIONNAIRE
  });
  const isBulkUploadInProgress = useIsBulkUploadInProgress();
  const questionnaireLoading =
    isBulkUploadInProgress || questionnaireSummaryPillsFetching || questionnaireGenerationInProgress;

  return { questionnaireLoading };
};
