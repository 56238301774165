import _ from 'lodash';
import { createSelector } from 'reselect';
import { CATEGORY_TYPE_OPTIONS, TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK } from '@app/src/constants/constants';
import { ONBOARDING_EXPERIMENTS_KEY, Url_SIGNUP_PHONE_ONLY } from '@app/src/constants/onboardingConstants';
import { bankListSelector } from '@app/src/selectors/bankSelectors';
import { signupLinkSkippableSelector } from '@app/src/selectors/userSelectors';

export const jobCategoriesSelector = (state) => _.get(state, ['onboarding', 'job_category']);
export const onboardingExperimentsSelector = (state) => _.get(state, ['onboarding', ONBOARDING_EXPERIMENTS_KEY]);
export const loadingSelector = (state) => _.get(state, ['onboarding', 'loading']);
export const updatingSelector = (state) => _.get(state, ['onboarding', 'updating']);
export const currentQuestionSelector = (state) => _.get(state, ['onboarding', 'currentQuestion']);
export const currentAnswerSelector = (state) => _.get(state, ['onboarding', 'currentAnswer']);
export const currentContextSelector = (state) => _.get(state, ['onboarding', 'currentContext']);

export const isOnboardingSkipEnabledSelector = createSelector(
  [currentQuestionSelector, signupLinkSkippableSelector, bankListSelector],
  (currentQuestion, signupLinkSkippable, bankList) => {
    if (currentQuestion.sysId === TAXFLOW_SYSTEM_QUESTION_TYPE_CONNECT_BANK) {
      return signupLinkSkippable && _.isEmpty(bankList);
    }

    return false;
  }
);

export const marketingContentIncomeTypesSelector = (state) =>
  _.get(state, ['onboarding', 'marketingContent', 'incomeTypes']);

export const onboardingEstimateTimeSelector = (state) =>
  _.get(state, ['onboarding', 'marketingContent', 'estimatedTimeChip']);

export const typeSelector = (state) => _.get(state, ['onboarding', 'type']);

export const normalizedQuestionsSelector = (state) => _.get(state, ['onboarding', 'normalizedQuestions']);

export const onboardingEmailSelector = createSelector([currentAnswerSelector], (answer) =>
  _.get(answer, ['value', 'signup-email', 'value'])
);

export const onboardingPhoneSelector = createSelector([currentAnswerSelector], (answer) => {
  const phone = _.get(answer, ['value', 'signup-phone-only-phone', 'value']);

  if (phone) {
    return phone.slice(1, 11);
  }
});

export const responseTextsSelector = createSelector(
  [currentQuestionSelector, currentAnswerSelector],
  (currentQuestion, currentAnswer) => {
    let response_texts = [];

    if (currentQuestion.question_type === CATEGORY_TYPE_OPTIONS) {
      const relatedOption = currentQuestion.question_meta.find(
        (option) => `${option.value}` === `${currentAnswer.value}`
      );
      if (_.get(relatedOption, 'response_text')) {
        response_texts = [relatedOption.response_text];
      }
    }

    return {
      warningTexts: response_texts
    };
  }
);

export const orderedJobCategoriesSelector = createSelector([jobCategoriesSelector], (jobCategories) => {
  const otherItem = jobCategories.find((category) => category.slug === 'other');
  return [
    ..._.chain(jobCategories)
      .sortBy((jobCategory) => jobCategory.name)
      .filter((jobCategory) => jobCategory.slug !== 'other')
      .value(),
    otherItem
  ];
});

export const topJobCategoriesSelector = createSelector([jobCategoriesSelector], (jobCategories) => {
  const slugs = ['graphic-designer', 'consultant', 'online-seller', 'healthcare-professional', 'real-estate-agent'];

  const slugToCategoryObj = jobCategories.reduce((acc, category) => {
    acc[category.slug] = category;
    return acc;
  }, {});

  return slugs.map((slug) => slugToCategoryObj[slug]);
});

export const isCreatingAccountSelector = createSelector(
  [updatingSelector, currentQuestionSelector],
  (updating, currentQuestion) => {
    const slug = _.get(currentQuestion, 'slug');

    if (slug === Url_SIGNUP_PHONE_ONLY && updating) {
      return true;
    }

    return false;
  }
);
