import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import { blockingModalPropsSelector } from '@app/src/selectors/navigationListSelectors';
import { setBlockingModalProps } from '@app/src/taxflow/navigation/actions/navigationActions';
import '@app/src/Components/TaxFlow/Common/TaxFlowBlockingModal.scss';

const TaxFlowBlockingModal = ({ blockingModalProps, setBlockingModalProps }) => {
  const { slug, open, title, body, iconUrl, submitButtonText, onSubmit } = blockingModalProps;
  const handleClose = () => {
    setBlockingModalProps({ ...blockingModalProps, open: false });
  };
  return (
    <Dialog open={open} onClose={handleClose} sx={{ '.MuiPaper-root': { maxWidth: '700px', borderRadius: '8px' } }}>
      <DialogTitle
        sx={{
          marginTop: 1.5,
          marginRight: 1.5,
          marginLeft: 1.5,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {iconUrl && <img className='taxflow-blocking-modal-icon' src={iconUrl} alt={`${slug}-icon`} />}
        <div>{title}</div>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            color: 'primary.main',
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ margin: 1 }}>
        <DialogContentText>{body}</DialogContentText>
        {onSubmit && (
          <DialogActions
            className='taxflow-blocking-modal-actions'
            sx={{ paddingTop: 1.5, paddingBottom: 0.5, paddingLeft: 0, paddingRight: 0 }}
          >
            <Button variant='outlined' type='button' onClick={handleClose} sx={{ marginRight: 1 }}>
              Cancel
            </Button>
            <Button variant='contained' type='button' onClick={onSubmit}>
              {submitButtonText}
            </Button>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  blockingModalProps: blockingModalPropsSelector(state)
});

const mapDispatchToProps = {
  setBlockingModalProps
};

const ConnectedTaxFlowBlockingModal = connect(mapStateToProps, mapDispatchToProps)(TaxFlowBlockingModal);

export default ConnectedTaxFlowBlockingModal;
