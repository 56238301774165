export const DASHBOARD2__SET_EXPENSES = 'DASHBOARD2__SET_EXPENSES';
export const DASHBOARD2__SET_CATEGORIES = 'DASHBOARD2__SET_CATEGORIES';

export const EXPENSE_REVIEW_MODAL_TYPES = {
  PAYWALL: 'paywall',
  ADD: 'add',
  RULE: 'rule',
  AUTO_RULE: 'auto-rule',
  EDIT: 'edit',
  BULK_SPLIT: 'bulk-split',
  BULK_NOTE: 'bulk-note',
  EXPORT_PAYWALL: 'export-paywall'
};
