import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@app/src/assets/close.svg?react';
import LampIcon from '@app/src/assets/lamp-on.svg?react';
import { dismissNonBlockingError } from '@app/src/services/taxFlowService';
import '@app/src/Components/TaxValidation/TaxValidationNonBlockingWarning.scss';

const TaxValidationNonBlockingWarning = ({ warningMessage, validationSlug, collectionType, collectionId }) => {
  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(dismissNonBlockingError({ validationSlug, collectionType, collectionId }));
  };

  return (
    <div className='validation-non-blocking-warning'>
      <div className='validation-non-blocking-warning-icon'>
        <LampIcon width={20} height={20} />
      </div>
      <div className='validation-non-blocking-warning-message'>{warningMessage}</div>
      <div className='validation-non-blocking-warning-close' onClick={onDismiss}>
        <CloseIcon width={24} height={24} />
      </div>
    </div>
  );
};

export default TaxValidationNonBlockingWarning;
