import _ from 'lodash';
import axios from 'axios';
import { getCategories, getExpenses } from '@app/src/actions/dashboardActions';
import { setBusinessCode, setCurrentAnswer, setAddMoreItems } from '@app/src/actions/taxFlowActions';
import { getAllExpenses } from '@app/src/api/expensesApi';
import { getCampaign, getFeatures } from '@app/src/api/profileApi';
import { getHomeAddressPreselectOptions, getJobs, getTaxData, updateTaxData } from '@app/src/api/taxDataApi';
import { CATEGORY_TYPE_ADD_MORE, CATEGORY_TYPE_TAXFLOW_FORM } from '@app/src/constants/constants';
import { serverUrl } from '@app/src/global/Environment';
import { getBankLinks, requireAccountDetails } from '@app/src/services/pricingService';
import { getWorkInfoLazy } from '@app/src/services/workService';
import { setCurrentCollectionId, setCurrentTaxState } from '@app/src/taxflow/collection/actions/collectionActions';
import { requireWork } from '@app/src/taxflow/common/services/userInfo';
import {
  bulkUploadItemsSelector,
  flattenedUploadAttemptsSelector
} from '@app/src/taxflow/main/selectors/formUploadSelectors';
import {
  allDerivedQuestionsSelector,
  allQuestionsSelector,
  savedDefaultAnswerSelector,
  yearSelector
} from '@app/src/taxflow/main/selectors/mainSelectors';
import {
  getDoneUpdates,
  get1099JobData,
  getQuestionById,
  getQuestionQueryResults,
  getQuestionUpdates,
  getStartedUpdates
} from '@app/src/taxflow/main/utils/mainUtils';
import { deserializeQuestionAnswer } from '@app/src/taxflow/mapping/utils/mappingUtils';
import { CAR_ENDPOINT_ATTRIBUTES, CAR_SLUGS, COLLECTION_TYPE__CAR } from '@app/src/taxflow/sections/car/carConstants';
import {
  SLUG__CREDIT_CHARITY_AMOUNT,
  SLUG__CREDIT_HOMEOWNER_DETAIL,
  SLUG__CREDIT_STANDARD_DEDUCTION,
  SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS,
  SLUG__CREDIT_STANDARD_RESULT
} from '@app/src/taxflow/sections/credit/constants/creditConstants';
import {
  COLLECTION_TYPE__HOME,
  HOME_ENDPOINT_ATTRIBUTES,
  HOME_SLUGS
} from '@app/src/taxflow/sections/home/homeConstants';
import { INCOME_COLLECTION_TYPES, INCOME_SLUGS } from '@app/src/taxflow/sections/income/incomeConstants';
import { SLUG__FIND_WRITE_OFFS } from '@app/src/taxflow/sections/special/constants/specialConstants';
import {
  SLUG__BULK_UPLOAD_QUESTIONS_MULTISTATE_INFO,
  SLUG__BULK_UPLOAD_QUESTIONS_STATE_INFO,
  SLUG__STATE_RETURN
} from '@app/src/taxflow/sections/state/constants/stateConstants';
import {
  SLUG__SUBMIT_CONFIRMATION,
  SLUG__SUBMIT_DEBIT
} from '@app/src/taxflow/sections/submit/constants/submitConstants';
import {
  setDeductions,
  setHomePriorDepreciation,
  setCarPriorDepreciation,
  setQueryResults
} from '@app/src/taxflow/shared/actions/sharedActions';
import { DEFAULT_COLLECTION_ID, STATE_TAX_COLL_TYPE_MAP } from '@app/src/taxflow/shared/constants/sharedConstants';
import {
  currentCollectionIdSelector,
  deductionsSelector,
  queryResultsSelector
} from '@app/src/taxflow/shared/selectors/sharedSelectors';
import { getQueryResultByEndpointAttribute } from '@app/src/taxflow/shared/utils/sharedUtils';
import defaultCaptureException from '@app/src/utils/sentry/defaultCaptureException';
import { notify } from '@app/src/utils/snackbarUtils';

const baseUrl = serverUrl();

// Gets a value by endpoint attribute and converts it to a number, coalescing to 0
const getNumericValue = ({ queryResults, collectionId, collectionType, slug }) =>
  Number(
    _.get(getQueryResultByEndpointAttribute({ queryResults, collectionType, collectionId, slug }), [
      'answer',
      'value'
    ]) || 0
  );

const getHomePriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const totalHomeSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_TOTAL
  });

  const workSpaceSize = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.OFFICE_SIZE_WORK_SPACE
  });

  const businessPercent = (workSpaceSize / totalHomeSize) * 100;

  const homePurchasePrice = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_PURCHASE_PRICE
  });

  const homeImprovements = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_IMPROVEMENTS
  });

  const valueOfLand = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.VALUE_LAND
  });

  const cost = homePurchasePrice + homeImprovements - valueOfLand;

  const yearsDepreciation = getNumericValue({
    queryResults,
    collectionId: currentCollectionId,
    collectionType: COLLECTION_TYPE__HOME,
    slug: HOME_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
  });

  const res = await axios.post(`${baseUrl}api/taxes/home-depreciation-calc`, {
    yearsDepreciation,
    businessPercent,
    cost
  });

  const { priorDepreciation } = res.data.data;
  dispatch(setHomePriorDepreciation(priorDepreciation));
};

export const getDeductions = () => async (dispatch, getState) => {
  const year = yearSelector(getState());
  const res = await axios.get(`${baseUrl}api/taxes/deductions`, { params: { year } });
  const data = _.get(res, ['data', 'data']);
  dispatch(setDeductions(data));
};

export const getNextCollectionId =
  ({ collectionType }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    const res = await axios.get(`${baseUrl}api/taxes/get-next-collection-id`, {
      params: { coll_type: collectionType, year }
    });
    const nextCollectionId = _.get(res, ['data', 'data', 'coll_id']);
    return nextCollectionId;
  };

const fetchAddMoreItems =
  ({ sectionSlug }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    const res = await axios.get(`${baseUrl}api/taxes/add-more-items`, { params: { sectionSlug, year } });
    const addMoreItems = _.get(res, ['data', 'data', 'addMoreItems']);
    dispatch(setAddMoreItems(addMoreItems));
  };

export const getQueryResults = () => async (dispatch, getState) => {
  try {
    const year = yearSelector(getState());
    const allQuestions = allDerivedQuestionsSelector(getState());

    const items = await dispatch(getTaxData({ year }));
    const queryResults = getQuestionQueryResults({ allQuestions, items, year });
    dispatch(setQueryResults(queryResults));
  } catch (e) {
    notify('Something went wrong. Please try again.');
    defaultCaptureException(e);
  }
};

export const getCurrentQuestionData =
  ({ currentQuestion }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    await Promise.all([
      dispatch(getCampaign()),
      dispatch(requireWork()),
      dispatch(requireAccountDetails()),
      dispatch(getFeatures()),
      dispatch(getWorkInfoLazy()),
      dispatch(getJobs()),
      dispatch(getAllExpenses({ year })),
      ...(currentQuestion.question_type === CATEGORY_TYPE_ADD_MORE
        ? [dispatch(fetchAddMoreItems({ sectionSlug: _.get(currentQuestion, ['question_meta', 'sectionSlug']) }))]
        : []),
      ...(currentQuestion.slug === SLUG__CREDIT_STANDARD_DEDUCTION ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_RESULT ||
      currentQuestion.slug === SLUG__CREDIT_HOMEOWNER_DETAIL ||
      currentQuestion.slug === SLUG__CREDIT_CHARITY_AMOUNT ||
      currentQuestion.slug === SLUG__CREDIT_STANDARD_ITEMIZED_SUCCESS
        ? [dispatch(getDeductions())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_DEBIT ? [dispatch(getBankLinks())] : []),
      ...(currentQuestion.slug === SLUG__FIND_WRITE_OFFS
        ? [dispatch(getExpenses({ year })), dispatch(getCategories())]
        : []),
      ...(currentQuestion.slug === SLUG__SUBMIT_CONFIRMATION ? [dispatch(getExpenses({ year }))] : []),
      ...([HOME_SLUGS.PRESELECT_ADDRESS, HOME_SLUGS.ADDRESS].includes(currentQuestion.slug)
        ? [dispatch(getHomeAddressPreselectOptions({ year }))]
        : [])
    ]);
  };

export const updateItemizedDeduction = () => async (dispatch, getState) => {
  const year = yearSelector(getState());
  const allQuestions = allQuestionsSelector(getState());

  await dispatch(getDeductions());

  const deductions = deductionsSelector(getState());

  if (!deductions.switchToItemized) {
    return;
  }

  const creditStandardDeduction = getQuestionById({
    allQuestions,
    slug: 'credit-standard-deduction',
    coll_id: DEFAULT_COLLECTION_ID
  });

  await dispatch(
    updateTaxData({
      taxData: [
        ...[getStartedUpdates, getQuestionUpdates, getDoneUpdates].flatMap((fn) =>
          fn({
            question: creditStandardDeduction,
            answer: { value: 'itemized' },
            collectionId: DEFAULT_COLLECTION_ID
          })
        )
      ],
      generateSharedCollectionId: false,
      year
    })
  );
};

export const dispatchHomeOfficeUpdates = async ({ dispatch, slug, queryResults, collectionId }) => {
  if (slug === HOME_SLUGS.PRIOR_DEPRECIATION) {
    await dispatch(getHomePriorDepreciation(queryResults, collectionId));
  }
};

export const getQuestionnaireQuestionAsQuestion =
  ({ questionnaireQuestion }) =>
  async (dispatch, getState) => {
    const year = yearSelector(getState());
    const { type, coll_type, coll_id, slug: questionnaireQuestionSlug } = questionnaireQuestion;
    await dispatch(setCurrentCollectionId(coll_id || DEFAULT_COLLECTION_ID));
    const maybeMatchingUpload = _.find(flattenedUploadAttemptsSelector(getState()), {
      collectionType: coll_type,
      collectionId: _.toNumber(coll_id),
      status: 'prefilled'
    });
    const bulkUploadItems = bulkUploadItemsSelector(getState());
    // if type is form review instead of question, display form details screen
    const slug =
      type === 'form-review' && maybeMatchingUpload && coll_type
        ? _.chain(bulkUploadItems)
            .find({ collectionType: coll_type, subType: maybeMatchingUpload.subType })
            .get('formInputQuestionSlug')
            .defaultTo(questionnaireQuestionSlug)
            .value()
        : questionnaireQuestionSlug;

    const allQuestions = allDerivedQuestionsSelector(getState());
    let clarifyingQuestionContent = getQuestionById({ allQuestions, slug });
    if (type === 'form-review') {
      clarifyingQuestionContent = {
        ...clarifyingQuestionContent,
        title: '',
        summary: ''
      };
    }

    await dispatch(getQueryResults({ currentQuestion: clarifyingQuestionContent }));
    const queryResults = queryResultsSelector(getState());
    await dispatchHomeOfficeUpdates({ dispatch, slug, queryResults, collectionId: coll_id });

    if (slug === INCOME_SLUGS.FREELANCE_JOB) {
      const businessCodeQuery = queryResults.find(
        (result) =>
          result.coll_type === INCOME_COLLECTION_TYPES.FREELANCE &&
          result.slug === INCOME_SLUGS.FREELANCE_BUSINESS_CODE &&
          result.coll_id === coll_id
      );
      const businessCodeValue = _.get(businessCodeQuery, ['answer', 'value']);
      await dispatch(setBusinessCode(businessCodeValue));
    } else if ([SLUG__BULK_UPLOAD_QUESTIONS_STATE_INFO, SLUG__BULK_UPLOAD_QUESTIONS_MULTISTATE_INFO].includes(slug)) {
      const stateCollectionId = slug === SLUG__BULK_UPLOAD_QUESTIONS_STATE_INFO ? '1' : '2';
      const currentState = queryResults.find((queryResult) => {
        return queryResult.slug === SLUG__STATE_RETURN && queryResult.coll_id === stateCollectionId;
      });
      await dispatch(setCurrentTaxState(_.get(currentState, ['answer', 'value'])));
    } else {
      await dispatch(setBusinessCode(null));
    }

    const maybeState = _.findKey(STATE_TAX_COLL_TYPE_MAP, (coll_types) => coll_types.includes(coll_type));
    if (maybeState) {
      await dispatch(setCurrentTaxState(maybeState));
    }

    await dispatch(getAllExpenses({ year }));
    if ([HOME_SLUGS.PRESELECT_ADDRESS, HOME_SLUGS.ADDRESS].includes(slug)) {
      await dispatch(getHomeAddressPreselectOptions({ year }));
    } else if ([INCOME_SLUGS.FREELANCE_JOB, INCOME_SLUGS.FREELANCE_BUSINESS_CODE_INFO].includes(slug)) {
      await dispatch(getJobs());
    }

    return clarifyingQuestionContent;
  };

export const resetCurrentAnswer =
  ({ questionWithNewMeta }) =>
  async (dispatch, getState) => {
    const currentCollectionId = currentCollectionIdSelector(getState());
    const queryResults = queryResultsSelector(getState());
    const year = yearSelector(getState());

    if (questionWithNewMeta.slug === CAR_SLUGS.DEPRECIATION_AMOUNT_ESTIMATE) {
      await dispatch(getCarPriorDepreciation(queryResults, currentCollectionId));
    }

    if (questionWithNewMeta.slug === INCOME_SLUGS.INVEST_INFO) {
      const unificationRecord = queryResults.find(
        (result) => result.slug === INCOME_SLUGS.INVEST_UNIFICATION && result.coll_id === currentCollectionId
      );
      let collectionIds;
      if (unificationRecord) {
        collectionIds = _.get(unificationRecord, ['answer', 'value'], [currentCollectionId]);
      } else {
        collectionIds = [currentCollectionId];
      }

      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        collectionIds.forEach((collId) => {
          const queryResult = queryResults.find(
            (qr) =>
              qr.coll_type === subQuestion.collectionType &&
              collId === Number(qr.coll_id) &&
              qr.slug === subQuestion.endpoint_attr
          );

          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year });
          result = {
            ...result,
            [collId]: {
              ..._.get(result, collId, {}),
              [subQuestion.slug]: _.get(queryResult, 'answer', defaultAnswer)
            }
          };
        });

        return result;
      }, {});
      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else if (questionWithNewMeta.slug === CAR_SLUGS.MILEAGE_SPECIFIC) {
      const carQueryResults = queryResults.filter((item) => item.coll_type === COLLECTION_TYPE__CAR);
      const carCollectionIds = _.uniq(carQueryResults.map((r) => r.coll_id).filter((id) => Number(id) > 0));
      const jobs = get1099JobData({ queryResults });

      const newValue = questionWithNewMeta.sub_question[0].sub_question.reduce((result, subQuestion) => {
        let res = result;
        for (const carCollectionId of carCollectionIds) {
          const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, year });
          if (subQuestion.slug !== CAR_SLUGS.MILEAGE_SPECIFIC_BUSINESS_MILEAGE) {
            const slug = `${subQuestion.endpoint_attr}-${carCollectionId}`;
            const savedAnswer = carQueryResults.find(
              (qr) => qr.coll_type === subQuestion.collectionType && qr.slug === slug
            );

            res[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
          } else {
            const slugs = jobs.map(
              (job) => `${subQuestion.endpoint_attr}-${job.who}-${job.jobName.replaceAll('-', '')}-${carCollectionId}`
            );
            const jobLevelResults = carQueryResults.filter(
              (qr) => qr.coll_type === subQuestion.collectionType && slugs.includes(qr.slug)
            );
            for (const slug of slugs) {
              const savedAnswer = jobLevelResults.find((r) => r.slug === slug);
              res[slug] = _.get(savedAnswer, 'answer', defaultAnswer);
            }
          }
        }
        return res;
      }, {});
      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else if (questionWithNewMeta.question_type === CATEGORY_TYPE_TAXFLOW_FORM) {
      const newValue = questionWithNewMeta.sub_question.reduce((result, subQuestion) => {
        const queryResult = getQueryResultByEndpointAttribute({
          queryResults,
          collectionType: subQuestion.collectionType,
          collectionId: currentCollectionId,
          slug: subQuestion.endpoint_attr
        });
        const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: subQuestion });
        const defaultAnswer = deserializeQuestionAnswer({ question: subQuestion, value: null, queryResults, year });
        return {
          ...result,
          [subQuestion.slug]: _.get(
            queryResult,
            ['answer'],
            !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
          )
        };
      }, {});

      const currentAnswer = { value: newValue };
      dispatch(setCurrentAnswer(currentAnswer));
    } else {
      const queryResult = queryResults.find(
        (queryResult) =>
          queryResult.slug === questionWithNewMeta.endpoint_attr && queryResult.coll_id === currentCollectionId
      );
      const savedDefaultAnswer = savedDefaultAnswerSelector(getState(), { question: questionWithNewMeta, year });
      const defaultAnswer = deserializeQuestionAnswer({
        question: questionWithNewMeta,
        value: null,
        queryResults
      });

      const currentAnswer = _.get(
        queryResult,
        ['answer'],
        !_.isNil(savedDefaultAnswer) ? savedDefaultAnswer : defaultAnswer
      );
      dispatch(setCurrentAnswer(currentAnswer));
    }
  };

export const getCarPriorDepreciation = (queryResults, currentCollectionId) => async (dispatch) => {
  const queryResultCarCost = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: CAR_ENDPOINT_ATTRIBUTES.COST
  });

  const queryResultCarYears = getQueryResultByEndpointAttribute({
    queryResults,
    collectionType: COLLECTION_TYPE__CAR,
    collectionId: currentCollectionId,
    slug: CAR_ENDPOINT_ATTRIBUTES.YEARS_DEPRECIATION
  });

  const cost = _.get(queryResultCarCost, ['answer', 'value']);
  const yearsDepreciation = _.get(queryResultCarYears, ['answer', 'value']);

  const res = await axios.post(`${baseUrl}api/taxes/car-depreciation-calc`, {
    yearsDepreciation,
    cost
  });
  const { priorDepreciation } = res.data.data;
  dispatch(setCarPriorDepreciation(priorDepreciation));
};
