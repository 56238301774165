import React from 'react';
import { useLocation } from 'react-router-dom';
import ProgressBarWithTitle from '@app/src/Components/Common/ProgressBarWithTitle/ProgressBarWithTitle';
import { PATHS_WITH_RETRO_LOADING_PROGRESS_BAR } from '@app/src/constants/onboardingConstants';

const RetroLoadingProgressBar = () => {
  const { pathname } = useLocation();

  const isVisible = PATHS_WITH_RETRO_LOADING_PROGRESS_BAR.includes(pathname);

  const title = pathname.includes('linked') ? 'Scan complete!' : 'Onboarding complete!';

  return isVisible ? <ProgressBarWithTitle title={title} progress={100} /> : null;
};

export default RetroLoadingProgressBar;
