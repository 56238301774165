import React, { useMemo } from 'react';
import _ from 'lodash';
import Autocomplete from '@mui/material/Autocomplete';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Fuse from 'fuse.js';
import { FUSE_CATEGORY_OPTIONS } from '@app/src/constants/constants';
import { colorPrimaryAlt, colorSurface } from '@app/src/theme';

const CategorySearch = ({ open, anchorEl, value = [], onChange, handleClose, filterOptions }) => {
  const fuse = useMemo(() => new Fuse(filterOptions, FUSE_CATEGORY_OPTIONS), [filterOptions]);

  return (
    <Popper open={open} anchorEl={anchorEl} style={{ width: 200, zIndex: 9000 }} placement='bottom-start' elevation={5}>
      <ClickAwayListener
        onClickAway={() => {
          // When selecting a category, the handleClose event unmounts
          // the Autocomplete component and the onChange event is not triggered.
          setTimeout(() => handleClose(), 50);
        }}
      >
        <Autocomplete
          multiple
          open
          onClose={(_, reason) => {
            if (reason === 'escape') {
              handleClose();
            }
          }}
          getOptionDisabled={(option) => option.displayName === 'Select a category'}
          onChange={(event, newValue, reason) => {
            if (
              event.type === 'keydown' &&
              (event.key === 'Backspace' || event.key === 'Delete') &&
              reason === 'removeOption'
            ) {
              return;
            }
            // Convert selected objects back to an array of IDs
            onChange({
              event,
              newValue: newValue.map((option) => option.value),
              reason
            });
          }}
          disableCloseOnSelect
          renderTags={() => null}
          // Map the array of IDs to the corresponding objects for the `value` prop,
          // which Autocomplete expects.
          value={filterOptions.filter((option) => value.includes(option.value))}
          options={[{ value: null, displayName: 'Select a category' }, ...filterOptions]}
          slotProps={{ popupIndicator: { style: { display: 'none' } }, paper: { elevation: 5 } }}
          filterOptions={(options, { inputValue }) => {
            if (inputValue === '') {
              const selected = options.filter((opt) => value.includes(opt.value));
              const notSelected = options.filter((opt) => !value.includes(opt.value));
              return [...selected, ...notSelected];
            }

            const searchResult = fuse.search(inputValue);
            return _.map(searchResult, 'item');
          }}
          renderOption={(props, option) => (
            <MenuItem
              aria-selected={value.includes(option.value)}
              style={{
                backgroundColor: value.includes(option.value) && colorPrimaryAlt,
                color: value.includes(option.value) && colorSurface
              }}
              {...props}
            >
              {option.displayName}
            </MenuItem>
          )}
          getOptionLabel={(option) => option.displayName}
          renderInput={(params) => (
            <TextField
              {...params}
              color='secondary'
              inputProps={params.inputProps}
              autoFocus
              placeholder='e.g. Supplies'
              sx={{ zIndex: 9999 }}
            />
          )}
        />
      </ClickAwayListener>
    </Popper>
  );
};

export default CategorySearch;
