import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PhoneField from '@app/src/Components/Common/PhoneField/PhoneField';
import { hasFieldErrorSelector } from '@app/src/selectors/taxValidationSelectors';

const TaxValidationMaskedInputField = (props) => {
  const { className, hasError, mask, placeholder, onFocus = _.noop, onBlur = _.noop, showRightIcon, rightIcon } = props;
  return (
    <>
      <PhoneField
        mask={mask}
        placeholder={placeholder}
        className={classNames('form-control', className)}
        error={hasError}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
        {..._.omit(props, ['dispatch', 'className', 'hasError', 'question', 'answer', 'showRightIcon', 'rightIcon'])}
      />
      {showRightIcon ? rightIcon : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  hasError: hasFieldErrorSelector
});

const ConnectedTaxValidationMaskedInputField = connect(mapStateToProps)(TaxValidationMaskedInputField);

export default ConnectedTaxValidationMaskedInputField;
